import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Box } from "@cyanco/components/theme/Box";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints } from "@cyanco/components/theme/config";
import { Button, Hidden, Input, SlashButton, SlashText, Text } from "@cyanco/components/theme/v3";
import { useOnClickOutside } from "@cyanco/components/theme/v3/hooks/useOnClickOutside";
import { Search } from "@cyanco/components/theme/v3/icons";

import { useCollectionTags } from "@/components/AppContextProvider";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { useDebounce } from "@/hooks/useDebounce";
import { useQueryParams } from "@/hooks/useQueryParams";

import { useBnplSupportedCollections } from "../../BnplDataContext";
import { useBnplSelectors } from "../../BnplPageContext";

export const CollectionSelectors: React.FC = () => {
  const { collectionTagId } = useQueryParams();
  const { collectionTags } = useCollectionTags();
  const navigate = useNavigate();
  return (
    <BnplSelectorWrapper isStickyHeader>
      <Flex direction="row" gap="1rem" wrap="wrap" style={{ rowGap: "0.5rem" }}>
        <TagButton isActive={collectionTagId === null} onClick={() => navigate(`/`)}>
          <StyledTagText color="secondary" weight="600">
            Trending
          </StyledTagText>
        </TagButton>
        {collectionTags.map(item => (
          <TagButton
            key={item.id}
            isActive={!!collectionTagId && item.id === Number(collectionTagId)}
            onClick={() => navigate(`/?tag=${item.id}`)}
          >
            <StyledTagText color="secondary" weight="600">
              {item.name}
            </StyledTagText>
          </TagButton>
        ))}
        <TagButton isActive={collectionTagId === "all"} onClick={() => navigate(`/?tag=all`)}>
          <StyledTagText color="secondary" weight="600">
            All
          </StyledTagText>
        </TagButton>
      </Flex>
      <Hidden laptopSUp>
        <CollectionSearch />
      </Hidden>
    </BnplSelectorWrapper>
  );
};

export const CollectionSearch = ({ minWidth, isSmall }: { minWidth?: string; isSmall?: boolean }) => {
  const location = useLocation();
  const { setSelectedCollectionSearch, selectedCollectionSearch } = useBnplSelectors();
  const { collections, collectionsLoading } = useBnplSupportedCollections();
  const theme = useTheme();
  const [collectionInputValue, setCollectionInputValue] = useState("");
  const searchInput = useRef<HTMLInputElement>(null);
  const node = useRef<HTMLDivElement>(null);
  const [index, setIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  useDebounce(
    () => {
      setSelectedCollectionSearch(collectionInputValue);
    },
    [collectionInputValue],
    500,
  );
  useEffect(() => {
    if (!location.pathname.startsWith("/bnpl")) {
      setCollectionInputValue("");
    }
    setOpen(false);
  }, [location]);
  const filteredCollections = useMemo(() => {
    if (selectedCollectionSearch === "") return collections;
    return collections.filter(
      collection =>
        collection.name.toLowerCase().includes(selectedCollectionSearch.toLowerCase()) ||
        collection.address.toLowerCase().startsWith(selectedCollectionSearch.toLowerCase()),
    );
  }, [collections, selectedCollectionSearch]);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "/") {
        searchInput.current?.focus();
      }
      if (e.key === "Escape") {
        searchInput.current?.blur();
        setOpen(false);
      }
      if (e.key == "ArrowUp") {
        setIndex(index > 0 ? index - 1 : filteredCollections.length - 1);
      }
      if (e.key == "ArrowDown") {
        setIndex(index + 1 < filteredCollections.length ? index + 1 : 0);
      }
      if (e.key == "Enter" && index >= 0 && index < collections.length) {
        setOpen(false);
        setCollectionInputValue(filteredCollections[index].name);
        searchInput.current?.blur();
        navigate(
          `/bnpl/${CHAIN_IDS_TO_NAMES[filteredCollections[index].chainId as SupportedChainId]}/${
            filteredCollections[index].slug
          }`,
        );
      }
    };
    document.addEventListener("keyup", handleKeyDown);
    return () => document.removeEventListener("keyup", handleKeyDown);
  }, [searchInput, filteredCollections, index]);
  useOnClickOutside(node, () => setOpen(false));
  const navigate = useNavigate();

  return (
    <div ref={node}>
      <StyledBox h={!isSmall ? "46px" : "35px"} minWidth={minWidth}>
        <Input
          placeholder={`Search collections`}
          fontSize={isSmall ? "md" : "lg"}
          fontWeight="400"
          icon={<Search color={theme.colors.gray0} />}
          value={collectionInputValue}
          onChange={e => setCollectionInputValue(e.target.value)}
          onFocus={() => {
            setIndex(-1);
            setOpen(true);
          }}
          inputRef={searchInput}
          p={isSmall ? "0.25rem" : "0.45rem"}
        >
          <SlashButton
            style={{
              maxHeight: isSmall ? "25px" : "30px",
              maxWidth: isSmall ? "25px" : "30px",
              minHeight: isSmall ? "25px" : "30px",
              minWidth: isSmall ? "25px" : "30px",
            }}
          >
            <SlashText size={isSmall ? "sm" : "lg"} weight="500">
              /
            </SlashText>
          </SlashButton>
        </Input>
        {open && !collectionsLoading && collectionInputValue.length > 0 && (
          <SearchResultBox>
            <Flex direction="column" gap="2px">
              {filteredCollections.length === 0 && <Text color="secondary">{`No results found`}</Text>}
              {filteredCollections.map((collection, ind) => (
                <SearchCollectionItem
                  key={collection.address}
                  variant="ghost"
                  isActive={ind == index}
                  onClick={() => {
                    setCollectionInputValue(collection.name);
                    searchInput.current?.blur();
                    navigate(`/bnpl/${CHAIN_IDS_TO_NAMES[collection.chainId as SupportedChainId]}/${collection.slug}`);
                  }}
                >
                  <Text color="secondary" textAlign="left">
                    {collection.name}
                  </Text>
                </SearchCollectionItem>
              ))}
            </Flex>
          </SearchResultBox>
        )}
      </StyledBox>
    </div>
  );
};

const BnplSelectorWrapper = styled.div<{ isStickyHeader: boolean }>`
  padding-bottom: 20px;
  gap: 0.5rem;
  top: 75px;
  position: ${({ isStickyHeader }) => (isStickyHeader ? "sticky" : "unset")};
  z-index: 6;
  background: ${({ theme }) => theme.backgroundColor};
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    top: 60px;
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    padding: 5px 0px;
  }
  ${({ isStickyHeader, theme }) => {
    if (isStickyHeader) {
      return `
      :before {
        background-color: ${theme.backgroundColor};
        content: "";
        display: block;
        position: absolute;
        transform: translateZ(-1px);
        height: 70px;
        width: auto;
        left: -70px;
        top: -5px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      :after {
        background-color: ${theme.backgroundColor};
        content: "";
        display: block;
        position: absolute;
        transform: translateZ(-1px);
        height: 70px;
        width: auto;
        right: -70px;
        top: -5px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      `;
    }
  }}
`;

const StyledBox = styled(Box)<{ minWidth?: string }>`
  min-width: ${({ minWidth }) => minWidth};
  max-width: 500px;
  @media only screen and (max-width: ${breakpoints.laptopM}px) {
    min-width: 180px;
  }
`;

const SearchResultBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray30};
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary} !important;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-top: 0.5rem;
  z-index: 200 !important;
  padding: 0.3rem;
  max-height: 400px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const SearchCollectionItem = styled(Button)<{ isActive: boolean }>`
  padding: 4px 4px;
  justify-content: flex-start;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  background: ${({ theme, isActive }) =>
    isActive && (theme.theme === "light" ? theme.colors.gray10 : theme.colors.gray20)};
  :hover {
    background-color: ${({ theme }) => (theme.theme === "light" ? theme.colors.gray10 : theme.colors.gray20)};
    border-radius: 5px;
  }
`;

const TagButton = styled(Button)<{ isActive: boolean }>`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  background: ${({ theme, isActive }) => (isActive ? theme.colors.gray20 : theme.colors.primary)};
  width: fit-content;
  transition: 0.2s;
  padding: 14px 16px;
  height: min-content;
  :hover {
    opacity: 0.8;
    border: 1px solid ${({ theme }) => theme.colors.gray20};
    background: ${({ theme }) => theme.colors.gray10};
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    padding: 10px 12px;
  }
`;

const StyledTagText = styled(Text)`
  font-size: 18px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    font-size: 14px;
  }
`;
