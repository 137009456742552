import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import orderBy from "lodash.orderby";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAsync, useAsyncAbortable } from "react-async-hook";

import { IUserNftWithApePlanBe, fetchUserApePlanHistory, fetchUserNftsWithApePlans } from "@/apis/ape-plans";
import { IUserNft } from "@/apis/user/types";
import { useBNPLPositions, usePawnPositions } from "@/components/Account/AccountDataContext";
import { IPawn, PawnStatuses } from "@/components/Account/pawn.types";
import { BNPLStatuses, IBNPL } from "@/components/Bnpl/bnpl.types";
import { CAPS_MAPPED_BY_ADDRESS, POOL_IDS_MAPPED_BY_ADDRESS } from "@/config";
import { IsInTestDrive, bigNumToFloat, isApeCoinStakingPossible } from "@/utils";
import { getCyanWalletAddress } from "@/utils/contract";

import { IApeCoinBalance, IApePlanHistory, IApeStakingDataContract, IApeUserAsset, IApeUserPosition } from "./types";
import { applyApeStakingDataToAssets, getAllStakingData, getPoolAndVaultData } from "./util";

const apeCoinBalanceInit = {
  stakedAmount: BigNumber.from("0"),
  stakedAmountCyan: BigNumber.from("0"),
  earnedAmount: BigNumber.from("0"),
  earnedAmountCyan: BigNumber.from("0"),
};

type IApeCoinDataContextType = {
  userAssets: IApeUserAsset;
  cyanAssets: IApeUserAsset;
  pawnPositions: IApeUserPosition;
  bnplPositions: IApeUserPosition;
  assetLoading: boolean;

  apeCoinBalance: IApeCoinBalance;
  allNftStakes: IApeStakingDataContract[];
  apePlanHistories: Array<IApePlanHistory>;
  apePlanHistoryLoading: boolean;
  updateStakingData: () => Promise<void>;
  refreshAssets: () => Promise<void>;
};

export const ApeCoinDataContext = createContext<IApeCoinDataContextType>({
  userAssets: [],
  cyanAssets: [],
  pawnPositions: [],
  bnplPositions: [],
  assetLoading: false,

  apePlanHistories: [],
  allNftStakes: [],
  apePlanHistoryLoading: false,
  apeCoinBalance: apeCoinBalanceInit,
  updateStakingData: async () => {},
  refreshAssets: async () => {},
});

export const ApeCoinDataProvider: React.FC = ({ children }) => {
  const { account, provider, chainId } = useWeb3React();
  const { pawnPositionsWithApePlan } = usePawnPositions();
  const { bnplPositionsWithApePlan } = useBNPLPositions();
  const [apeStakingPossibleItems, setApeStakingPossibleItems] = useState<{
    userAssets: IApeUserAsset;
    cyanAssets: IApeUserAsset;
    pawnPositions: IApeUserPosition;
    bnplPositions: IApeUserPosition;
  }>({
    userAssets: [],
    cyanAssets: [],
    pawnPositions: [],
    bnplPositions: [],
  });

  const { result: apePlanHistories = [], loading: apePlanHistoryLoading } = useAsyncAbortable<IApePlanHistory[]>(
    async abortSignal => {
      try {
        if (IsInTestDrive || !account || !isApeCoinStakingPossible(chainId)) return [];
        const history = await fetchUserApePlanHistory({
          wallet: account,
          abortSignal,
        });
        return history;
      } catch (e) {
        console.error(e);
      }
      return [];
    },
    [account, chainId, IsInTestDrive],
  );

  const {
    result: stakingInfo,
    loading: stakingInfoLoading,
    execute: refetchContractData,
  } = useAsync(async () => {
    const _chainId = await provider?.getSigner()?.getChainId();
    if (account !== undefined && provider && _chainId && isApeCoinStakingPossible(_chainId)) {
      const [{ apeCoinBalance, allNftStakes }, { serviceFeeRate, vaultTokenPrice, poolsData }] = await Promise.all([
        getAllStakingData({
          chainId: _chainId,
          mainWallet: account,
          provider,
        }),
        getPoolAndVaultData(provider, _chainId),
      ]);
      return {
        apeCoinBalance,
        allNftStakes,
        poolsData,
        serviceFeeRate,
        vaultTokenPrice,
      };
    }
    return;
  }, [provider, account]);

  const {
    result: apeStakingPossibleItemsBe,
    execute: refetchAssets,
    loading,
  } = useAsync(async () => {
    const _chainId = await provider?.getSigner()?.getChainId();
    if (!provider || !isApeCoinStakingPossible(_chainId) || !account) return;
    const cyanWalletAddress = await getCyanWalletAddress({ provider, mainWallet: account });
    const promises = [];
    if (account) {
      promises.push(fetchUserNftsWithApePlans({ wallet: account }));
    }
    if (cyanWalletAddress) {
      promises.push(fetchUserNftsWithApePlans({ wallet: cyanWalletAddress }));
    }
    const [_userAssets, _cyanAssets] = await Promise.all(promises);
    const userAssets = _userAssets as IUserNftWithApePlanBe[];
    const cyanAssets = filterAssets((_cyanAssets ?? []) as IUserNftWithApePlanBe[], [
      ...bnplPositionsWithApePlan,
      ...pawnPositionsWithApePlan,
    ]);
    return {
      userAssets,
      cyanAssets,
      pawnPositions: pawnPositionsWithApePlan,
      bnplPositions: bnplPositionsWithApePlan,
    };
  }, [provider, account]);

  const setApeStakingContractDatas = () => {
    if (!stakingInfo || !account || stakingInfoLoading) return;
    const userAssetsBe = apeStakingPossibleItemsBe?.userAssets ?? [];
    const cyanAssetsBe = apeStakingPossibleItemsBe?.cyanAssets ?? [];
    const pawnPositionsBe = apeStakingPossibleItemsBe?.pawnPositions ?? [];
    const bnplPositionsBe = apeStakingPossibleItemsBe?.bnplPositions ?? [];
    if (!userAssetsBe.length && !cyanAssetsBe.length && !pawnPositionsBe.length && !bnplPositionsBe.length) return;
    const { vaultTokenPrice, poolsData, serviceFeeRate, allNftStakes } = stakingInfo;

    setApeStakingPossibleItems({
      userAssets: applyApeStakingDataToAssets({
        apeStakeDatasFromContract: allNftStakes,
        assets: userAssetsBe.map(item => ({ ...item, poolId: POOL_IDS_MAPPED_BY_ADDRESS[item.address.toLowerCase()] })),
        vaultTokenPrice,
        pools: poolsData,
        serviceFeeRate,
      }),
      cyanAssets: applyApeStakingDataToAssets({
        apeStakeDatasFromContract: allNftStakes,
        assets: cyanAssetsBe.map(item => ({ ...item, poolId: POOL_IDS_MAPPED_BY_ADDRESS[item.address.toLowerCase()] })),
        vaultTokenPrice,
        pools: poolsData,
        serviceFeeRate,
      }),
      pawnPositions: applyApeStakingDataToAssets({
        apeStakeDatasFromContract: allNftStakes,
        assets: pawnPositionsBe.map(item => ({
          ...item,
          poolId: POOL_IDS_MAPPED_BY_ADDRESS[item.metadata.collectionAddress.toLowerCase()],
        })),
        vaultTokenPrice,
        pools: poolsData,
        serviceFeeRate,
      }),
      bnplPositions: applyApeStakingDataToAssets({
        apeStakeDatasFromContract: allNftStakes,
        assets: bnplPositionsBe.map(item => ({
          ...item,
          poolId: POOL_IDS_MAPPED_BY_ADDRESS[item.metadata.collectionAddress.toLowerCase()],
        })),
        vaultTokenPrice,
        pools: poolsData,
        serviceFeeRate,
      }),
    });
  };

  useEffect(() => {
    if (!stakingInfo || !account || stakingInfoLoading || loading) return;
    setApeStakingContractDatas();
  }, [apeStakingPossibleItemsBe, stakingInfo, stakingInfoLoading, loading]);

  const refreshData = async () => {
    await refetchAssets();
  };

  return (
    <ApeCoinDataContext.Provider
      value={{
        apeCoinBalance: stakingInfo?.apeCoinBalance ?? apeCoinBalanceInit,
        allNftStakes: stakingInfo?.allNftStakes ?? [],
        apePlanHistories,
        apePlanHistoryLoading,
        cyanAssets: apeStakingPossibleItems.cyanAssets,
        userAssets: apeStakingPossibleItems.userAssets,
        pawnPositions: apeStakingPossibleItems.pawnPositions,
        bnplPositions: apeStakingPossibleItems.bnplPositions,
        assetLoading: loading || stakingInfoLoading,
        updateStakingData: async () => {
          await refetchContractData();
        },
        refreshAssets: async () => {
          await refreshData();
        },
      }}
    >
      {children}
    </ApeCoinDataContext.Provider>
  );
};

export const useApeStakingUserAssets = () => {
  const {
    userAssets,
    cyanAssets,
    pawnPositions,
    bnplPositions,
    updateStakingData,
    apeCoinBalance,
    assetLoading,
    refreshAssets,
  } = useContext(ApeCoinDataContext);
  const userAssetsFiltered = useMemo(() => {
    if (!userAssets || !cyanAssets || !pawnPositions || !bnplPositions) {
      return {
        stakableAssets: [],
        stakedAssets: [],
      };
    }
    const assetsMerged = [...userAssets, ...cyanAssets];
    const stakableAssets = assetsMerged.filter(
      asset =>
        asset.apeStaking.plan === null &&
        ((asset.apeStaking.stakedAmount &&
          CAPS_MAPPED_BY_ADDRESS[asset.address.toLowerCase()] > bigNumToFloat(asset.apeStaking.stakedAmount)) ||
          asset.apeStaking.stakedAmount === null),
    );
    const stakedAssets = assetsMerged.filter(
      asset => asset.apeStaking.stakedAmount && asset.apeStaking.stakedAmount.gt(BigNumber.from(0)),
    );
    return {
      stakableAssets: orderBy(stakableAssets, ["collectionName", "tokenId"], "asc"),
      stakedAssets: orderBy(stakedAssets, ["collectionName", "tokenId"], "asc"),
    };
  }, [userAssets, cyanAssets, bnplPositions, pawnPositions]);
  const userPositionsFiltered = useMemo(() => {
    if (!pawnPositions || !bnplPositions) {
      return {
        stakablePositions: [],
        stakedPositions: [],
      };
    }
    const assetsMerged = [...pawnPositions, ...bnplPositions].filter(position => {
      if (position.planType === "BNPL" && position.status === BNPLStatuses.Activated) return true;
      if (position.planType === "Pawn" && position.status === PawnStatuses.Activated) return true;
    });
    const stakablePositions = assetsMerged.filter(
      asset =>
        asset.apeStaking.plan === null &&
        ((asset.apeStaking.stakedAmount &&
          CAPS_MAPPED_BY_ADDRESS[asset.metadata.collectionAddress.toLowerCase()] >
            bigNumToFloat(asset.apeStaking.stakedAmount)) ||
          asset.apeStaking.stakedAmount === null),
    );
    const stakedPositions = assetsMerged.filter(
      asset => asset.apeStaking.stakedAmount && asset.apeStaking.stakedAmount.gt(BigNumber.from(0)),
    );
    return {
      stakablePositions: orderBy(stakablePositions, ["metadata.collection.name", "tokenId"], "asc"),
      stakedPositions: orderBy(stakedPositions, ["metadata.collection.name", "tokenId"], "asc"),
    };
  }, [bnplPositions, pawnPositions]);
  return {
    ...userAssetsFiltered,
    ...userPositionsFiltered,
    apeCoinBalance,
    loading: assetLoading,
    updateStakingData,
    refreshAssets,
  };
};

export const useApeCoinDataContext = () => {
  return useContext(ApeCoinDataContext);
};

const filterAssets = <T,>(assets: Array<IUserNft & T>, positions: Array<IBNPL | IPawn>): Array<IUserNft & T> => {
  return assets.filter(
    asset =>
      !positions.some(
        position =>
          asset.address.toLowerCase() === position.metadata.collectionAddress.toLowerCase() &&
          asset.tokenId === position.tokenId &&
          (position.status === PawnStatuses.Defaulted ||
            position.status === BNPLStatuses.Defaulted ||
            position.status === PawnStatuses.Activated ||
            position.status === BNPLStatuses.Activated),
      ),
  );
};
