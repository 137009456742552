import { useModal } from "@cyanco/components/theme/v3";

import { isV1Plan } from "@/utils";

import { IPawn, PawnStatuses } from "../../pawn.types";
import { HistoricalPaymentModal } from "./PaymentModal";
import { PaymentRow, getPositionDefaultedDate } from "./PaymentRow";

export const PawnPaymentRow = ({ position }: { position: IPawn }) => {
  const { setModalContent } = useModal();

  const onClick = (
    position: IPawn,
    payment: {
      txnHash?: string;
      createdAt: Date;
      paymentNumber: number;
      amount: string;
      status: string;
    },
    payments: { amount: string }[],
  ) => {
    setModalContent({
      title: `Payment Details`,
      content: (
        <HistoricalPaymentModal
          payment={{
            imageUrl: position.metadata.imageUrl,
            collectionName: position.metadata.collection.name,
            paymentNumber: payment.paymentNumber,
            amount: payment.amount,
            loanedAmount: position.pawnedAmount,
            tokenId: position.tokenId,
            downpaymentAmount: "0",
            totalNumOfPayments: position.totalNumOfPayments,
            paidDate: payment.createdAt,
            status: payment.status,
            txnHash: payment.txnHash,
            currentNumOfPayments: position.currentNumOfPayments,
            monthlyAmount: position.monthlyAmount,
            currency: position.currency,
            serviceFeeAmount: position.serviceFeeAmount,
            interestFeeAmount: position.interestFee,
            payments,
            planType: "Pawn",
            isV1Plan: isV1Plan(position),
          }}
        />
      ),
    });
  };
  return (
    <>
      {(position.status === PawnStatuses.Defaulted || position.status === PawnStatuses.Liquidated) && (
        <PaymentRow
          onClick={() =>
            onClick(
              position,
              {
                paymentNumber: position.currentNumOfPayments + 1,
                createdAt: getPositionDefaultedDate(position),
                amount: position.monthlyAmount,
                status: "missed",
              },
              position.payments,
            )
          }
          key={`${position.planId}:defaulted`}
          payment={{
            imageUrl: position.metadata.imageUrl,
            collectioName: position.metadata.collection.name,
            createdAt: getPositionDefaultedDate(position),
            paymentNumber: position.currentNumOfPayments + 1,
            amount: position.monthlyAmount,
            currency: position.currency,
            status: "missed",
            tokenId: position.tokenId,
            totalPaymentNumber: position.totalNumOfPayments,
            positionId: position.planId,
            planType: "Pawn",
            isAutoLiquidationEnabled: position.isAutoLiquidationEnabled,
          }}
        />
      )}
      {position.payments.map((payment, index) => {
        const status =
          (position.numOfEarlyPaidPayments ?? 0) > 1 && payment.paymentNumber === position.totalNumOfPayments
            ? "completedEarly"
            : payment.paymentNumber === position.totalNumOfPayments && position.status === PawnStatuses.Completed
            ? "completed"
            : "paid";
        return (
          <PaymentRow
            onClick={() => onClick(position, { ...payment, status }, position.payments)}
            key={`${position.planId}:${index}`}
            payment={{
              imageUrl: position.metadata.imageUrl,
              collectioName: position.metadata.collection.name,
              createdAt: payment.createdAt,
              paymentNumber: payment.paymentNumber,
              amount: payment.amount,
              currency: position.currency,
              status,
              tokenId: position.tokenId,
              totalPaymentNumber: position.totalNumOfPayments,
              transactionHash: payment.txnHash,
              positionId: position.planId,
              planType: "Pawn",
              isAutoLiquidationEnabled: position.isAutoLiquidationEnabled,
            }}
          />
        );
      })}
    </>
  );
};
