import { BigNumber } from "ethers";
import { useEffect, useState } from "react";

import { Flex } from "@cyanco/components/theme";
import { Text, useModal } from "@cyanco/components/theme/v3";

import { fetchPositionsByUser } from "@/apis/user";
import SetupAlertsModal from "@/components/SetupAlerts";
import { ICurrency } from "@/types";
import { readPlansOnChain } from "@/utils/contract";

import { IPawn } from "../Account/pawn.types";
import { IBNPL } from "../Bnpl/bnpl.types";
import { useWeb3React } from "../Web3ReactProvider";
import { ItemsMetadata } from "./ItemsMetadata";

type IItemType = {
  address: string;
  tokenId: string;
  planId: number;
  planType?: "bnpl" | "pawn";
  price: BigNumber;
  collectionName: string;
  imageUrl: string | null;
  currency: ICurrency;
  marketName?: string;
  rarityStat?: string;
  error?: string;
};
type IProps = {
  items: Array<IItemType>;
  planType: "pawn" | "bnpl";
};
export const PlanNotificationSettings: React.FC<IProps> = args => {
  const { items, planType } = args;
  const { account, chainId } = useWeb3React();

  const { unsetModal, setModalChildContent } = useModal();
  const [planData, setPlanData] = useState<IPawn | IBNPL | null>(null);

  const paymentPlans = items.map(pricedItem => ({
    planId: pricedItem.planId,
  }));

  useEffect(() => {
    if (!account) return;

    const getPlanData = async (account: string, planId: number, planType: string) => {
      if (planType === "pawn") {
        const positions = await fetchPositionsByUser(account, { type: "Pawn", chainId });
        const { pawns } = await readPlansOnChain(positions);
        const selectedPawnData = pawns.filter(plan => plan.planId === planId);
        if (selectedPawnData.length > 0) setPlanData(selectedPawnData[0]);
      } else {
        const positions = await fetchPositionsByUser(account, { type: "BNPL", chainId });
        const { bnpls } = await readPlansOnChain(positions);
        const selectedBnplData = bnpls.filter(plan => plan.planId === planId);
        if (selectedBnplData.length > 0) setPlanData(selectedBnplData[0]);
      }
    };
    getPlanData(account, paymentPlans[0].planId, planType);
  }, []);

  return (
    <Flex gap="5x" direction="column">
      <ItemsMetadata items={args.items} planType={planType} isCreating={false} />
      <div style={{ margin: "16px 0px" }}>
        <Text size="lg" weight="600" color="secondary">
          {`Your Plan has started!`} <br />
          {`Setup alert option below.`}
        </Text>
      </div>
      <SetupAlertsModal
        planData={planData}
        planType={planType}
        onClose={unsetModal}
        onNext={nextNode => {
          setModalChildContent({ childContent: nextNode });
        }}
      />
    </Flex>
  );
};
