import React, { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { Flex } from "../../Flex";
import { ITextSizes, TextSizes } from "./Text";

export const Label = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const StyledToggler = styled.div<{ size: "xs" | "sm" | "md"; isChecked: boolean; disabled?: boolean }>`
  position: relative;
  height: ${({ size }) =>
    size == "xs" ? "calc(4px + 0.1rem)" : size === "sm" ? "calc(12px + 0.1rem);" : "calc(16px + 0.1rem);"};
  width: ${({ size }) => (size == "xs" ? "19px" : "45px")};
  background: ${({ theme }) => (theme.theme === "light" ? "white" : "#6C6C6C")};
  border-radius: 25px;
  padding: ${({ size }) => (size === "xs" ? "3px 2px" : size === "sm" ? "4px 3px" : "4px 5px")};
  transition: 300ms background;
  border: 1px solid;
  border-color: ${({ theme, isChecked }) => {
    if (isChecked) {
      return theme.theme === "light" ? "#00DFDF" : "#006060";
    }
    return theme.theme === "light" ? "black" : "#6C6C6C";
  }};

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: ${({ size }) => (size === "xs" ? "10px" : size === "sm" ? "15px" : "19px")};
    height: ${({ size }) => (size == "xs" ? "10px" : size === "sm" ? "15px" : "19px")};
    border-radius: 35px;
    top: 50%;
    left: ${({ size }) => (size == "xs" ? "1px" : "4px")};
    background: ${({ theme }) => theme.colors.secondary};
    transform: translate(0, -50%);
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;

const Input = styled.input<{ size: "xs" | "sm" | "md" }>`
  opacity: 0;
  position: absolute;

  &:checked + ${StyledToggler} {
    background: ${({ theme }) => (theme.theme === "light" ? "#00DFDF" : "#006060")};

    &:before {
      transform: ${({ size }) => (size === "xs" ? "translate(11px, -50%)" : "translate(27px, -50%)")};
      background: #00ffff;
    }
  }
`;

export const Toggler = ({
  value,
  disabled = false,
  onChange,
  size = "md",
}: {
  value: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  size?: "xs" | "sm" | "md";
  width?: string;
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };
  return (
    <Label disabled={disabled}>
      <Input size={size} checked={value} type="checkbox" disabled={disabled} onChange={handleChange} />
      <StyledToggler size={size} isChecked={value} disabled={disabled} />
    </Label>
  );
};

const PillContainer = styled(Flex)<{ borderColor?: string; borderRadius?: string }>`
  border-width: ${({ theme }) => theme.borderWidth};
  border-color: ${({ theme, borderColor }) => borderColor || theme.colors.gray20};
  border-style: solid;
  border-radius: ${({ borderRadius }) => borderRadius ?? "8px"};
  height: 100%;
  padding: 0.24rem;
  background: ${({ theme }) => theme.colors.primary};
`;
const PillButton = styled.button<{
  active?: boolean;
  textSize?: ITextSizes;
  activeBackground?: string;
  activeTextColor?: string;
  hover?: boolean;
  borderRadius?: string;
  color: string;
  height: string;
}>`
  background: ${({ active, theme, activeBackground }) => {
    return active
      ? activeBackground
        ? activeBackground
        : theme.theme == "dark"
        ? theme.colors.gray20
        : "#C8C8C8"
      : theme.colors.primary;
  }};
  white-space: nowrap;
  border-radius: ${({ borderRadius }) => borderRadius ?? "5px"};
  border: none;
  color: ${({ color }) => color};
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: ${({ textSize }) => TextSizes[textSize ?? "xs"]};
  height: ${({ height }) => height};
  padding: 0rem 0.8rem;
  cursor: pointer;
  outline: none;
  min-width: 55px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  transition: color 0.2s;
  transition: background-color 0.2s ease;
  &:disabled {
    cursor: not-allowed;
    :hover {
      color: ${({ theme }) => theme.colors.gray20};
    }
  }
  svg,
  path,
  rect {
    transition: 0.2s;
  }
  :hover {
    transition: color 0.2s;
    color: ${({ theme, active, hover }) => !active && hover && theme.colors.secondary};
    svg,
    path,
    rect {
      transition: 0.2s;
      stroke: ${({ theme, active, hover }) => !active && hover && theme.colors.secondary};
    }
  }
`;
export const SwitchButtonGroup = <T,>(props: {
  activeBackground?: string;
  activeTextColor?: string;
  borderColor?: string;
  borderRadius?: string;
  textSize?: ITextSizes;
  hover?: boolean;
  onChange?: (v: T) => void;
  value: T;
  children?: React.ReactNode;
}) => {
  const { children, onChange, value, activeBackground, activeTextColor, borderColor, borderRadius, textSize } = props;
  return (
    <PillContainer borderColor={borderColor} borderRadius={borderRadius}>
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child as React.ReactElement<any>, {
          activeBackground,
          activeTextColor,
          active: child.props.value === value,
          disabled: child.props.disabled,
          hover: props.hover,
          height: child.props.height,
          borderRadius,
          textSize,
          onSelect: onChange || child.props.onSelect,
        });
      })}
    </PillContainer>
  );
};

export const SwitchButton = (props: {
  activeBackground?: string;
  activeTextColor?: string;
  active?: boolean;
  borderRadius?: string;
  textSize?: ITextSizes;
  hover?: boolean;
  disabled?: boolean;
  value: string | number;
  onSelect?: (a: string | number) => void;
  children?: React.ReactNode;
  height?: string;
}) => {
  const theme = useTheme();
  const {
    children,
    value,
    active,
    onSelect,
    activeBackground,
    activeTextColor,
    hover,
    disabled,
    height,
    borderRadius,
    textSize,
  } = props;
  const textColor = useMemo(() => {
    if (active) {
      return activeTextColor || theme.colors.secondary;
    }
    if (disabled) {
      return theme.colors.gray20;
    }
    return theme.colors.gray0;
  }, [theme, active, activeTextColor, disabled]);
  return (
    <PillButton
      active={active}
      hover={hover}
      onClick={() => onSelect && onSelect(value)}
      activeBackground={activeBackground}
      activeTextColor={activeTextColor}
      disabled={disabled}
      color={textColor}
      height={height || "100%"}
      borderRadius={borderRadius}
      textSize={textSize}
    >
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement<any>, {
            color: textColor,
          });
        }
        return child;
      })}
    </PillButton>
  );
};
