import dayjs from "dayjs";
import { utils } from "ethers";
import { useEffect, useState } from "react";
import { useAsync } from "react-async-hook";
import { Info, Lock } from "react-feather";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import styled, { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme/components";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import {
  Button,
  Hidden,
  NotFound,
  SortingArrows,
  Text,
  Tooltip,
  TooltipText,
  useModal,
} from "@cyanco/components/theme/v3";
import { Send } from "@cyanco/components/theme/v3/icons";
import { Etherscan } from "@cyanco/components/theme/v3/images";

import { IUserToken } from "@/apis/user/types";
import { useFilteredVaults } from "@/components/Vault/VaultDataProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { useGetCyanWalletLock } from "@/hooks";
import { useSortableHeader } from "@/hooks/useSortableHeader";

import { displayInUSD, getChainExplorerURL, numberWithCommas, shortenAddress, shortenName } from "../../../../utils";
import { useUserTokens } from "../../../Token/TokenContextProvider";
import { useWalletTabContext } from "../../AccountPageContext";
import { ContainerBox, Header as HeaderContainer, ListViewBox } from "../../index";
import { HeaderItemWrapper } from "../PositionView/PositionRow";
import { RowText, StyledImg, WalletContainer } from "./NftRow";
import { Refresh } from "./Refresh";
import { WalletSelector } from "./Selectors";
import { UserTokenTransfer } from "./UserTokenModals/UserTokenTransfer";

const UserTokenRow = ({
  token,
  account,
  chainId,
}: {
  token: IUserToken & { tokenInUsd?: number };
  account: string;
  cyanWalletAddress?: string;
  chainId: number;
}) => {
  const theme = useTheme();
  const cyanWallet = useCyanWallet();
  const { vaults } = useFilteredVaults();
  const tokenBalanceInNumber = parseFloat(utils.formatUnits(token.tokenBalance, token.decimal));
  const { setModalContent } = useModal();
  const { getLockedDate } = useGetCyanWalletLock();

  const {
    loading: depositLockLoading,
    result: { isCyanWalletLocked, lockedDate } = { isCyanWalletLocked: false, lockedDate: dayjs() },
  } = useAsync<{ isCyanWalletLocked: boolean; lockedDate: dayjs.Dayjs | null }>(async () => {
    if (cyanWallet && token.symbol.startsWith("CV")) {
      try {
        const vaultAddress = vaults.find(
          vault => vault.contractTokenAddress.toLowerCase() === token.address.toLowerCase(),
        )?.contractAddress;
        if (vaultAddress) {
          const date = await getLockedDate(vaultAddress);
          const lockedDate = date && date.gt(0) ? dayjs.unix(date.toNumber()) : null;
          if (lockedDate && lockedDate.isAfter(dayjs())) {
            const isCyanWalletLocked = lockedDate !== null;
            return {
              lockedDate: lockedDate,
              isCyanWalletLocked,
            };
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
    return {
      lockedDate: null,
      isCyanWalletLocked: false,
    };
  }, [cyanWallet, token]);

  const onClick = () => {
    setModalContent({ title: `Token Transfer`, content: <UserTokenTransfer token={token} /> });
  };
  return (
    <Row onClick={onClick}>
      <Flex gap="1rem" alignItems="center">
        <Flex alignItems="center" gap="20px">
          <StyledImg src={token.imageUrl} hasImage alt={token.name} />
          <Flex gap="5px">
            <Flex direction="column" gap="2px">
              <RowText weight="500" color="secondary">
                {token.symbol.toUpperCase()}
              </RowText>
              <RowText color="gray0" sub>
                {shortenName(token.name)}
              </RowText>
            </Flex>
            {!depositLockLoading && isCyanWalletLocked && token.isCyanWallet && token.symbol.startsWith("CV") && (
              <MobileFriendlyWrapper>
                <>
                  <Hidden laptopMDown>
                    <Tooltip>
                      <RowBox direction="column">
                        <Flex gap="2px" alignItems="center">
                          <StyledText color="primary" weight="600" size="xxs">
                            Locked until: {lockedDate?.format("MMMM Do, YYYY")}
                          </StyledText>
                        </Flex>
                        <TooltipText showArrow position="top" bottom="calc(100% + 15px)" left="10px">
                          <Flex direction="column">
                            <StyledText color="primary" weight="600" size="xxs">
                              Due to the recent vault deposit, The cyan wallet is locked.
                            </StyledText>
                          </Flex>
                        </TooltipText>
                      </RowBox>
                    </Tooltip>
                  </Hidden>

                  <Hidden laptopMUp onClick={e => e.stopPropagation()}>
                    <Flex
                      style={{
                        background: theme.colors.secondary,
                        borderRadius: "4px",
                      }}
                      p={"2px 3px"}
                      h={"fit-content"}
                    >
                      <Tooltip>
                        <Flex gap="2px" alignItems="center">
                          <StyledText color="primary" weight="600">
                            {`Locked`}
                          </StyledText>
                          <Info height={"10px"} width={"10px"} color={theme.colors.primary} />
                        </Flex>
                        <TooltipText showArrow position="top" bottom="calc(100% + 15px)" left="10px">
                          <Flex direction="column">
                            <StyledText color="primary" weight="600" size="xxs">
                              Due to the recent vault deposit, The cyan wallet is locked until{" "}
                              {lockedDate?.format("MMMM Do, YYYY")}
                            </StyledText>
                          </Flex>
                        </TooltipText>
                      </Tooltip>
                    </Flex>
                  </Hidden>
                </>
              </MobileFriendlyWrapper>
            )}
          </Flex>
        </Flex>
      </Flex>
      {token.isCyanWallet ? (
        <WalletContainer>
          <div
            style={{
              background: theme.colors.cyan,
              width: "18px",
              height: "18px",
              minWidth: "18px",
              minHeight: "18px",
              borderRadius: "50%",
            }}
          />
          <RowText color="secondary" weight="500">
            Cyan Wallet
          </RowText>
          {!depositLockLoading && isCyanWalletLocked && token.symbol.startsWith("CV") && (
            <Lock height={"15x"} width={"15px"} color={theme.colors.secondary}></Lock>
          )}
        </WalletContainer>
      ) : (
        <WalletContainer>
          <Jazzicon seed={jsNumberForAddress(account)} diameter={18} />
          <RowText color="secondary" weight="500">
            {shortenAddress(account)}
          </RowText>
        </WalletContainer>
      )}

      <Flex direction="column" justifyContent="center" gap="2px">
        <RowText weight="500" style={{ overflowWrap: "anywhere" }} color="secondary">
          {numberWithCommas(tokenBalanceInNumber, 2)} {token.symbol.toUpperCase()}
        </RowText>
        {!!token.tokenInUsd && (
          <RowText sub color="gray0" style={{ overflowWrap: "anywhere" }}>
            {displayInUSD(token.tokenInUsd * tokenBalanceInNumber)}
          </RowText>
        )}
      </Flex>
      <Hidden tabletDown>
        <Box w="min-content">
          <TransferButton
            variant="ghost"
            onClick={e => {
              e.stopPropagation();
              setModalContent({ title: `Token Transfer`, content: <UserTokenTransfer token={token} /> });
            }}
          >
            <Send color={theme.colors.secondary} height={20} width={20} />
          </TransferButton>
        </Box>
      </Hidden>
      <Hidden tabletDown>
        <ImgButton
          src={Etherscan}
          onClick={e => {
            e.stopPropagation();
            window.open(`${getChainExplorerURL(chainId)}/token/${token.address}`, "_blank");
          }}
        />
      </Hidden>
    </Row>
  );
};

const UserTokenListHeader = ({
  refreshToken,
  sortedTokens,
  updateSortedTokens,
}: {
  refreshToken: () => Promise<void>;
  sortedTokens: (IUserToken & { tokenInUsd?: number })[];
  updateSortedTokens: (values: (IUserToken & { tokenInUsd?: number })[]) => void;
}) => {
  const { selectedWalletType, setSelectedWalletType } = useWalletTabContext();
  const getBalance = (c: IUserToken & { tokenInUsd?: number }) => {
    return c.tokenInUsd && c.tokenBalance && c.decimal
      ? (c.tokenInUsd ?? 1) * parseFloat(utils.formatUnits(c.tokenBalance, c.decimal))
      : 0;
  };
  const { sort, sortDirectionDesc, sortName } = useSortableHeader<IUserToken & { tokenInUsd?: number }>(
    [c => getBalance(c)],
    "balance",
  );

  const handleHeaderClick = (value: ((item: IUserToken & { tokenInUsd?: number }) => any)[], sortName: string) => {
    const sortedData = sort(value, sortedTokens, sortName);
    updateSortedTokens(sortedData);
  };

  const onRefresh = async () => {
    await refreshToken();
  };
  return (
    <Header>
      <Flex gap="1rem" alignItems="center">
        <RowText weight="600" color="secondary">{`${sortedTokens.length} item${
          sortedTokens.length > 1 ? "s" : ""
        }`}</RowText>
        <Refresh onClick={onRefresh} />
      </Flex>{" "}
      <WalletSelector selectedWalletType={selectedWalletType} setSelectedWalletType={setSelectedWalletType} />
      <Flex alignItems="center" gap="0.4rem">
        <HeaderItemWrapper>
          <Flex gap="5px" onClick={() => handleHeaderClick([c => getBalance(c)], "balance")}>
            <RowText color="secondary" weight="600">{`Balance`}</RowText>
            <SortingArrows sortDirectionDesc={sortDirectionDesc} sortName={sortName} currentSortName="balance" />
          </Flex>
        </HeaderItemWrapper>
      </Flex>
      <Hidden tabletDown>
        <RowText color="secondary" weight="600">{`Transfer`}</RowText>
      </Hidden>
      <Hidden tabletDown>
        <RowText color="secondary" weight="600">{`Explorer`}</RowText>
      </Hidden>
    </Header>
  );
};

export const UserTokens = () => {
  const { account, chainId } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { userTokensFiltered, refreshUserTokens } = useUserTokens();
  const [sortedTokens, setSortedTokens] = useState<(IUserToken & { tokenInUsd?: number })[]>(userTokensFiltered);

  useEffect(() => {
    setSortedTokens(userTokensFiltered);
  }, [userTokensFiltered]);
  const updateSortedTokens = (values: (IUserToken & { tokenInUsd?: number })[]) => {
    setSortedTokens(values);
  };

  return (
    <ListContainer>
      <ListViewBox>
        <HeaderContainer>
          <UserTokenListHeader
            refreshToken={refreshUserTokens}
            updateSortedTokens={updateSortedTokens}
            sortedTokens={sortedTokens}
          />
        </HeaderContainer>
        <ContainerBox>
          {sortedTokens.length === 0 && <NotFound msg={`No tokens found`} />}
          {account &&
            sortedTokens.map(token => (
              <UserTokenRow
                key={`${token.address}-${token.isCyanWallet ? "cyan" : "main"}`}
                token={token}
                account={account}
                chainId={chainId}
                cyanWalletAddress={cyanWallet?.walletAddress}
              />
            ))}
        </ContainerBox>
      </ListViewBox>
    </ListContainer>
  );
};

const Row = styled.div`
  cursor: pointer;
  display: grid;
  column-gap: 0.5rem;
  align-items: center;
  background-color: transparent;
  grid-template-columns: 2.5fr 2fr 2fr 0.8fr 0.5fr;
  padding: 13px 15px 13px 15px;
  ${getStyleWithMediaQuery("grid-template-columns", "", [{ [breakpoints.tablet]: "2.5fr 2fr 1.3fr" }])};
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.mobile]: "7px 10px" }])};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray10};
    ${getStyleWithMediaQuery("background-color", "", [{ [breakpoints.tablet]: "transparent" }])};
  }
`;
const Header = styled(Row)`
  padding: 15px 15px 9px 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  background-color: ${({ theme }) => theme.colors.primary};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 65x;
    padding: 7px 10px;
  }
`;

const ImgButton = styled.img`
  cursor: pointer;
  height: max(20px, 0.8vw);
  width: max(20px, 0.8vw);
  cursor: pointer;
  transition: 0.2s;
  :hover {
    opacity: 0.5;
  }
`;
const RowBox = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;
  padding: 0.3rem 0.5rem;
`;
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 calc((100% - 1240px) / 2);
  padding-bottom: 50px;
  width: calc(100% - calc((100% - 1240px)));
  gap: 1rem;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 1 }])}
`;

const TransferButton = styled(Button)`
  width: fit-content;
  :hover {
    svg,
    path {
      transition: 0.2s ease-in-out;
      stroke: ${({ theme }) => theme.colors.gray0};
    }
  }
`;

const MobileFriendlyWrapper = styled(Flex)`
  flex-direction: row;
  gap: 4px;
  ${getStyleWithMediaQuery("flex-direction", "", [{ [breakpoints.tablet]: "column" }])};
  align-items: flex-end;
`;

const StyledText = styled(Text)`
  font-size: 10px;
  ${getStyleWithMediaQuery("font-size", "px", [{ [breakpoints.desktop]: 10 }, { [breakpoints.mobile]: 8 }])}
`;
