import dayjs from "dayjs";
import { BigNumber } from "ethers";
import { useMemo, useState } from "react";
import { useAsync } from "react-async-hook";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { factories as f } from "@cyanco/contract";

import { getPlanRevivalSignatureBulk } from "@/apis/revival";
import { IPawn } from "@/components/Account/pawn.types";
import { IBNPL, isBnplPlan } from "@/components/Bnpl/bnpl.types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { getApr, getInterestRate, getPlanPrincipleAmount } from "@/components/PlanCreation/utils";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { IPlanType, PlanTypes } from "@/constants/plans";

import { usePersistedWalletTypeForRelease } from "./usePersistedWalletForNFTRelease";

export enum RevivalOptions {
  revive,
  refinance,
}

export const usePlanRevival = ({ plan, planType }: { plan: IPawn | IBNPL; planType: IPlanType }) => {
  const { account, chainId, signer } = useWeb3React();
  const cyanWallet = useCyanWallet();

  const [warning, setWarning] = useState<{
    msg: string;
    title: string;
    description?: string;
  } | null>(null);
  const [isNotifiedTransferWarning, setIsNotifiedTransferWarning] = useState<boolean>(false);
  const { walletTypeForRelease } = usePersistedWalletTypeForRelease();
  const [releaseWallet, setReleaseWallet] = useState<string>(
    (walletTypeForRelease === "main" ? account : cyanWallet?.walletAddress) ?? "",
  );
  const [revivalOption, setRevivalOption] = useState<RevivalOptions>(RevivalOptions.revive);
  const [defaultedError, setDefaultedError] = useState<string | null>();

  const {
    result: revivalInfo,
    loading: revivalLoading,
    error: revivalError,
  } = useAsync(async () => {
    return await getPlanRevivalSignatureBulk(
      [
        {
          planId: plan.planId,
        },
      ],
      chainId,
    );
  }, [plan.planId]);

  const { error: nextPaymentErrors, loading } = useAsync(async () => {
    if (!signer || !chainId) return;
    const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
    const paymentPlanReader = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
    const nextPayment = await paymentPlanReader.getPaymentInfoByPlanIdMapped(plan.planId, false);
    if (!plan?.defaultedAt || dayjs(nextPayment.dueDate).add(plan.term, "seconds").isBefore(Date.now())) {
      setDefaultedError(`This plan has defaulted, and will not accept payments.`);
      return;
    }
  }, [plan.planId]);

  const amountToComplete = useMemo(() => {
    if (!plan) return;
    return f.PaymentPlanV2Contract.getRemainingPaymentInfoSync({
      downpaymentRate: isBnplPlan(plan) ? (plan as IBNPL).downpaymentPercent : 0,
      interestRate: plan.interestRate,
      serviceFeeRate: plan.serviceFeePercent,
      totalNumberOfPayments: plan.totalNumOfPayments,
      counterPaidPayments: plan.currentNumOfPayments,
      amount: isBnplPlan(plan) ? BigNumber.from((plan as IBNPL).price) : BigNumber.from(plan.pawnedAmount),
    });
  }, [plan.planId]);

  const planData = useMemo(() => {
    const isBnpl = isBnplPlan(plan) && planType === PlanTypes.BNPL;
    const bnplOriginalPrice = isBnpl ? (plan as IBNPL).price : undefined;
    const totalNumOfPaymentsByPlanType = isBnpl ? plan.totalNumOfPayments - 1 : plan.totalNumOfPayments;
    const totalNumOfPaymentsLeft = plan.totalNumOfPayments - plan.currentNumOfPayments;
    const totalLoanAmount = BigNumber.from(plan.monthlyAmount).mul(totalNumOfPaymentsByPlanType);
    const totalLeftAmount = BigNumber.from(plan.monthlyAmount).mul(totalNumOfPaymentsLeft);
    const totalCost = isBnpl ? totalLoanAmount.add(BigNumber.from(plan.downpaymentAmount)) : totalLoanAmount;
    const principleAmount = getPlanPrincipleAmount(plan);
    const totalRepayAmount = principleAmount.add(plan.interestFee).add(plan.serviceFeeAmount);
    const interestRate = getInterestRate({
      principleAmount,
      payAmount: totalRepayAmount,
      decimals: plan.currency.decimal,
    });
    const apr = getApr({
      maturity: plan.term * totalNumOfPaymentsByPlanType,
      interestRate,
    });
    const isRevivalPossible = !!plan.revivalInfo?.isRevivalPossible;
    const penaltyRate = plan.revivalInfo?.penaltyRate ?? 0;
    const penaltyAmount = plan.revivalInfo?.penaltyAmount ?? BigNumber.from(0);
    const totalPayNow = BigNumber.from(plan.monthlyAmount).add(penaltyAmount);
    return {
      isBnpl,
      bnplOriginalPrice,
      totalNumOfPaymentsByPlanType,
      totalNumOfPaymentsLeft,
      totalLoanAmount,
      totalLeftAmount,
      totalCost,
      principleAmount,
      totalRepayAmount,
      interestRate,
      apr,
      isRevivalPossible,
      penaltyRate,
      penaltyAmount,
      totalPayNow,
    };
  }, [plan]);

  const formatNumber = useMemo(() => {
    return PlanCreationDecimalFormatMap.get(plan.currency.decimal) || 4;
  }, [plan.currency.decimal]);

  const error = useMemo(() => {
    if (revivalError) {
      return {
        title: "Revival error",
        msg: "Failed to get revival info",
        description: revivalError.message,
      };
    }
    if (nextPaymentErrors) {
      return {
        title: "Next payment error",
        msg: "Failed to get next payment info",
        description: nextPaymentErrors.message,
      };
    }
    return null;
  }, [revivalError]);

  return {
    revivalOption,
    setRevivalOption,
    releaseWallet,
    setReleaseWallet,
    warning,
    setWarning,
    isNotifiedTransferWarning,
    setIsNotifiedTransferWarning,
    defaultedError,
    setDefaultedError,
    planData,
    amountToComplete,
    formatNumber,
    error,
    isLoading: revivalLoading || loading,
    revivalInfo,
  };
};
