import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { BigNumber } from "ethers";
import orderBy from "lodash.orderby";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAsync, useAsyncAbortable } from "react-async-hook";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { UpdatedBNPLEvent } from "@cyanco/contract/abis/CyanPaymentPlanV2";
import { BnplStatuses } from "@cyanco/contract/lib/types";

import { IBnplWithApePlan, IPawnWithApePlan } from "@/apis/ape-plans";
import { fetchUserCreatedP2PLoanOffers, fetchUserP2PPlans } from "@/apis/p2p";
import { IP2PUserCreatedOffer, IPeerPlan, PeerPlanStatuses, isPeerPlan } from "@/apis/p2p/types";
import { fetchPrivateSalesWithNft } from "@/apis/private-sale";
import { fetchPositionsByUser, fetchUserNFTs, fetchVaultTransactionsByUser } from "@/apis/user";
import { IUserNFTsResult, IUserNft } from "@/apis/user/types";
import { IVault } from "@/apis/vault/types";
import { CYAN_TESTDRIVE_WALLET } from "@/config";
import { isSupportedChain } from "@/constants/chains";
import { usePaymentPlanContract } from "@/hooks/usePaymentPlanContract";
import { INftBe } from "@/types";
import { IsInTestDrive, bigNumToFloat } from "@/utils";
import { readPlansOnChain } from "@/utils/contract";
import { Experiments } from "@/utils/experimentList";

import { useAppContext } from "../AppContextProvider";
import { BNPLStatuses, IBNPL, IBNPLStatus, isBnplPlan } from "../Bnpl/bnpl.types";
import { useTransactionContext } from "../TransactionContextProvider";
import { useVaultPositions, useVaults } from "../Vault/VaultDataProvider";
import { IPosition, IVaultTokenTransaction } from "../Vault/types";
import { useWeb3React } from "../Web3ReactProvider";
import {
  PositionViewSortingAttributes,
  WalletTypes,
  useLendingTabContext,
  usePositionsTabContext,
  useVaultTokensTabContext,
  useWalletTabContext,
} from "./AccountPageContext";
import { checkIsRevivalPossible } from "./components/PositionView/utils";
import { IPawn, IPawnStatus, PawnStatuses, isPawnPlan } from "./pawn.types";
import { IPlan } from "./plan.types";

dayjs.extend(duration);

type IAccountDataContextType = {
  userAssets?: IUserNFTsResult;
  userAssetsLoading: boolean;
  fetchUserAssets: () => Promise<void>;
  loadMoreUserAssets: () => Promise<void>;

  cyanAssets?: IUserNFTsResult;
  cyanAssetsLoading: boolean;
  fetchCyanAssets: () => Promise<void>;
  loadMoreCyanAssets: () => Promise<void>;

  pawnPositions: Array<IPawn>;
  pawnLoading: boolean;
  fetchPawnPositions: () => Promise<Array<IPawn>>;
  bnplPositions: Array<IBNPL>;
  bnplLoading: boolean;
  fetchBNPLPositions: () => Promise<Array<IBNPL>>;
  vaultTransactions: Array<IVaultTokenTransaction>;
  fetchVaultTransactions: () => Promise<void>;

  peerPlans: Array<IPeerPlan>;
  peerPlansLoading: boolean;
  fetchPeerPlans: () => Promise<Array<IPeerPlan>>;

  createdLoanBids: Array<IP2PUserCreatedOffer>;
  createdLoanBidsLoading: boolean;
  fetchUserCreatedLoanBids: () => Promise<IP2PUserCreatedOffer[]>;

  privateSales: Array<INftBe>;
  privateSaleLoading: boolean;
  fetchPrivateSales: () => Promise<INftBe[]>;

  demoAssets: Array<IUserNft>;
};

export const AccountDataContext = createContext<IAccountDataContextType>({
  userAssets: { assets: [], continuation: null },
  userAssetsLoading: false,
  fetchUserAssets: async () => {},
  loadMoreUserAssets: async () => {},

  cyanAssets: { assets: [], continuation: null },
  cyanAssetsLoading: false,
  fetchCyanAssets: async () => {},
  loadMoreCyanAssets: async () => {},

  pawnPositions: [],
  pawnLoading: false,
  fetchPawnPositions: async () => [],
  bnplPositions: [],
  bnplLoading: false,
  fetchBNPLPositions: async () => [],
  vaultTransactions: [],
  fetchVaultTransactions: async () => {},

  createdLoanBids: [],
  createdLoanBidsLoading: false,
  fetchUserCreatedLoanBids: async () => [],

  peerPlans: [],
  peerPlansLoading: false,
  fetchPeerPlans: async () => [],

  privateSales: [],
  privateSaleLoading: false,
  fetchPrivateSales: async () => [],

  demoAssets: [],
});

export const filterAssets = (assets: Array<IUserNft>, positions: Array<IBNPL | IPawn | IPeerPlan>): Array<IUserNft> => {
  const assetsWithTokenCount = assets.map(asset => {
    if (!asset.isCyanWallet) return asset;
    const assetPlans = positions.filter(position => {
      const isMetadataMatched =
        asset.address.toLowerCase() ===
          (isPeerPlan(position)
            ? position.collectionAddress.toLowerCase()
            : position.metadata.collectionAddress.toLowerCase()) && asset.tokenId === position.tokenId;
      if (position.planType === "Pawn") {
        return (
          isMetadataMatched &&
          (position.status === PawnStatuses.Activated || position.status === PawnStatuses.Defaulted)
        );
      }
      if (position.planType === "BNPL") {
        return (
          isMetadataMatched &&
          (position.status === BNPLStatuses.Activated || position.status === BNPLStatuses.Defaulted)
        );
      }
      if (position.planType === "P2P") {
        return isMetadataMatched && position.status === PeerPlanStatuses.ACTIVE;
      }
    });
    return {
      ...asset,
      ownership: {
        tokenCount: asset.ownership.tokenCount - assetPlans.length,
      },
    };
  });
  return assetsWithTokenCount.filter(asset => asset.ownership.tokenCount > 0);
};

const calculateDueDate = (position: IPlan) => {
  const nextPaymentNum =
    position.planType === "BNPL" ? position.currentNumOfPayments : position.currentNumOfPayments + 1;
  return dayjs(position.createdAt)
    .add(dayjs.duration(position.term * nextPaymentNum, "seconds"))
    .toDate();
};

const calculatePeerPlanDueDate = (position: IPeerPlan) => {
  return dayjs(position.createdAt).add(dayjs.duration(position.loanBid.term, "seconds")).toDate();
};

const calculatePeerPlanPayAmount = (position: IPeerPlan) => {
  const fee = BigNumber.from(position.loanBid.amount)
    .mul(position.loanBid.interestRate)
    .div(10000)
    .add(BigNumber.from(position.loanBid.amount).mul(position.serviceFeeRate).div(10000));
  return BigNumber.from(position.loanBid.amount).add(fee).toString();
};

export const AccountDataProvider: React.FC = ({ children }) => {
  const { showCyanSupportedProjects } = useWalletTabContext();
  const { transactions } = useTransactionContext();
  const { account, chainId } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { experiment } = useAppContext();
  const { contract: paymentPlanContractReader, filters: contractFilters } = usePaymentPlanContract();
  const [vaultTransactions, setVaultTransactions] = useState<Array<IVaultTokenTransaction>>([]);
  const isFetchableChain = useMemo(() => {
    if (!isSupportedChain(chainId)) return false;
    return true;
  }, [experiment, chainId]);
  const {
    result: pawnPositions = [],
    merge: setPawnPositions,
    loading: pawnLoading,
  } = useAsyncAbortable<Array<IPawn>>(
    async abortSignal => {
      if (IsInTestDrive || !account || !isSupportedChain(chainId)) return [];
      const result = await fetchPositionsByUser(
        account,
        {
          type: "Pawn",
          chainId,
        },
        abortSignal,
      );
      const { pawns } = await readPlansOnChain(result);
      return pawns.map(position => {
        if (position.status === PawnStatuses.Activated) {
          return {
            ...position,
            nextPaymentDate: calculateDueDate(position),
            isCyanWallet: true,
          };
        }
        return position;
      });
    },
    [account, IsInTestDrive, chainId],
  );

  const fetchPawnPositions = useCallback(async () => {
    if (!account) return [];
    const result = await fetchPositionsByUser(account, {
      type: "Pawn",
      chainId,
    });
    const { pawns } = await readPlansOnChain(result);
    setPawnPositions({
      result: pawns.map(position => {
        if (position.status === PawnStatuses.Activated) {
          return {
            ...position,
            nextPaymentDate: calculateDueDate(position),
            isCyanWallet: true,
          };
        }
        return position;
      }),
    });
    return pawns;
  }, [setPawnPositions, account, chainId]);

  const {
    result: bnplPositions = [],
    merge: updateBnplPositions,
    loading: bnplLoading,
  } = useAsyncAbortable<Array<IBNPL>>(
    async abortSignal => {
      if (IsInTestDrive || !account || !isSupportedChain(chainId)) return [];
      const plans = await fetchPositionsByUser(
        account,
        {
          type: "BNPL",
          chainId,
        },
        abortSignal,
      );
      const { bnpls } = await readPlansOnChain(plans);
      return bnpls.map(position => {
        if (position.status === BNPLStatuses.Activated) {
          return {
            ...position,
            nextPaymentDate: calculateDueDate(position),
            isCyanWallet: true,
          };
        }
        return position;
      });
    },
    [account, IsInTestDrive, chainId],
  );

  const fetchBNPLPositions = useCallback(async () => {
    if (!account) return [];
    const plans = await fetchPositionsByUser(account, {
      type: "BNPL",
      chainId,
    });
    const { bnpls } = await readPlansOnChain(plans);
    updateBnplPositions({
      result: bnpls.map(position => {
        if (position.status === BNPLStatuses.Activated) {
          return {
            ...position,
            nextPaymentDate: calculateDueDate(position),
            isCyanWallet: true,
          };
        }
        return position;
      }),
    });
    return bnpls;
  }, [account, updateBnplPositions, chainId]);

  const {
    result: userAsset,
    merge: updateUserAsset,
    loading: userAssetLoading,
  } = useAsyncAbortable<IUserNFTsResult>(
    async abortSignal => {
      try {
        if (IsInTestDrive)
          return {
            assets: [],
            continuation: null,
          };
        if (account && isFetchableChain) {
          return await fetchUserNFTs(account, {
            chainId,
            onlySupported: showCyanSupportedProjects,
            abortSignal,
          });
        }
      } catch (e) {
        console.error(e);
      }
      return {
        assets: [],
        continuation: null,
      };
    },
    [chainId, account, showCyanSupportedProjects, IsInTestDrive, isFetchableChain],
  );

  const fetchUserAssets = useCallback(async () => {
    if (IsInTestDrive) return;
    if (account && isFetchableChain) {
      const result = await fetchUserNFTs(account, {
        chainId,
        onlySupported: showCyanSupportedProjects,
      });
      updateUserAsset({
        result,
        loading: false,
      });
    }
  }, [account, showCyanSupportedProjects, IsInTestDrive, isFetchableChain]);

  const {
    result: cyanAssets,
    merge: updateCyanAssets,
    loading: cyanAssetsLoading,
  } = useAsyncAbortable<IUserNFTsResult>(
    async abortSignal => {
      try {
        if (cyanWallet && isFetchableChain) {
          const { assets, continuation } = await fetchUserNFTs(cyanWallet.walletAddress, {
            chainId,
            onlySupported: showCyanSupportedProjects,
            abortSignal,
          });
          return {
            continuation,
            assets: assets.map(asset => ({ ...asset, isCyanWallet: true })),
          };
        }
      } catch (e) {
        console.error(e);
      }
      return {
        assets: [],
        continuation: null,
      };
    },
    [chainId, cyanWallet, showCyanSupportedProjects, isFetchableChain],
  );

  const isP2pSupport = useMemo(() => {
    return experiment.result && experiment.result[Experiments.P2P];
  }, [experiment]);

  const fetchCyanAssets = useCallback(async () => {
    if (cyanWallet && isFetchableChain) {
      const { assets, continuation } = await fetchUserNFTs(cyanWallet.walletAddress, {
        chainId,
        onlySupported: showCyanSupportedProjects,
      });
      updateCyanAssets({
        result: {
          continuation,
          assets: assets.map(asset => ({ ...asset, isCyanWallet: true })),
        },
        loading: false,
      });
    }
  }, [cyanWallet, showCyanSupportedProjects, isFetchableChain]);

  const loadMoreCyanAssets = useCallback(async () => {
    if (cyanWallet && cyanAssets && isFetchableChain) {
      const { assets, continuation } = await fetchUserNFTs(cyanWallet.walletAddress, {
        chainId,
        onlySupported: showCyanSupportedProjects,
        continuation: cyanAssets.continuation,
      });
      updateCyanAssets({
        result: {
          continuation,
          assets: [...cyanAssets.assets, ...assets].map(asset => ({ ...asset, isCyanWallet: true })),
        },
      });
    }
  }, [cyanAssets, pawnPositions, bnplPositions, isFetchableChain]);

  const loadMoreUserAssets = useCallback(async () => {
    if (account && userAsset && userAsset.continuation && !userAssetLoading && isFetchableChain) {
      const { assets, continuation } = await fetchUserNFTs(account, {
        chainId,
        onlySupported: showCyanSupportedProjects,
        continuation: userAsset.continuation,
      });
      updateUserAsset({
        result: {
          continuation,
          assets: [...userAsset.assets, ...assets].filter(
            asset =>
              !pawnPositions.some(
                position =>
                  asset.address.toLowerCase() === position.metadata.collectionAddress.toLowerCase() &&
                  asset.tokenId === position.tokenId &&
                  position.status === PawnStatuses.Activated,
              ),
          ),
        },
      });
    }
  }, [userAsset, pawnPositions, isFetchableChain]);

  const {
    result: peerPlans = [],
    merge: updatePeerPlans,
    loading: peerPlansLoading,
  } = useAsyncAbortable<IPeerPlan[]>(
    async abortSignal => {
      try {
        if (IsInTestDrive || !isP2pSupport || !account) return [];
        return (
          await fetchUserP2PPlans({
            chainId,
            wallet: account,
            abortSignal,
          })
        ).map(plan => ({
          ...plan,
          nextPaymentDate: calculatePeerPlanDueDate(plan),
          monthlyAmount: calculatePeerPlanPayAmount(plan),
        }));
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    [account, chainId, isP2pSupport, IsInTestDrive],
  );

  const fetchPeerPlans = useCallback(async () => {
    if (account) {
      const result = (
        await fetchUserP2PPlans({
          chainId,
          wallet: account,
        })
      ).map(plan => ({
        ...plan,
        nextPaymentDate: calculatePeerPlanDueDate(plan),
        monthlyAmount: calculatePeerPlanPayAmount(plan),
      }));
      updatePeerPlans({
        result,
        loading: false,
      });
      return result;
    }
    return [];
  }, [account, chainId]);

  const {
    result: createdLoanBids = [],
    merge: updateCreatedLoanBids,
    loading: createdLoanBidsLoading,
  } = useAsyncAbortable<IP2PUserCreatedOffer[]>(
    async abortSignal => {
      try {
        if (IsInTestDrive || !isP2pSupport || !account) return [];
        return fetchUserCreatedP2PLoanOffers({
          chainId,
          wallet: account,
          abortSignal,
        });
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    [account, chainId, isP2pSupport, IsInTestDrive],
  );

  const fetchUserCreatedLoanBids = useCallback(async () => {
    if (account) {
      const result = await fetchUserCreatedP2PLoanOffers({
        chainId,
        wallet: account,
      });
      updateCreatedLoanBids({
        result,
        loading: false,
      });
      return result;
    }
    return [];
  }, [account, chainId]);

  const {
    result: privateSales = [],
    merge: updatePrivateSales,
    loading: privateSaleLoading,
  } = useAsyncAbortable<INftBe[]>(
    async abortSignal => {
      try {
        if (IsInTestDrive || !account) return [];
        return fetchPrivateSalesWithNft({
          sellerAddresses: [account],
          abortSignal,
          chainId,
        });
      } catch (e) {
        return [];
      }
    },
    [account, chainId, IsInTestDrive],
  );

  const fetchPrivateSales = useCallback(async () => {
    if (account) {
      const result = await fetchPrivateSalesWithNft({
        sellerAddresses: [account],
        chainId,
      });
      updatePrivateSales({
        result,
        loading: false,
      });
      return result;
    }
    return [];
  }, [account, chainId]);

  const fetchVaultTransactions = useCallback(async () => {
    if (!account) return;
    const transactions = [];
    if (!IsInTestDrive) {
      const mainWalletTransactions = await fetchVaultTransactionsByUser(account);
      transactions.push(...mainWalletTransactions);
    }
    if (cyanWallet) {
      const cyanWalletWalletTransactions = await fetchVaultTransactionsByUser(cyanWallet.walletAddress);
      transactions.push(...cyanWalletWalletTransactions.map(transaction => ({ ...transaction, isCyanWallet: true })));
    }
    setVaultTransactions(transactions);
  }, [fetchVaultTransactionsByUser, account, cyanWallet, IsInTestDrive]);

  useEffect(() => {
    fetchVaultTransactions();
  }, [fetchVaultTransactions]);

  useEffect(() => {
    if (!paymentPlanContractReader || !transactions.some(transaction => transaction.type === "bnpl-create")) return;

    paymentPlanContractReader.on<UpdatedBNPLEvent>(contractFilters.UpdatedBNPL(), fetchBNPLPositions);

    return () => {
      paymentPlanContractReader.off<UpdatedBNPLEvent>(contractFilters.UpdatedBNPL(), fetchBNPLPositions);
    };
  }, [bnplPositions, paymentPlanContractReader, fetchBNPLPositions, transactions]);

  const { result: demoAssets = [] } = useAsync<IUserNft[]>(async () => {
    if (
      !userAssetLoading &&
      !cyanAssetsLoading &&
      userAsset &&
      cyanAssets &&
      userAsset.assets.length === 0 &&
      cyanAssets.assets.length === 0 &&
      isFetchableChain
    ) {
      const { assets } = await fetchUserNFTs(CYAN_TESTDRIVE_WALLET, {
        chainId,
        onlySupported: showCyanSupportedProjects,
      });
      return assets;
    }
    return [];
  }, [account, userAsset, cyanAssets, userAssetLoading, cyanAssetsLoading, isFetchableChain]);

  return (
    <AccountDataContext.Provider
      value={{
        userAssets: userAsset,
        userAssetsLoading: userAssetLoading,
        fetchUserAssets,
        loadMoreUserAssets,

        cyanAssets,
        cyanAssetsLoading,
        fetchCyanAssets,
        loadMoreCyanAssets,

        pawnPositions,
        pawnLoading,
        fetchPawnPositions,
        bnplPositions,
        bnplLoading,
        fetchBNPLPositions,
        vaultTransactions,
        fetchVaultTransactions,

        createdLoanBids,
        createdLoanBidsLoading,
        fetchUserCreatedLoanBids,

        peerPlans,
        peerPlansLoading,
        fetchPeerPlans,

        privateSales,
        privateSaleLoading,
        fetchPrivateSales,

        demoAssets,
      }}
    >
      {children}
    </AccountDataContext.Provider>
  );
};

export const useUserAssets = () => {
  const { account } = useWeb3React();
  const {
    userAssets,
    userAssetsLoading,
    fetchUserAssets,
    loadMoreUserAssets,
    cyanAssetsLoading,
    cyanAssets,
    fetchCyanAssets,
    loadMoreCyanAssets,
    pawnPositions,
    bnplPositions,
    peerPlans,
  } = useContext(AccountDataContext);
  const { selectedNftSearch, selectedWalletType } = useWalletTabContext();
  const userAssetsFiltered = useMemo(() => {
    if (!userAssets || !cyanAssets) return [];
    const assets = [...cyanAssets.assets, ...userAssets.assets];
    if (selectedNftSearch !== "") {
      return assets.filter(
        asset =>
          (asset.tokenId.toLowerCase().startsWith(selectedNftSearch.toLowerCase()) ||
            asset.address.toLowerCase().startsWith(selectedNftSearch.toLowerCase()) ||
            asset.collectionName.toLowerCase().includes(selectedNftSearch.toLowerCase())) &&
          ((selectedWalletType === WalletTypes.mainWallet && !asset.isCyanWallet) ||
            (selectedWalletType === WalletTypes.cyanWallet && asset.isCyanWallet) ||
            selectedWalletType === WalletTypes.allWallets),
      );
    } else {
      return assets.filter(
        asset =>
          (selectedWalletType === WalletTypes.mainWallet && !asset.isCyanWallet) ||
          (selectedWalletType === WalletTypes.cyanWallet && asset.isCyanWallet) ||
          selectedWalletType === WalletTypes.allWallets,
      );
    }
  }, [userAssets?.assets, cyanAssets?.assets, selectedNftSearch, selectedWalletType]);

  const fetchAsset = useCallback(async () => {
    if (selectedWalletType === WalletTypes.mainWallet) {
      await fetchUserAssets();
    } else if (selectedWalletType === WalletTypes.cyanWallet) {
      await fetchCyanAssets();
    } else {
      await Promise.all([fetchUserAssets(), fetchCyanAssets()]);
    }
  }, [selectedWalletType, fetchUserAssets, fetchCyanAssets]);

  const hasMoreAssets = useMemo(() => {
    if (selectedWalletType === WalletTypes.mainWallet) {
      return !!userAssets?.continuation;
    } else if (selectedWalletType === WalletTypes.cyanWallet) {
      return !!cyanAssets?.continuation;
    } else {
      return !!userAssets?.continuation || !!cyanAssets?.continuation;
    }
  }, [selectedWalletType, userAssets, cyanAssets]);

  const loadMoreAssets = useCallback(async () => {
    if (!hasMoreAssets) return;
    if (selectedWalletType === WalletTypes.mainWallet && !!userAssets?.continuation) {
      await loadMoreUserAssets();
    } else if (selectedWalletType === WalletTypes.cyanWallet && !!cyanAssets?.continuation) {
      await loadMoreCyanAssets();
    } else {
      const promises = [];
      if (!!userAssets?.continuation) {
        promises.push(loadMoreUserAssets());
      }
      if (!!cyanAssets?.continuation) {
        promises.push(loadMoreCyanAssets());
      }
      await Promise.all(promises);
    }
  }, [selectedWalletType, loadMoreUserAssets, loadMoreCyanAssets]);

  const assetsLoading = useMemo(() => {
    if (selectedWalletType === WalletTypes.mainWallet) {
      return userAssetsLoading;
    } else if (selectedWalletType === WalletTypes.cyanWallet) {
      return cyanAssetsLoading;
    } else {
      return userAssetsLoading || cyanAssetsLoading;
    }
  }, [selectedWalletType, userAssetsLoading, cyanAssetsLoading]);

  return {
    userAssets: {
      assets: filterAssets(userAssetsFiltered, [
        ...pawnPositions,
        ...bnplPositions,
        ...peerPlans.filter(plan => plan.borrowerAddress.toLowerCase() === account?.toLowerCase()),
      ]),
      hasMore: hasMoreAssets,
    },
    userAssetsFiltered,
    userAssetsLoading: assetsLoading,
    fetchUserAssets: fetchAsset,
    loadMoreUserAssets: loadMoreAssets,
    hasMore: hasMoreAssets,
  };
};

export const usePawnPositions = () => {
  const { chainId } = useWeb3React();
  const { pawnPositions, fetchPawnPositions, pawnLoading } = useContext(AccountDataContext);
  const { selectedPositionSearch } = usePositionsTabContext();
  const pawnPositionsWithApePlan: IPawnWithApePlan[] = useMemo(() => {
    return pawnPositions.filter(e => e.apeStaking);
  }, [pawnPositions]);
  const pawnPositionsFiltered = useMemo(() => {
    let positions = pawnPositions;
    if (selectedPositionSearch !== "") {
      positions = pawnPositions.filter(
        position =>
          position.tokenId.toLowerCase().startsWith(selectedPositionSearch.toLowerCase()) ||
          position.metadata.collectionAddress.toLowerCase().startsWith(selectedPositionSearch.toLowerCase()) ||
          position.metadata.collection.name.toLowerCase().includes(selectedPositionSearch.toLowerCase()),
      );
    }
    return positions;
  }, [pawnPositions, selectedPositionSearch]);
  return {
    pawnPositions: pawnPositionsFiltered,
    activePawnPositions: pawnPositions.filter(
      pawn =>
        pawn.status === PawnStatuses.Activated &&
        pawn.nextPaymentDate &&
        dayjs().isBefore(pawn.nextPaymentDate) &&
        pawn.paymentPlan.chainId === chainId,
    ),
    revivablePawnPostions: pawnPositions.filter(
      pawn =>
        pawn.status === PawnStatuses.Defaulted && checkIsRevivalPossible(pawn) && pawn.paymentPlan.chainId === chainId,
    ),
    fetchPawnPositions,
    pawnLoading,
    pawnPositionsWithApePlan,
  };
};

export const useBNPLPositions = () => {
  const { chainId } = useWeb3React();
  const { bnplPositions, fetchBNPLPositions, bnplLoading } = useContext(AccountDataContext);
  const { selectedPositionSearch, showRecentRejectedLoans } = usePositionsTabContext();
  const bnplPositionsWithApePlan: IBnplWithApePlan[] = useMemo(() => {
    return bnplPositions.filter(e => e.apeStaking);
  }, [bnplPositions]);
  const bnplPositionsFiltered = useMemo(() => {
    let positions = bnplPositions;
    if (selectedPositionSearch !== "") {
      positions = bnplPositions.filter(
        position =>
          position.tokenId.toLowerCase().startsWith(selectedPositionSearch.toLowerCase()) ||
          position.metadata.collectionAddress.toLowerCase().startsWith(selectedPositionSearch.toLowerCase()) ||
          position.metadata.collection.name.toLowerCase().includes(selectedPositionSearch.toLowerCase()),
      );
    }
    if (showRecentRejectedLoans) {
      const date = dayjs().subtract(3, "days");
      positions = positions.filter(loan =>
        loan.status === BNPLStatuses.Rejected ? date.isBefore(loan.createdAt) : true,
      );
    }
    return positions;
  }, [bnplPositions, selectedPositionSearch, showRecentRejectedLoans]);

  return {
    bnplPositions: bnplPositionsFiltered,
    activeBnplPositions: bnplPositions.filter(
      bnpl =>
        bnpl.status === BNPLStatuses.Activated &&
        bnpl.nextPaymentDate &&
        dayjs().isBefore(bnpl.nextPaymentDate) &&
        bnpl.paymentPlan.chainId === chainId,
    ),
    revivableBnplPositions: bnplPositions.filter(
      bnpl =>
        bnpl.status === BNPLStatuses.Defaulted && checkIsRevivalPossible(bnpl) && bnpl.paymentPlan.chainId === chainId,
    ),
    fetchBNPLPositions,
    bnplLoading,
    bnplPositionsWithApePlan,
  };
};

export const usePeerPlans = () => {
  const { account } = useWeb3React();
  const { peerPlans, fetchPeerPlans, peerPlansLoading } = useContext(AccountDataContext);
  const { selectedPositionSearch } = usePositionsTabContext();
  const { selectedLendingSearch } = useLendingTabContext();
  const takenPlansFiltered = useMemo(() => {
    const filteredPlans = peerPlans.filter(plan => account && plan.borrowerAddress === account.toLowerCase());
    if (selectedPositionSearch !== "") {
      return filteredPlans.filter(
        plan =>
          plan.tokenId.toLowerCase().startsWith(selectedPositionSearch.toLowerCase()) ||
          plan.collectionAddress.toLowerCase().startsWith(selectedPositionSearch.toLowerCase()) ||
          plan.collection.name.toLowerCase().includes(selectedPositionSearch.toLowerCase()),
      );
    } else {
      return filteredPlans;
    }
  }, [peerPlans, selectedPositionSearch, account]);

  const givenPlansFiltered = useMemo(() => {
    const filteredPlans = peerPlans.filter(plan => account && plan.borrowerAddress !== account.toLowerCase());
    if (selectedLendingSearch !== "") {
      return filteredPlans.filter(
        plan =>
          plan.tokenId.toLowerCase().startsWith(selectedLendingSearch.toLowerCase()) ||
          plan.collectionAddress.toLowerCase().startsWith(selectedLendingSearch.toLowerCase()) ||
          plan.collection.name.toLowerCase().includes(selectedLendingSearch.toLowerCase()),
      );
    } else {
      return filteredPlans;
    }
  }, [peerPlans, selectedLendingSearch, account]);

  return {
    userTakenPeerPlans: takenPlansFiltered,
    userGivenPeerPlans: givenPlansFiltered,
    userActiveTakenPeerPlans: peerPlans.filter(
      position =>
        account && position.borrowerAddress === account.toLowerCase() && position.status === PeerPlanStatuses.ACTIVE,
    ),
    userActiveGivenPeerPlans: peerPlans.filter(
      position =>
        (account &&
          position.borrowerAddress !== account.toLowerCase() &&
          position.status === PeerPlanStatuses.ACTIVE) ||
        position.status === PeerPlanStatuses.DEFAULTED,
    ),
    fetchPeerPlans,
    peerPlansLoading,
  };
};

export const useVaultPositionsFiltered = () => {
  const [vaultPositionsFiltered, setFilteredVaultPositions] = useState<Array<{ position: IPosition; vault: IVault }>>(
    [],
  );
  const { vaults, fetchingVaults } = useVaults();
  const { vaultPositions, vaultPositionsCyan } = useVaultPositions();
  const { selectedVault, selectedVaultSearch } = useVaultTokensTabContext();
  const { chainId } = useWeb3React();
  const positionsWithVault = useMemo(() => {
    return [...vaultPositionsCyan.map(position => ({ ...position, isCyanWallet: true })), ...vaultPositions]
      .map(position => {
        const vault = vaults.find(({ id }) => id === position.vaultId);
        return {
          vault,
          position,
        };
      })
      .filter(item => item.vault?.chainId === chainId) as unknown as {
      position: IPosition;
      vault: IVault;
    }[];
  }, [vaultPositions, vaults, vaultPositionsCyan]);
  useEffect(() => {
    if (selectedVaultSearch !== "") {
      setFilteredVaultPositions(
        positionsWithVault.filter(
          position =>
            (position.position.vaultId.toString().startsWith(selectedVaultSearch.toLowerCase()) ||
              position.vault.symbol.toLowerCase().startsWith(selectedVaultSearch.toLowerCase()) ||
              position.vault.name.toLowerCase().startsWith(selectedVaultSearch.toLowerCase()) ||
              position.vault.contractTokenAddress.toLowerCase().startsWith(selectedVaultSearch.toLowerCase())) &&
            (selectedVault === 0 || selectedVault === position.position.vaultId),
        ),
      );
    } else {
      setFilteredVaultPositions(
        positionsWithVault.filter(position => selectedVault === 0 || selectedVault === position.position.vaultId),
      );
    }
  }, [positionsWithVault, selectedVaultSearch, selectedVault]);
  return { vaultPositions: vaultPositionsFiltered, loading: fetchingVaults };
};

export const useVaultTransactions = () => {
  const [vaultTransactionsFiltered, setFilteredVaultTransactions] = useState<Array<IVaultTokenTransaction>>([]);
  const { vaultTransactions, fetchVaultTransactions } = useContext(AccountDataContext);
  const { selectedVault, selectedVaultSearch } = useVaultTokensTabContext();
  useEffect(() => {
    if (selectedVaultSearch !== "") {
      setFilteredVaultTransactions(
        vaultTransactions.filter(
          transaction =>
            (transaction.vault.name.toLowerCase().startsWith(selectedVaultSearch.toLowerCase()) ||
              transaction.vault.symbol.toLowerCase().startsWith(selectedVaultSearch.toLowerCase())) &&
            (selectedVault === 0 || selectedVault === transaction.vaultId),
        ),
      );
    } else {
      setFilteredVaultTransactions(
        vaultTransactions.filter(transaction => selectedVault === 0 || selectedVault === transaction.vaultId),
      );
    }
  }, [vaultTransactions, selectedVaultSearch, selectedVault]);
  return { vaultTransactions: vaultTransactionsFiltered, fetchVaultTransactions };
};

export const usePositionsSorted = () => {
  const { pawnPositions } = usePawnPositions();
  const { bnplPositions } = useBNPLPositions();
  const { userTakenPeerPlans } = usePeerPlans();
  const { cyanAssets } = useContext(AccountDataContext);
  const { showDefaultedPlans, showRejectedPlans, selectedSortingAtttibute } = usePositionsTabContext();
  const { chainId } = useWeb3React();
  const positionsMerged = useMemo(() => {
    const pawnCurrentStatuses: Array<IPawnStatus> = [PawnStatuses.Activated];
    const bnplCurrentStatuses: Array<IBNPLStatus> = [BNPLStatuses.Pending, BNPLStatuses.Funded, BNPLStatuses.Activated];
    const peerPlanCurrentStatuses: Array<PeerPlanStatuses> = [PeerPlanStatuses.ACTIVE];
    if (showDefaultedPlans) {
      pawnCurrentStatuses.push(PawnStatuses.Defaulted);
      bnplCurrentStatuses.push(BNPLStatuses.Defaulted);
    }
    if (showRejectedPlans) {
      bnplCurrentStatuses.push(BNPLStatuses.Rejected);
    }
    return [
      ...pawnPositions
        .filter(position => pawnCurrentStatuses.includes(position.status) && position.paymentPlan.chainId === chainId)
        .filter(
          position =>
            position.status !== PawnStatuses.Defaulted ||
            (position.status === PawnStatuses.Defaulted && checkIsRevivalPossible(position)),
        ),
      ...bnplPositions
        .filter(position => bnplCurrentStatuses.includes(position.status) && position.paymentPlan.chainId === chainId)
        .filter(
          position =>
            position.status !== BNPLStatuses.Defaulted ||
            (position.status === BnplStatuses.Defaulted && checkIsRevivalPossible(position)),
        ),
      ...userTakenPeerPlans.filter(position => peerPlanCurrentStatuses.includes(position.status)),
    ].concat();
  }, [pawnPositions, bnplPositions, showDefaultedPlans, showRejectedPlans, chainId, userTakenPeerPlans]);
  const positionsSorted = useMemo(() => {
    switch (selectedSortingAtttibute.key) {
      case PositionViewSortingAttributes.paymentAmount:
        return positionsMerged.sort((a, b) => {
          const aAmount = BigNumber.from(a.monthlyAmount);
          const bAmount = BigNumber.from(b.monthlyAmount);
          return selectedSortingAtttibute.order === "asc"
            ? aAmount.gt(bAmount)
              ? 1
              : -1
            : aAmount.gt(bAmount)
            ? -1
            : 1;
        });
      case PositionViewSortingAttributes.paymentDate:
        return positionsMerged.sort((a, b) => {
          const isAsc = selectedSortingAtttibute.order === "asc";
          if (a.nextPaymentDate && b.nextPaymentDate) {
            return a.nextPaymentDate.getTime() > b.nextPaymentDate.getTime() ? (isAsc ? 1 : -1) : isAsc ? -1 : 1;
          } else if (a.nextPaymentDate) {
            return isAsc ? -1 : 1;
          } else if (b.nextPaymentDate) {
            return isAsc ? 1 : -1;
          }
          if (a.status < b.status) {
            return isAsc ? -1 : 1;
          } else if (a.status > b.status) {
            return isAsc ? 1 : -1;
          }
          return 0;
        });
      case PositionViewSortingAttributes.floorPrice:
        return orderBy(
          positionsMerged,
          [
            position => {
              const asset = (cyanAssets?.assets ?? []).find(
                asset =>
                  position.tokenId === asset.tokenId &&
                  ((isPeerPlan(position) && position.collectionAddress === asset.address) ||
                    ((isPawnPlan(position) || isBnplPlan(position)) &&
                      position.metadata.collectionAddress === asset.address)),
              );
              return asset?.collection.floorAskPrice;
            },
          ],
          selectedSortingAtttibute.order,
        );
      case PositionViewSortingAttributes.loanProgress:
        return orderBy(
          positionsMerged,
          [
            p =>
              isPawnPlan(p) || isBnplPlan(p)
                ? p.currentNumOfPayments
                : [PeerPlanStatuses.LIQUIDATED, PeerPlanStatuses.DEFAULTED].includes(p.status)
                ? 1
                : 0,
          ],
          selectedSortingAtttibute.order,
        );
      case PositionViewSortingAttributes.totalCost:
        return orderBy(
          positionsMerged,
          [
            p => {
              if (!isPeerPlan(p)) {
                if (p.planType === "BNPL") {
                  return BigNumber.from(p.monthlyAmount)
                    .mul(p.totalNumOfPayments - 1)
                    .add(p.downpaymentAmount);
                }
                return bigNumToFloat(BigNumber.from(p.monthlyAmount).mul(p.totalNumOfPayments), p.currency.decimal);
              } else {
                return 0;
              }
            },
          ],
          selectedSortingAtttibute.order,
        );
      default:
        return positionsMerged.sort((a, b) => {
          const aTokenId = Number(a.tokenId);
          const bTokenId = Number(b.tokenId);
          return selectedSortingAtttibute.order === "asc"
            ? aTokenId > bTokenId
              ? 1
              : -1
            : aTokenId > bTokenId
            ? -1
            : 1;
        });
    }
  }, [positionsMerged, selectedSortingAtttibute]);
  return {
    positionsSorted,
  };
};

export const useCreatedLoanBids = () => {
  const { createdLoanBidsLoading, createdLoanBids, fetchUserCreatedLoanBids } = useContext(AccountDataContext);
  return {
    createdLoanBidsLoading,
    createdLoanBids,
    fetchUserCreatedLoanBids,
  };
};

export const usePrivateSales = () => {
  const { privateSales, privateSaleLoading, fetchPrivateSales } = useContext(AccountDataContext);
  return {
    privateSales,
    privateSaleLoading,
    fetchPrivateSales,
  };
};
