import { BigNumber, TypedDataDomain, TypedDataField } from "ethers";

import { IApeCoinPlan } from "@/apis/ape-plans";
import {
  IActionType,
  IApeBulkStakingModal,
  IApeStakingModal,
  ISelectedNft,
} from "@/components/ApeCoinStaking/new/types";
import { isProd } from "@/config";
import { SupportedChainId } from "@/constants/chains";

export const types: Record<string, Array<TypedDataField>> = {
  ApeCoinPlan: [
    { name: "planId", type: "uint256" },
    { name: "amount", type: "uint224" },
    { name: "poolId", type: "uint256" },
    { name: "tokenId", type: "uint32" },
    { name: "wallet", type: "address" },
    { name: "blockNumber", type: "uint256" },
  ],
  ApeCoinPlanCreation: [
    { name: "ApeCoin Plans", type: "ApeCoinPlan[]" },
    { name: "nonce", type: "string" },
  ],
};

// All properties on a domain are optional
export const domain: TypedDataDomain = {
  name: "Cyan Ape Coin Plan",
  chainId: isProd ? SupportedChainId.MAINNET : SupportedChainId.SEPOLIA,
  version: "1",
};

export type IApeCoinBalance = {
  stakedAmountMain: BigNumber | null;
  stakedAmountCyan: BigNumber | null;
  earnedAmountCyan: BigNumber | null;
  earnedAmountMain: BigNumber | null;
  mainWalletMax: BigNumber | null;
  cyanWalletMax: BigNumber | null;
  allowance: BigNumber | null;
};

export type IInitiateBorrowedApeStake = IApeStakingModal & {
  selectedMainNft: ISelectedNft;
  cyanWalletAmount: string | null;
  mainWalletAmount: string | null;
};

export type IInitiateApeCoinStake = {
  err?: { title: string; msg: string; description?: string };
  action: IActionType;
  poolId: number;
  activePlan?: IApeCoinPlan;
  cyanWalletAmount: number;
  mainWalletAmount: number;
  apeCoinBalance: {
    stakedAmount: null | BigNumber;
    earnedAmount: BigNumber | null;
    mainWalletMax: BigNumber | null;
    cyanWalletMax: BigNumber | null;
    allowance: BigNumber | null;
  };
};

export type IInitiateOwnedApeStake = IApeStakingModal & {
  selectedMainNft: ISelectedNft;
  cyanWalletAmount: string | null;
  mainWalletAmount: string | null;
};

export type IInitiateBorrowedApeUnstake = IApeStakingModal & {
  selectedMainNft: ISelectedNft;
};

export type IInitiateApeCoinUnstake = {
  action: IActionType;
  err?: { title: string; msg: string; description?: string };
  planId?: number;
  unstakingAmount?: number;
  releaseWallet: string;
  sourceWallet: string;

  apeCoinBalance: {
    stakedAmount: null | BigNumber;
    earnedAmount: BigNumber | null;
    mainWalletMax: BigNumber | null;
    cyanWalletMax: BigNumber | null;
    allowance: BigNumber | null;
  };
};

export type IInitiateOwnedApeUnstake = IApeStakingModal & {
  selectedMainNft: ISelectedNft;
  releaseWallet: string;
  unstakingAmount: string;
  isClaiming: boolean;
};

export type IInitiateApeBulkStake = Omit<IApeBulkStakingModal, "selectedMainNfts"> & {
  selectedMainNfts: Array<
    ISelectedNft & { userStakingAmount: BigNumber; isStakingPossible?: boolean; borrowingAmount: BigNumber }
  >;
  isLoan: boolean;
};

export type IInitiateApeBulkUnstake = IApeBulkStakingModal & {
  withdrawWallet: string;
};
