import { BigNumber } from "ethers";
import { ForwardedRef, forwardRef, useContext, useMemo } from "react";
import { Virtuoso, VirtuosoGrid } from "react-virtuoso";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { NotFound, useModal } from "@cyanco/components/theme/v3";

import { IPeerPlan, PeerPlanStatuses, isPeerPlan } from "@/apis/p2p/types";
import { BNPLStatuses, IBNPL, isBnplPlan } from "@/components/Bnpl/bnpl.types";
import { BnplDetailProgress } from "@/components/PlanCreation/BnplDetailProgress";
import { PeerPlanRepayment } from "@/components/PlanPayment/PeerPlan";
import { PlanRepayment } from "@/components/PlanPayment/PlanRepayment";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { PlanTypes } from "@/constants/plans";

import { getRarityRank } from "../../../../utils";
import { useAppContext } from "../../../AppContextProvider";
import { AccountDataContext, usePositionsSorted } from "../../AccountDataContext";
import { WalletViewStyle, useTabViewStyle } from "../../AccountPageContext";
import { useSelectedItems } from "../../SelectedItemsContext";
import { ContainerBox, Header, ListViewBox } from "../../index";
import { IPawn, PawnStatuses, isPawnPlan } from "../../pawn.types";
import { UserDefaultedPositionDetails } from "../WalletView/UserNftModals/UserDefaultedPositionDetail";
import { PositionCard } from "./PositionCard";
import { CurrentPeerPlanRow, CurrentPositionRow, PositionListHeader } from "./PositionRow";

const gridComponents = {
  List: forwardRef(({ children, ...props }, ref: ForwardedRef<HTMLDivElement>) => (
    <Container $showByGrid ref={ref} {...props}>
      {children}
    </Container>
  )),
};
gridComponents.List.displayName = "List";

const UserNftWrapper: React.FC<{ showByGrid: boolean; totalItems: number }> = ({
  children,
  showByGrid,
  totalItems,
}) => {
  if (showByGrid) {
    return (
      <Flex direction="column" gap="10px">
        <CurrenPositionWrapper>{children}</CurrenPositionWrapper>
      </Flex>
    );
  } else {
    return (
      <ListContainer>
        <ListViewBox>
          <Header>
            <PositionListHeader totalItems={totalItems} />
          </Header>
          <ContainerBox>{children}</ContainerBox>
        </ListViewBox>
      </ListContainer>
    );
  }
};

export const CurrentPositions: React.FC = () => {
  const { chainId } = useWeb3React();
  const { cyanAssets } = useContext(AccountDataContext);
  const { collections } = useAppContext();
  const { transactions } = useTransactionContext();
  const { setModalContent, unsetModal } = useModal();
  const { positionsSorted } = usePositionsSorted();
  const { tabViewStyle } = useTabViewStyle();
  const { items, toggleItem } = useSelectedItems();
  const showByGrid = useMemo(() => tabViewStyle === WalletViewStyle.grid, [tabViewStyle]);

  const showPeerPlanModal = (plan: IPeerPlan) => {
    if (plan.status !== PeerPlanStatuses.COMPLETED) {
      setModalContent({
        title: `Loan Details`,
        content: <PeerPlanRepayment plan={plan} onClose={unsetModal} />,
      });
      return;
    }
  };

  const showPawnPositionModal = (pawn: IPawn) => {
    if (pawn.status === PawnStatuses.Defaulted) {
      setModalContent({
        title: `Defaulted Loan`,
        content: <UserDefaultedPositionDetails plan={pawn} planType={PlanTypes.Pawn} />,
      });
      return;
    }
    if (pawn.status !== PawnStatuses.Completed) {
      setModalContent({
        title: `Loan Details`,
        content: (
          <PlanRepayment
            pawn={pawn}
            planType="pawn"
            onClose={() => {
              unsetModal();
            }}
          />
        ),
      });
      return;
    }
  };

  const showBnplPositionModal = (bnpl: IBNPL) => {
    if (bnpl.status === BNPLStatuses.Defaulted) {
      setModalContent({
        title: `Defaulted Loan`,
        content: <UserDefaultedPositionDetails plan={bnpl} planType={PlanTypes.BNPL} />,
      });
      return;
    }
    if (bnpl.status === BNPLStatuses.Activated || bnpl.status === BNPLStatuses.Liquidated) {
      setModalContent({
        title: `Loan Details`,
        content: (
          <PlanRepayment
            bnpl={bnpl}
            planType="bnpl"
            onClose={() => {
              unsetModal();
            }}
          />
        ),
      });
    }

    if (
      bnpl.status === BNPLStatuses.Pending ||
      bnpl.status === BNPLStatuses.Funded ||
      bnpl.status === BNPLStatuses.Rejected
    ) {
      setModalContent({
        content: (
          <BnplDetailProgress
            plan={{
              ...bnpl,
              price: BigNumber.from(bnpl.price),
              planType: "bnpl",
              address: bnpl.metadata.collectionAddress,
              collectionName: bnpl.metadata.collection.name,
              imageUrl: bnpl.metadata.imageUrl,
              currency: bnpl.currency,
            }}
          />
        ),
      });
    }
  };

  return (
    <UserNftWrapper showByGrid={showByGrid} totalItems={positionsSorted.length}>
      {positionsSorted.length === 0 && <NotFound msg={`No positions found`} />}
      {!showByGrid ? (
        <Container role="grid" $showByGrid={false}>
          <Virtuoso
            customScrollParent={window.document.getElementById("custom-scroll-parent") ?? undefined}
            totalCount={positionsSorted.length}
            data={positionsSorted}
            itemContent={(index, position) => {
              const asset = (cyanAssets?.assets ?? []).find(
                asset =>
                  position.tokenId === asset.tokenId &&
                  ((isPeerPlan(position) && position.collectionAddress === asset.address) ||
                    ((isPawnPlan(position) || isBnplPlan(position)) &&
                      position.metadata.collectionAddress === asset.address)),
              );
              return position.planType === "P2P" ? (
                <CurrentPeerPlanRow
                  position={{
                    ...position,
                    floor: asset?.collection.floorAskPrice,
                  }}
                  key={`${position.planId}:${position.tokenId}`}
                  onClick={() => {
                    showPeerPlanModal(position);
                  }}
                  isSelected={items.some(
                    item =>
                      isPeerPlan(item) &&
                      item.collectionAddress === position.collectionAddress &&
                      item.tokenId === position.tokenId,
                  )}
                  toggleItem={() => toggleItem(position)}
                  loading={transactions.some(t => t.data && t.data.planId === position.planId && t.type === "p2p-pay")}
                />
              ) : (
                <CurrentPositionRow
                  isLast={index === positionsSorted.length - 1}
                  position={{
                    ...position,
                    floor: asset?.collection.floorAskPrice,
                  }}
                  key={`${position.planId}:${position.tokenId}`}
                  onClick={() => {
                    if (position.planType === "Pawn") {
                      showPawnPositionModal(position as IPawn);
                    } else {
                      showBnplPositionModal(position as IBNPL);
                    }
                  }}
                  isSelected={items.some(
                    item =>
                      ((position.planType === "Pawn" && isPawnPlan(item)) ||
                        (position.planType === "BNPL" && isBnplPlan(item))) &&
                      item.metadata.collectionAddress === position.metadata.collectionAddress &&
                      item.tokenId === position.tokenId,
                  )}
                  toggleItem={() => toggleItem(position)}
                  loading={transactions.some(
                    t =>
                      t.data &&
                      t.data.planId === position.planId &&
                      t.type === (position.planType === "Pawn" ? "pawn-pay" : "bnpl-pay"),
                  )}
                  hasMultiSelectionBox={items.length > 0}
                />
              );
            }}
          />
        </Container>
      ) : (
        <VirtuosoGrid
          useWindowScroll
          totalCount={positionsSorted.length}
          data={positionsSorted}
          components={gridComponents}
          width="100%"
          itemContent={(_, position) => {
            return (
              <PositionCard
                position={{
                  ...position,
                  rarity:
                    position.planType === "P2P"
                      ? getRarityRank(
                          position.metadata ? position.metadata.rarityRank : null,
                          position.collectionAddress,
                          collections,
                        )
                      : getRarityRank(position.metadata.rarityRank, position.metadata.collectionAddress, collections),
                }}
                isSelected={items.some(
                  item =>
                    (((position.planType === "Pawn" && isPawnPlan(item)) ||
                      (position.planType === "BNPL" && isBnplPlan(item))) &&
                      item.metadata.collectionAddress === position.metadata.collectionAddress &&
                      item.tokenId === position.tokenId) ||
                    (isPeerPlan(item) &&
                      item.planId === position.planId &&
                      item.tokenId === position.tokenId &&
                      item.planType === position.planType),
                )}
                toggleItem={() => toggleItem(position)}
                key={`${position.planId}:${position.tokenId}`}
                onClick={() => {
                  if (position.planType === "Pawn") {
                    showPawnPositionModal(position as IPawn);
                  } else if (position.planType === "BNPL") {
                    showBnplPositionModal(position as IBNPL);
                  } else {
                    showPeerPlanModal(position);
                  }
                }}
                chainId={chainId}
                loading={transactions.some(
                  t =>
                    t.data &&
                    t.data.planId === position.planId &&
                    t.type ===
                      (position.planType === "Pawn"
                        ? "pawn-pay"
                        : position.planType === "P2P"
                        ? "p2p-pay"
                        : "bnpl-pay"),
                )}
              />
            );
          }}
        />
      )}
    </UserNftWrapper>
  );
};

const CurrenPositionWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 30px;
`;

const Container = styled.div<{ $showByGrid: boolean }>`
  ${({ $showByGrid }) => {
    if ($showByGrid) {
      return ` display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  ${getStyleWithMediaQuery("grid-column-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-row-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-template-columns", "", [
    { [breakpoints.desktop]: "repeat(auto-fill, minmax(200px, 1fr))" },
    { [breakpoints.tablet]: "repeat(auto-fill, minmax(180px, 1fr))" },
    { [breakpoints.mobile]: "repeat(auto-fill, minmax(140px, 1fr))" },
  ])}; `;
    } else {
      return ` display: flex;
      flex-direction: column;
      width: 100%;
   @media only screen and (max-width: 414px) {
     border: none;
     border-radius: 20px;
     padding: 0;
   }
   > * {
     &:last-child {
       border-bottom-left-radius: 20px;
       border-bottom-right-radius: 20px;
     }
   }`;
    }
  }}
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 calc((100% - 1240px) / 2);
  padding-bottom: 50px;
  ${getStyleWithMediaQuery("margin", "", [
    { [breakpoints.laptopM]: "0 50px 30px 50px" },
    { [breakpoints.tablet]: "0px" },
  ])}
  ${getStyleWithMediaQuery("width", "", [
    { [breakpoints.desktop]: "calc(100% - calc((100% - 1240px)))" },
    { [breakpoints.laptopM]: "calc(100% - 100px)" },
    { [breakpoints.tablet]: "100%" },
  ])}
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.desktop]: 1 }, { [breakpoints.tablet]: 1 }])}
`;
