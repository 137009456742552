import { BigNumber, ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Flex } from "@cyanco/components/theme";
import { Card, SystemMessage, Text } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { CAPS_MAPPED_BY_ADDRESS, apeCoinContract } from "@/config";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";
import { bigNumToFixedStr, isApeCoinStakingPossible, numberWithCommas } from "@/utils";
import { IMappedError } from "@/utils/error/msgs";

import { IActionType, IApeBulkStakingModal, IApeCoinSource, IApeCoinUserBalance, ISelectedNft } from "../../types";
import { StyledButton } from "../common";
import { ActionSelector } from "./ActionSelector";
import { ApeCoinSourceSelector } from "./ApeCoinSourceSelector";
import { UserBulkNfts } from "./UserNftSelector";
import { BulkStakingBreakdown } from "./staking/BulkStakingBreakdown";
import { BulkUnstakingBreakdown } from "./unstaking/BulkUnstakingBreakdown";
import { ReleaseWalletSelector } from "./unstaking/ReleaseWalletSelector";

export const ApeCoinBulkStakingModal: React.FC<IApeBulkStakingModal> = ({
  action,
  apeCoinSource,
  selectedMainNfts,
  selectedCollection: _selectedCollection,
  removePlan,
  err,
}) => {
  const { account, provider, chainId } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { initiateBorrowedApeBulkStake, initiateBulkUnstake } = useApePlanCreation();

  const [userBalance, setUserBalance] = useState<IApeCoinUserBalance>({
    mainWalletMax: null,
    cyanWalletMax: null,
  });
  const [error, setError] = useState<IMappedError | null>(err || null);
  const [selectedSource, setSelectedSource] = useState<IApeCoinSource>(apeCoinSource);
  // BAYC, MAYC and BAKC addresses for staking
  const [selectedCollection, setSelectedCollection] = useState<string>(selectedMainNfts[0].address.toLowerCase());
  // Wallet address for APE release /owned/
  const [releaseWallet, setReleaseWallet] = useState<string>(account ?? "");

  useEffect(() => {
    const _setApeCoinBalance = async () => {
      if (!provider || !account || !isApeCoinStakingPossible(chainId)) return;

      const apeCoinContractWriter = f.ApeCoinFactory.connect(apeCoinContract, provider);
      let balanceCyan = BigNumber.from(0);
      const balanceMain = await apeCoinContractWriter.balanceOf(account);
      if (cyanWallet) {
        balanceCyan = await apeCoinContractWriter.balanceOf(cyanWallet.walletAddress);
      }
      setUserBalance({
        mainWalletMax: balanceMain,
        cyanWalletMax: balanceCyan,
      });
    };
    _setApeCoinBalance();
  }, [account, cyanWallet, chainId]);

  const stake = () => {
    setError(null);
    if (selectedSource === IApeCoinSource.borrow) {
      initiateBorrowedApeBulkStake({
        isLoan: true,
        action,
        apeCoinSource: selectedSource,
        selectedMainNfts: nftsWithStakingAmount,
        removePlan,
      });
    }
    if (selectedSource === IApeCoinSource.owned) {
      initiateBorrowedApeBulkStake({
        isLoan: false,
        action,
        apeCoinSource: selectedSource,
        selectedMainNfts: nftsWithStakingAmount,
        removePlan,
      });
    }
  };
  const unstake = () => {
    initiateBulkUnstake({
      withdrawWallet: releaseWallet,
      action,
      apeCoinSource: selectedSource,
      selectedMainNfts,
      removePlan,
    });
  };
  const onChangeCollection = (address: string) => {
    setError(null);
    setSelectedCollection(address);
  };

  const onChangeSelectedMainNft = (nft: ISelectedNft) => {
    setError(null);
    removePlan({
      address: nft.address.toLowerCase(),
      tokenId: nft.tokenId,
    });
  };

  const onChangeSelectedSource = (source: IApeCoinSource) => {
    setError(null);
    setSelectedSource(source);
  };
  const nftsWithStakingAmount = useMemo(() => {
    if (action === IActionType.unstake) return [];
    if (selectedSource === IApeCoinSource.borrow) {
      return selectedMainNfts.map(nft => {
        const cap = CAPS_MAPPED_BY_ADDRESS[nft.address];
        return {
          ...nft,
          borrowingAmount: ethers.utils.parseEther(cap.toString()).sub(nft.apeStaking.stakedAmount ?? 0),
          userStakingAmount: BigNumber.from(0),
          isDisabled: false,
        };
      });
    } else {
      let totalUserBalance = BigNumber.from(0);
      if (userBalance.cyanWalletMax) totalUserBalance = totalUserBalance.add(userBalance.cyanWalletMax);
      if (userBalance.mainWalletMax) totalUserBalance = totalUserBalance.add(userBalance.mainWalletMax);
      return selectedMainNfts.map(nft => {
        const cap = ethers.utils.parseEther(CAPS_MAPPED_BY_ADDRESS[nft.address].toString());
        const possibleStakingAmount = cap.sub(nft.apeStaking.stakedAmount ?? 0);
        let stakingAmount = BigNumber.from(0);
        let borrowingAmount = BigNumber.from(0);
        let isDisabled = false;
        if (totalUserBalance.eq(0)) {
          borrowingAmount = cap;
          isDisabled = true;
        } else if (possibleStakingAmount.lt(totalUserBalance)) {
          stakingAmount = possibleStakingAmount;
          borrowingAmount = cap.sub(possibleStakingAmount);
          totalUserBalance = totalUserBalance.sub(possibleStakingAmount);
        } else {
          stakingAmount = totalUserBalance;
          borrowingAmount = cap.sub(totalUserBalance);
          totalUserBalance = totalUserBalance.sub(totalUserBalance);
        }
        return {
          ...nft,
          userStakingAmount: stakingAmount,
          borrowingAmount,
          isDisabled,
        };
      });
    }
  }, [selectedMainNfts, selectedSource, userBalance]);

  const totalStakingAmount = useMemo(() => {
    return nftsWithStakingAmount.reduce((acc, cur) => {
      if (selectedSource === IApeCoinSource.borrow) {
        return acc.add(cur.borrowingAmount).add(cur.userStakingAmount);
      }
      return acc.add(cur.userStakingAmount);
    }, BigNumber.from(0));
  }, [nftsWithStakingAmount, selectedSource]);

  const totalBorrowingAmount = useMemo(() => {
    return nftsWithStakingAmount.reduce((acc, cur) => acc.add(cur.borrowingAmount), BigNumber.from(0));
  }, [nftsWithStakingAmount]);

  const unstakingNfts = useMemo(() => {
    return selectedMainNfts.map(nft => ({
      ...nft,
      stakedAmount: nft.apeStaking.stakedAmount ?? BigNumber.from(0),
      pendingRewards: nft.apeStaking.earnedAmount ?? BigNumber.from(0),
      pendingRewardsInVault: nft.apeStaking.plan?.totalRewards ?? BigNumber.from(0),
    }));
  }, [selectedMainNfts]);
  return (
    <Flex direction="column" gap="1rem">
      <UserBulkNfts
        selectedCollection={selectedCollection}
        selectedNfts={action === IActionType.stake ? nftsWithStakingAmount : selectedMainNfts}
        onChangeSelectedCollection={onChangeCollection}
        onChangeSelectedNft={onChangeSelectedMainNft}
        hideSelector={true}
      />
      {error && <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />}{" "}
      <Card p={"24px 12px"}>
        <Flex direction="column" gap="26px">
          <Flex justifyContent="center">
            <ActionSelector selectedAction={action} onChange={() => {}} disabled />
          </Flex>
          <Flex direction="column" gap="0.7rem">
            {action === IActionType.stake && (
              <ApeCoinSourceSelector selectedSource={selectedSource} onChange={onChangeSelectedSource} />
            )}
            {action === IActionType.stake && (
              <Flex direction="column" gap="0.5rem">
                <BulkStakingBreakdown nfts={nftsWithStakingAmount} source={selectedSource} hasLoan={true} />
                {selectedSource === IApeCoinSource.owned && userBalance.mainWalletMax && (
                  <Flex direction="column" gap="1rem">
                    <StakedBox>
                      <Flex justifyContent="space-between">
                        <Text size="xs" weight="500" color="secondary">
                          {`Main Wallet`}
                        </Text>
                        <Flex direction="column">
                          <Text size="xs" weight="500" color="secondary" textAlign="right">
                            {`${numberWithCommas(bigNumToFixedStr(userBalance.mainWalletMax), 2)} APE`}
                          </Text>
                        </Flex>
                      </Flex>
                      {userBalance.cyanWalletMax && (
                        <Flex justifyContent="space-between">
                          <Text size="xs" weight="500" color="secondary">
                            {`Cyan Wallet`}
                          </Text>
                          <Flex direction="column">
                            <Text size="xs" weight="500" color="secondary" textAlign="right">
                              {`${numberWithCommas(bigNumToFixedStr(userBalance.cyanWalletMax), 2)} APE`}
                            </Text>
                          </Flex>
                        </Flex>
                      )}
                      <Flex justifyContent="space-between">
                        <Text size="xs" weight="500" color="red">
                          {`Borrowing Amount`}
                        </Text>
                        <Flex direction="column">
                          <Text size="xs" weight="500" color="red" textAlign="right">
                            {`${numberWithCommas(bigNumToFixedStr(totalBorrowingAmount), 2)} APE`}
                          </Text>
                        </Flex>
                      </Flex>
                    </StakedBox>
                  </Flex>
                )}
              </Flex>
            )}
            {action === IActionType.unstake && (
              <>
                <BulkUnstakingBreakdown nfts={unstakingNfts} source={IApeCoinSource.all} />
                {selectedMainNfts.some(nft => nft.apeStaking.plan === null) && (
                  <ReleaseWalletSelector setReleaseWallet={setReleaseWallet} releaseWallet={releaseWallet} />
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Card>
      {action === IActionType.stake && (
        <StyledButton onClick={stake} disabled={totalStakingAmount.lte(0)}>{`Stake APE`}</StyledButton>
      )}
      {action === IActionType.unstake && <StyledButton onClick={unstake}>{`Unstake APE`}</StyledButton>}
    </Flex>
  );
};

const StakedBox = styled(Flex)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 1rem 0.8rem;
  flex-direction: column;
  gap: 0.4rem;
`;
