import { BigNumber } from "ethers";

import { IPawn } from "@/components/Account/pawn.types";
import { IApePlanHistory } from "@/components/ApeCoinStaking/new/types";
import { IBNPL } from "@/components/Bnpl/bnpl.types";

import { axios } from "./axios";
import { IUserNft } from "./user/types";

export enum ApePlanStatuses {
  Pending = 0,
  Started = 1,
  Completed = 2,
}

type IApePlan = {
  id: number;
  mainPoolId: number | null;
  mainTokenId: string;
  poolId: number;
  tokenId: string;
  totalVaultTokens: string | null;
  status: ApePlanStatuses;
};

export type IApeCoinPlan = {
  id: number;
  createdAt: Date;
  amount: string;
};

export type IApePlanData = {
  apeStaking: {
    borrowedApe: BigNumber;
    planId: number;
    status: ApePlanStatuses;
    totalRewards: BigNumber;
    rewardStakeToCyanVault: boolean;
  } | null;
};

type IApeStakedData = {
  stakedAmountBakc: BigNumber;
  stakedAmountBayc: BigNumber;
  stakedAmountMayc: BigNumber;
};

export type IUserNftWithApePlanBe = IUserNft & IApePlanData;
export type IPawnWithApePlan = IPawn & IApePlanData;
export type IBnplWithApePlan = IBNPL & IApePlanData;

export const fetchUserNftsWithApePlans = async ({
  wallet,
  abortSignal,
}: {
  wallet: string;
  abortSignal?: AbortSignal;
}): Promise<IUserNftWithApePlanBe[]> => {
  const { data } = await axios.get<IUserNftWithApePlanBe[]>(`/v2/ape-plans/${wallet}/nfts`, {
    signal: abortSignal,
  });
  return data;
};

export const fetchUserApePlanHistory = async ({
  wallet,
  abortSignal,
}: {
  wallet: string;
  abortSignal?: AbortSignal;
}): Promise<IApePlanHistory[]> => {
  const { data } = await axios.get<IApePlanHistory[]>(`/v2/ape-plans/${wallet}/history`, {
    signal: abortSignal,
  });
  return data.map(item => {
    return {
      ...item,
      createdAt: new Date(item.createdAt),
    };
  });
};

export const fetchUserApePlans = async ({
  wallet,
  abortSignal,
}: {
  wallet: string;
  abortSignal?: AbortSignal;
}): Promise<IApePlan[]> => {
  const { data } = await axios.get<IApePlan[]>(`/v2/ape-plans/${wallet}`, {
    signal: abortSignal,
  });
  return data;
};

export const fetchApeStakedAmounts = async (): Promise<IApeStakedData> => {
  const { data } = await axios.get<IApeStakedData>(`/v2/ape-plans/staked-amounts`);
  return {
    stakedAmountBakc: BigNumber.from(data.stakedAmountBakc),
    stakedAmountBayc: BigNumber.from(data.stakedAmountBayc),
    stakedAmountMayc: BigNumber.from(data.stakedAmountMayc),
  };
};
