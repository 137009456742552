import { BigNumber } from "ethers";

import { axios } from "../axios";

export const priceApeCoin = async (args: FnCreateApePlan["params"]): Promise<FnCreateApePlan["result"]> => {
  const { items, wallet } = args;
  const { data } = await axios.post<FnCreateApePlan["result"]>("/v2/ape-plans", {
    items: items.map(item => ({ ...item, amount: item.amount.toString() })),
    wallet,
  });
  return data.map(item => ({
    ...item,
    amount: BigNumber.from(item.amount),
  }));
};

export const createApeCoinAcceptance = async (
  args: FnCreateUserAcceptance["params"],
): Promise<FnCreateUserAcceptance["result"]> => {
  const { planIds, signature } = args;
  const { data } = await axios.post<FnCreateUserAcceptance["result"]>(`/v2/ape-plans/accept`, { planIds, signature });
  return data.map(item => ({
    ...item,
    amount: BigNumber.from(item.amount),
  }));
};

export type FnCreateApePlan = {
  result: IResponseData[];
  params: {
    wallet: string;
    items: Array<{
      mainPoolId?: number;
      mainTokenId?: string;
      poolId: number;
      tokenId: string;
      amount: BigNumber;
    }>;
  };
};

type FnCreateUserAcceptance = {
  result: IResponseData[];
  params: {
    planIds: Array<number>;
    signature: string;
  };
};

export type IResponseData = {
  planId: number;
  mainPoolId?: number;
  mainTokenId?: string;
  poolId: number;
  tokenId: string;
  amount: BigNumber;
  vaultAddress: string;
  signedBlockNumber: number;
  signature: string;
  wallet: string;
  isLoan: boolean;
};
