import { SupportedMarketPlaces } from "./marketplaces.type";

export const getMarketPlaceName = (marketName: string) => {
  switch (marketName.toLowerCase()) {
    case SupportedMarketPlaces.LOOKSRARE.toLowerCase():
      return "LooksRare";
    case SupportedMarketPlaces.OPENSEA.toLowerCase():
      return "OpenSea";
    case SupportedMarketPlaces.X2Y2.toLowerCase():
      return "X2Y2";
    case SupportedMarketPlaces.PUDGYPENGUINS.toLowerCase():
      return "PudgyPenguins";
    case SupportedMarketPlaces.SUDOSWAP.toLowerCase():
      return "sudoswap";
    case SupportedMarketPlaces.RESERVOIR.toLowerCase():
      return "Reservoir Explorer";
    case SupportedMarketPlaces.APECOINMARKETPLACE.toLowerCase():
      return "ApeCoin Marketplace";
    case SupportedMarketPlaces.NFTX.toLowerCase():
      return "NFTX";
    case SupportedMarketPlaces.BLUR.toLowerCase():
      return "BLUR";
    case SupportedMarketPlaces.GEM.toLowerCase():
      return "GEM";
    case SupportedMarketPlaces.CAVIAR.toLowerCase():
      return "Caviar";
    case SupportedMarketPlaces.PASS.toLowerCase():
      return "PASS";
    case SupportedMarketPlaces.RARIBLE.toLowerCase():
      return "Rarible";
    case SupportedMarketPlaces.CYANPRIVATESALE.toLowerCase():
      return "Cyan";
    case SupportedMarketPlaces.MAGICEDEN_US.toLowerCase():
    case SupportedMarketPlaces.MAGICEDEN.toLowerCase():
      return "Magic Eden";
    case SupportedMarketPlaces.CRYPTOPUNKS.toLowerCase():
      return "CryptoPunks";
    default:
      return "Unknown";
  }
};

const DecimalFormatMap = new Map<number, number>([
  [6, 2],
  [18, 4],
]);

export const ConvertDecimalFormat = (decimal: number) => {
  return DecimalFormatMap.get(decimal) || 4;
};

export const getMarketPlaceLogo = (marketDomain: string) => {
  return `https://api.reservoir.tools/redirect/sources/${marketDomain}/logo/v2`;
};
