import { getAddress } from "@ethersproject/address";
import { Provider } from "@ethersproject/providers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { BigNumber, BigNumberish, providers, utils } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import omit from "lodash.omit";
import { renderToString } from "react-dom/server";

import { SupportedMarketPlaces } from "@usecyan/shared/utils/marketplaces.type";

import { factories as f } from "@cyanco/contract";

import { ICollectionBe } from "@/apis/collection/types";
import { IPeerPlan } from "@/apis/p2p/types";
import { IVault } from "@/apis/vault/types";
import { IPawn } from "@/components/Account/pawn.types";
import { IPlanBe } from "@/components/Account/plan.types";
import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { VaultContractAbiNames } from "@/components/Vault/types";
import { DAY_SECONDS, HOUR_SECONDS, MINUTE_SECONDS, MONTH_SECONDS } from "@/hooks/usePlanCreationForm";
import { INftType } from "@/types";

import { apeCoinContract, getEnvOrThrow, isProd } from "../config";
import { CHAIN_IDS_TO_EXPLORER_NAME, CHAIN_IDS_TO_NAMES, SupportedChainId } from "../constants/chains";
import { SUPPORTED_WALLETS } from "../constants/wallet";
import { getGoogleCalendarDescription } from "./calendar";
import { isMobile } from "./userAgent";

export { getEnvOrThrow } from "../config";
dayjs.extend(utc);

export const isAddress = (value: string): string | false => {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
};

export const shortenAddress = (address: string, chars = 4): string => {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
};

export const validEmail = (mail: string): boolean => {
  return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
    mail,
  );
};

export const shortenName = (name: string, limit = 15, subStringLength = 7, postStringLength = 6): string => {
  const nameLen = name.length;
  return nameLen > limit
    ? `${name.substring(0, subStringLength)}...${name.substring(nameLen - postStringLength)}`
    : name;
};

export const watchSmallAmount = (num: number): boolean => {
  return num > 0.000001;
};

export const watchBelowStakeAmount = (amount: number, max: number): boolean => {
  return amount <= max;
};

export const displayInUSD = (arg: number): string => {
  return arg.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const numberWithCommas = (arg: string | number, fix = 0, removeTrailZeros?: boolean): string => {
  const nf = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: fix,
    minimumFractionDigits: removeTrailZeros ? undefined : fix,
  });
  return nf.format(Number(arg));
};

export const createHashSHA256 = async (message: string): Promise<string> => {
  const hashBuffer = await crypto.subtle.digest("sha-256", new TextEncoder().encode(message));
  return Array.from(new Uint8Array(hashBuffer))
    .map(b => b.toString(16).padStart(2, "0"))
    .join("");
};

export const bigNumToFloat = (amount: BigNumberish, decimals = 18) =>
  parseFloat(utils.formatUnits(BigNumber.from(amount), decimals));

export const bigNumToFixedStr = (amount: BigNumberish, fix = 5, decimals = 18) =>
  bigNumToFloat(amount, decimals).toFixed(fix);

export const bigNumToFloatFormatted = (amount: BigNumberish, decimals = 18, fix = 4) => {
  return numberWithCommas(bigNumToFloat(amount, decimals), fix, true);
};

const getMarketUrl = (marketName: string, chainId?: number) => {
  switch (chainId) {
    case SupportedChainId.POLYGON:
      switch (marketName.toLowerCase()) {
        case SupportedMarketPlaces.OPENSEA.toLowerCase():
          return `https://opensea.io`;
        case SupportedMarketPlaces.PASS.toLowerCase():
          return "https://pass.xyz";
        case SupportedMarketPlaces.RESERVOIR.toLowerCase():
          return `https://explorer.reservoir.tools`;
        default:
          return "";
      }
    case SupportedChainId.SEPOLIA:
      switch (marketName.toLowerCase()) {
        case SupportedMarketPlaces.LOOKSRARE.toLowerCase():
          return `https://sepolia.looksrare.org/collections`;
        case SupportedMarketPlaces.OPENSEA.toLowerCase():
          return `https://testnets.opensea.io`;
        case SupportedMarketPlaces.RESERVOIR.toLowerCase():
          return `https://dev.reservoir.market`;
        default:
          return "";
      }
    case SupportedChainId.APECHAIN:
      switch (marketName.toLowerCase()) {
        case SupportedMarketPlaces.MAGICEDEN_US.toLowerCase():
          return `https://magiceden.us`;
        case SupportedMarketPlaces.MAGICEDEN.toLowerCase():
          return `https://magiceden.io`;
        case SupportedMarketPlaces.MINTIFY.toLowerCase():
          return `https://apechain.mintify.xyz`;
        case SupportedMarketPlaces.RESERVOIR.toLowerCase():
          return `https://explorer.reservoir.tools`;
        default:
          return "";
      }
    case SupportedChainId.CURTIS:
      switch (marketName.toLowerCase()) {
        case SupportedMarketPlaces.OPENSEA.toLowerCase():
          return `https://testnets.opensea.io`;
        case SupportedMarketPlaces.RESERVOIR.toLowerCase():
          return `https://dev.reservoir.market`;
        default:
          return "";
      }
    case SupportedChainId.BLAST:
      switch (marketName.toLowerCase()) {
        case SupportedMarketPlaces.OPENSEA.toLowerCase():
          return `https://opensea.io`;
        case SupportedMarketPlaces.BLUR.toLowerCase():
          return `https://blur.io/blast`;
        case SupportedMarketPlaces.MINTIFY.toLowerCase():
          return `https://blast.mintify.xyz`;
        default:
          return "";
      }
    case SupportedChainId.BLAST_SEPOLIA:
      switch (marketName.toLowerCase()) {
        case SupportedMarketPlaces.OPENSEA.toLowerCase():
          return `https://testnets.opensea.io`;
        default:
          return "";
      }
    case SupportedChainId.MAINNET:
      switch (marketName.toLowerCase()) {
        case SupportedMarketPlaces.LOOKSRARE.toLowerCase():
          return `https://looksrare.org/collections`;
        case SupportedMarketPlaces.OPENSEA.toLowerCase():
          return `https://opensea.io`;
        case SupportedMarketPlaces.X2Y2.toLowerCase():
          return `https://x2y2.io`;
        case SupportedMarketPlaces.SUDOSWAP.toLowerCase():
          return `https://sudoswap.xyz/#/item`;
        case SupportedMarketPlaces.RESERVOIR.toLowerCase():
          return `https://explorer.reservoir.tools`;
        case SupportedMarketPlaces.NFTX.toLowerCase():
          return `https://nftx.io/vault`;
        case SupportedMarketPlaces.BLUR.toLowerCase():
          return `https://blur.io`;
        case SupportedMarketPlaces.GEM.toLowerCase():
          return `https://pro.opensea.io`;
        case SupportedMarketPlaces.CAVIAR.toLowerCase():
          return "https://caviar.sh";
        case SupportedMarketPlaces.RARIBLE.toLowerCase():
          return "https://rarible.com";
        case SupportedMarketPlaces.PASS.toLowerCase():
          return "https://pass.xyz";
        case SupportedMarketPlaces.MAGICEDEN.toLowerCase():
          return "https://magiceden.io";
        case SupportedMarketPlaces.MAGICEDEN_US.toLowerCase():
          return "https://magiceden.us";
        case SupportedMarketPlaces.MINTIFY.toLowerCase():
          return "https://trade.mintify.xyz";
        case SupportedMarketPlaces.CRYPTOPUNKS.toLowerCase():
          return "https://cryptopunks.app";
        default:
          return "";
      }
    default:
      return "";
  }
};

const getMarketAffiliateCode = (marketName: string, chainId?: number) => {
  switch (chainId) {
    case SupportedChainId.POLYGON:
      switch (marketName.toLowerCase()) {
        case SupportedMarketPlaces.LOOKSRARE:
          return `?ref=2067952`;
        default:
          return "";
      }
    case SupportedChainId.SEPOLIA:
      switch (marketName.toLowerCase()) {
        case SupportedMarketPlaces.LOOKSRARE:
          return `?ref=2067952`;
        default:
          return "";
      }
    case SupportedChainId.MAINNET:
      switch (marketName.toLowerCase()) {
        case SupportedMarketPlaces.LOOKSRARE:
          return `?ref=2067952`;
        default:
          return "";
      }
    default:
      return "";
  }
};

export const getMarketCollectionUrl = (
  marketName: SupportedMarketPlaces,
  collection: string,
  chainId?: SupportedChainId,
) => {
  const marketUrl = getMarketUrl(marketName, chainId);
  switch (marketName) {
    case SupportedMarketPlaces.OPENSEA:
    case SupportedMarketPlaces.GEM:
      return `${marketUrl}/collection/${collection}`;
    case SupportedMarketPlaces.X2Y2:
    case SupportedMarketPlaces.RARIBLE:
      return `${marketUrl}/collection/${collection}/items`;
    case SupportedMarketPlaces.CAVIAR:
      return `${marketUrl}/app/trade?collection=${collection}`;
    case SupportedMarketPlaces.PASS: {
      const _chainId = chainId ?? SupportedChainId.MAINNET;
      const chainName = _chainId === SupportedChainId.MAINNET ? "ethereum" : CHAIN_IDS_TO_NAMES[_chainId];
      return `${marketUrl}/collection/${chainName}/${collection}`;
    }
    case SupportedMarketPlaces.RESERVOIR: {
      const _chainId = chainId ?? SupportedChainId.MAINNET;
      const chainName = _chainId === SupportedChainId.MAINNET ? "ethereum" : CHAIN_IDS_TO_NAMES[_chainId];
      return `${marketUrl}/${chainName}/collection/${collection}`;
    }
    case SupportedMarketPlaces.MAGICEDEN_US.toLowerCase():
    case SupportedMarketPlaces.MAGICEDEN: {
      const _chainId = chainId ?? SupportedChainId.MAINNET;
      const chainName = _chainId === SupportedChainId.MAINNET ? "ethereum" : CHAIN_IDS_TO_NAMES[_chainId];
      return `${marketUrl}/${chainName}/collection/${collection}`;
    }
    case SupportedMarketPlaces.BLUR: {
      return `${marketUrl}/collection/${collection}`;
    }
    default:
      return `${marketUrl}/${collection}`;
  }
};

export const getMarketCollectionUrlWithAffiliate = (
  marketName: SupportedMarketPlaces,
  collection: string,
  chainId?: number,
) => {
  return getMarketCollectionUrl(marketName, collection, chainId) + getMarketAffiliateCode(marketName, chainId);
};

export const getMarketItemUrl = (
  marketName: string,
  collection: string,
  token: string,
  chainId?: SupportedChainId,
  owner?: string,
) => {
  const marketUrl = getMarketUrl(marketName.toLowerCase(), chainId);
  const _chainId = chainId ?? SupportedChainId.MAINNET;
  const chainName = _chainId === SupportedChainId.MAINNET ? "ethereum" : CHAIN_IDS_TO_NAMES[_chainId];
  if (marketName.toLowerCase() === SupportedMarketPlaces.CYANPRIVATESALE.toLowerCase()) {
    return "/bargain";
  }
  if (marketName.toLowerCase() === SupportedMarketPlaces.OPENSEA) {
    if (chainId === SupportedChainId.MAINNET) {
      return `${marketUrl}/assets/ethereum/${collection}/${token}`;
    }
    if (chainId === SupportedChainId.POLYGON) {
      return `${marketUrl}/assets/matic/${collection}/${token}`;
    }
    return `${marketUrl}/assets/${CHAIN_IDS_TO_NAMES[chainId || 1]}/${collection}/${token}`;
  }
  if (marketName.toLowerCase() === SupportedMarketPlaces.GEM) {
    return `${marketUrl}/nft/${collection}/${token}`;
  }
  if (marketName.toLowerCase() === SupportedMarketPlaces.X2Y2) {
    if (chainId === SupportedChainId.MAINNET) {
      return `${marketUrl}/eth/${collection}/${token}`;
    }
  }
  if (marketName.toLowerCase() === SupportedMarketPlaces.LOOKSRARE) {
    return `${marketUrl}/${collection}/${token}${getMarketAffiliateCode(marketName, chainId)}`;
  }
  if (marketName.toLowerCase() === SupportedMarketPlaces.CAVIAR) {
    return `${marketUrl}/app/trade?collection=${collection}`;
  }
  if (marketName.toLowerCase() === SupportedMarketPlaces.RESERVOIR) {
    return `${marketUrl}/${chainName}/asset/${collection}:${token}?tab=info`;
  }
  if (marketName.toLowerCase() === SupportedMarketPlaces.RARIBLE) {
    return `${marketUrl}/token/${collection}:${token}`;
  }
  if (marketName.toLowerCase() === SupportedMarketPlaces.PASS) {
    return `${marketUrl}/collection/${chainName}/${collection}/${token}`;
  }
  if (marketName.toLowerCase() === SupportedMarketPlaces.BLUR) {
    if (chainId === SupportedChainId.BLAST) {
      return `${marketUrl}/asset/${collection}/${token}`;
    }
    if (chainId === SupportedChainId.MAINNET) {
      return `${marketUrl}/asset/${collection}/${token}`;
    }
  }
  if (
    marketName.toLowerCase() === SupportedMarketPlaces.MAGICEDEN ||
    marketName.toLowerCase() === SupportedMarketPlaces.MAGICEDEN_US
  ) {
    if (chainId === SupportedChainId.MAINNET) {
      return `${marketUrl}/item-details/ethereum/${collection}/${token}`;
    }
    if (chainId === SupportedChainId.POLYGON) {
      return `${marketUrl}/item-details/polygon/${collection}/${token}`;
    }
    if (chainId === SupportedChainId.APECHAIN) {
      return `${marketUrl}/item-details/apechain/${collection}/${token}`;
    }
  }
  if (marketName.toLowerCase() === SupportedMarketPlaces.MINTIFY) {
    if (chainId === SupportedChainId.MAINNET) {
      return `${marketUrl}/token/eth/${collection}/${token}`;
    }
    if (chainId === SupportedChainId.APECHAIN) {
      return `${marketUrl}/token/apechain/${collection}/${token}`;
    }
    if (chainId === SupportedChainId.BLAST) {
      return `${marketUrl}/token/blast/${collection}/${token}`;
    }
  }
  if (marketName.toLowerCase() === SupportedMarketPlaces.NFTX) {
    return `${marketUrl}/${owner}/${token}`;
  }
  if (marketName.toLowerCase() === SupportedMarketPlaces.CRYPTOPUNKS) {
    return `${marketUrl}/cryptopunks/details/${token}`;
  }
  return `${marketUrl}/${collection}/${token}`;
};

export const getActiveConnector = (connector: any) => {
  let activeConnector;
  Object.keys(SUPPORTED_WALLETS).forEach(key => {
    const option = SUPPORTED_WALLETS[key];
    if (option.connector === connector) {
      activeConnector = option;
    }
  });
  return activeConnector;
};

export const ERRORS = {
  NO_BUY_NOW_PRICE: "Item has no Buy Now price.",
  USER_DEFAULTED: "Sorry, you're not allowed to start a new plan.",
};

export const getChainExplorerURL = (chainId?: number) => {
  switch (chainId) {
    case SupportedChainId.SEPOLIA:
      return "https://sepolia.etherscan.io";
    case SupportedChainId.MAINNET:
      return "https://etherscan.io";
    case SupportedChainId.POLYGON:
      return "https://polygonscan.com";
    case SupportedChainId.MUMBAI:
      return "https://mumbai.polygonscan.com";
    case SupportedChainId.ARBITRUM:
      return "https://arbiscan.io";
    case SupportedChainId.AVALANCHE:
      return "https://snowtrace.io";
    case SupportedChainId.KOVAN:
      return "https://kovan.etherscan.io";
    case SupportedChainId.RINKEBY:
      return "https://rinkeby.etherscan.io";
    case SupportedChainId.OPTIMISM:
      return "https://optimistic.etherscan.io";
    case SupportedChainId.ROPSTEN:
      return "https://ropsten.etherscan.io";
    case SupportedChainId.BLAST:
      return "https://blastscan.io";
    case SupportedChainId.BLAST_SEPOLIA:
      return "https://sepolia.blastscan.io";
    case SupportedChainId.APECHAIN:
      return "https://apescan.io";
    case SupportedChainId.CURTIS:
      return "https://curtis.explorer.caldera.xyz";
    default:
      return "https://etherscan.io";
  }
};

export const getGoogleCalendarLink = (position: IPawn | IBNPL, type: string) => {
  const UTCFormat = "YYYYMMDDTHHmmss";
  const isBnpl = type === "bnpl";

  const createdAt = dayjs(position.createdAt.toISOString());
  const nextPaymentDate = isBnpl
    ? createdAt.add(position.term * position.currentNumOfPayments, "seconds")
    : createdAt.add(position.term * (position.currentNumOfPayments + 1), "seconds");

  const minutesBefore = isProd ? 15 : 5;
  const startDate = nextPaymentDate.subtract(minutesBefore, "minutes").format(UTCFormat);
  const endDate = nextPaymentDate.format(UTCFormat);

  const subject = `Payment Reminder: Cyan ${isBnpl ? "BNPL" : "Loan"}`;
  const totalNumOfPaymentsLeft = position.totalNumOfPayments - position.currentNumOfPayments;
  const description = getGoogleCalendarDescription(position, type);
  const formattedDescription = renderToString(description);

  let googleLink = "https://calendar.google.com/calendar/u/0/r/eventedit?";
  googleLink += `dates=${startDate}/${endDate}`;
  if (totalNumOfPaymentsLeft > 1) {
    // NOTE: Google Calender minimum support on daily recuring (Ceiling only for testing on dev)
    const paymentIntervalValue = isProd ? position.term / 86400 : Math.ceil(position.term / 86400);
    const repetition = `RRULE:FREQ%3DDAILY;INTERVAL%3D${paymentIntervalValue};COUNT%3D${totalNumOfPaymentsLeft}`;
    googleLink += `&recur=${repetition}`;
  }
  googleLink += `&text=${subject}`;
  googleLink += `&details=${formattedDescription}`;

  window.open(googleLink);
};

export const getGoogleCalendarLinkForP2P = (position: IPeerPlan) => {
  const UTCFormat = "YYYYMMDDTHHmmss";

  const nextPaymentDate = dayjs(position.nextPaymentDate);

  const minutesBefore = isProd ? 15 : 5;
  const startDate = nextPaymentDate.subtract(minutesBefore, "minutes").format(UTCFormat);
  const endDate = nextPaymentDate.format(UTCFormat);

  const subject = `Payment Reminder: Cyan Peer Plan`;
  const description = getGoogleCalendarDescription(position, "p2p");
  const formattedDescription = renderToString(description);

  let googleLink = "https://calendar.google.com/calendar/u/0/r/eventedit?";
  googleLink += `dates=${startDate}/${endDate}`;
  googleLink += `&text=${subject}`;
  googleLink += `&details=${formattedDescription}`;
  window.open(googleLink);
};

export const isApeCoinStakingPossible = (chainId?: number) => {
  return chainId === SupportedChainId.MAINNET || chainId === SupportedChainId.SEPOLIA;
};

export const jumpToLink = (link: string) => {
  window.open(link, "_blank");
};

export const getCryptoSymbolForChain = (chainId: number) => {
  return SupportedChainId.POLYGON === chainId ? "POL" : "ETH";
};

const findRelativeRarityRank = (totalToken: number, rank: number) => {
  if (totalToken / 10 >= rank) {
    return "Top 10%";
  }
  if (totalToken / 5 >= rank) {
    return "Top 20%";
  }
  if (totalToken / 2 >= rank) {
    return "Top 50%";
  }
  return "Bottom 50%";
};

export const getRarityRank = (
  rank: number | null,
  collectionAddress: string,
  collections: ICollectionBe[],
  showOnNft = true,
) => {
  if (!rank) return null;
  const collection = collections.find(({ address }) => address === collectionAddress);
  if (!collection) return null;
  const totalToken = Number(collection.tokenCount);
  return {
    rank,
    relativeRank: findRelativeRarityRank(totalToken, rank),
    showOnNft,
    total: totalToken,
  };
};

export const getRarityRankV3 = (rank: number | null, totalIssued: number) => {
  if (!rank) return null;
  return {
    rank,
    relativeRank: findRelativeRarityRank(totalIssued, rank),
    total: totalIssued,
  };
};

export const getSampleCollections = (chainId?: number) => {
  switch (chainId) {
    case SupportedChainId.SEPOLIA:
      return [
        "0x300b105942d6d181cdfe8199fd48eb09d26efd24", // BAYC
        "0xfede5e85f747177770819f25b09edf81f2ba4493", // MAYC
        "0x7f5fb797533204872a0fd58d53edfae233b3d74e", // BAKC
      ];
    case SupportedChainId.MAINNET:
      return [
        "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d", // bayc
        "0x8a90cab2b38dba80c64b7734e58ee1db38b8992e", // doodles
        "0xed5af388653567af2f388e6224dc7c4b3241c544", // azuki
        "0x1a92f7381b9f03921564a437210bb9396471050c", // cool cats
      ];
    case SupportedChainId.POLYGON:
      return [
        "0xfbe3ab0cbfbd17d06bdd73aa3f55aaf038720f59", // voxies
        "0xdb46d1dc155634fbc732f92e853b10b288ad5a1d", // lens protocol
        "0x67f4732266c7300cca593c814d46bee72e40659f", // zed runs
        "0x24a11e702cd90f034ea44faf1e180c0c654ac5d9", // trump
      ];
    case SupportedChainId.BLAST:
      return [
        "0x51edb3e5bce8618b77b60215f84ad3db14709051", // SushiSwap
      ];
    case SupportedChainId.BLAST_SEPOLIA:
      return ["0x11BE97b08CB2fF7fD8F753a73bbb011faEC6eE6E"]; //Cyan animals
    case SupportedChainId.CURTIS:
      return ["0xb852A37c4141B4B248cD369601C01D6F43Ca47A1"]; //Cyan animals
    case SupportedChainId.APECHAIN:
      // TODO: update apechain
      return [];
    default:
      return [];
  }
};

export const divideArrayByN = <T>(items: Array<T> | IterableIterator<T>, count: number): Array<Array<T>> => {
  const arr = [...items];
  const result = [];

  while (arr.length) {
    result.push(arr.splice(0, count));
  }
  return result;
};

export const formatCompactNumber = (number: number, fix?: number) => {
  if (number < 1000) {
    return number.toFixed(fix ?? 0);
  } else if (number >= 1000 && number < 1_000_000) {
    return (number / 1000).toFixed(1) + "K";
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(1) + "M";
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (number / 1_000_000_000).toFixed(1) + "B";
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(1) + "T";
  }
  return "-";
};

export const getPreferredChainIdByDapp = (currentChainId: SupportedChainId) => {
  if (isProd) {
    if (currentChainId === SupportedChainId.POLYGON) {
      return SupportedChainId.POLYGON;
    }
    if (currentChainId === SupportedChainId.BLAST) {
      return SupportedChainId.BLAST;
    }
    if (currentChainId === SupportedChainId.APECHAIN) {
      return SupportedChainId.APECHAIN;
    }
    return SupportedChainId.MAINNET;
  } else {
    if (currentChainId === SupportedChainId.BLAST_SEPOLIA) {
      return SupportedChainId.BLAST_SEPOLIA;
    }
    if (currentChainId === SupportedChainId.CURTIS) {
      return SupportedChainId.CURTIS;
    }
    return SupportedChainId.SEPOLIA;
  }
};

export const isPromiseFulfilled = <T>(response: PromiseSettledResult<T>): response is PromiseFulfilledResult<T> => {
  return response.status === "fulfilled";
};

export const roundDown = (num: number, decimal: number) => {
  return Math.floor(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
};

export const getCurrencyAddressByAbi = async (vault: IVault, provider: Provider) => {
  switch (vault.abiName) {
    case VaultContractAbiNames.CyanVaultV2:
    case VaultContractAbiNames.CyanVaultV2_1: {
      const vaultContractWriter = f.CyanVaultV2Factory.connect(vault.contractAddress, provider);
      const currencyAddress = await vaultContractWriter.getCurrencyAddress();
      return currencyAddress;
    }
    case VaultContractAbiNames.ApeCoinVaultV1: {
      return apeCoinContract;
    }
    default:
      throw new Error(`Invalid vault abi ${vault.abiName}`);
  }
};

export const TOKEN_TYPE_MAPPED: { [key: number]: string } = {
  [INftType.ERC721]: "ERC-721",
  [INftType.CryptoPunks]: "CryptoPunks",
  [INftType.ERC1155]: "ERC-1155",
};

export const getDurationLabel = (duration: number) => {
  if (duration >= MONTH_SECONDS) {
    const months = duration / MONTH_SECONDS;
    return `${months} ${isMobile ? "mth" : "month"}${months > 1 ? "s" : ""}`;
  }

  if (duration >= DAY_SECONDS) {
    const days = duration / DAY_SECONDS;
    return `${days} day${days > 1 ? "s" : ""}`;
  }

  if (duration >= HOUR_SECONDS) {
    const hours = duration / HOUR_SECONDS;
    return `${hours} hr${hours > 1 ? "s" : ""}`;
  }

  if (duration >= MINUTE_SECONDS) {
    const minutes = duration / MINUTE_SECONDS;
    return `${minutes} min${minutes > 1 ? "s" : ""}`;
  }
  return `${duration}  ${isMobile ? "sec" : "second"}${duration > 1 ? "s" : ""}`;
};

export const IsInTestDrive =
  window.location.hostname.includes("testdrive-testnet.usecyan.com") ||
  window.location.hostname.includes("demo.usecyan.com");

// use this provider when Web3Provider is not available to read contract data /when wallet is not connected/
export const getJsonRpcProvider = (chainId: number) => {
  const network = providers.getNetwork(chainId);

  switch (chainId) {
    case SupportedChainId.MAINNET:
      return new providers.StaticJsonRpcProvider(getEnvOrThrow("ALCHEMY_MAINNET"), network);

    case SupportedChainId.SEPOLIA:
      return new providers.JsonRpcProvider(getEnvOrThrow("ALCHEMY_SEPOLIA"), network);

    case SupportedChainId.POLYGON:
      return new providers.StaticJsonRpcProvider(getEnvOrThrow("ALCHEMY_POLYGON_MAINNET"), network);

    case SupportedChainId.BLAST:
      return new providers.StaticJsonRpcProvider(getEnvOrThrow("RPC_BLAST"), network);

    case SupportedChainId.BLAST_SEPOLIA:
      return new providers.StaticJsonRpcProvider(getEnvOrThrow("RPC_BLAST_SEPOLIA"), network);

    case SupportedChainId.APECHAIN:
      return new providers.StaticJsonRpcProvider(getEnvOrThrow("RPC_APECHAIN"), network);

    case SupportedChainId.CURTIS:
      return new providers.StaticJsonRpcProvider(getEnvOrThrow("RPC_CURTIS"), network);
  }
};

export const convertPriceToGivenCurrency = (args: {
  source: {
    decimal: number;
    usdPrice: number;
  };
  target: {
    decimal: number;
    usdPrice: number;
  };
  convertAmount: BigNumberish;
}) => {
  const { target, source, convertAmount } = args;
  const priceInUsd = bigNumToFloat(convertAmount, target.decimal) * target.usdPrice;
  const priceInEth = (priceInUsd / source.usdPrice).toFixed(source.decimal);
  return parseUnits(priceInEth, source.decimal);
};

export const getChainExplorerTextForTxn = (chainId: number) => {
  return `View on ${CHAIN_IDS_TO_EXPLORER_NAME[chainId] ?? "Etherscan"}`;
};

export const getFormattedTimeForUserHistory = (date: dayjs.Dayjs, showLocalTime: boolean) => {
  return `${date.format("hh")}:${date.format("mm")} ${date.format("A")} (${showLocalTime ? "Local" : "UTC"})`;
};

export const groupArrayByKey = <T extends Record<string, any>>(
  array: Array<T>,
  key: keyof T,
): { [key: string]: T[] } => {
  const groupedObject = array.reduce<{
    [key: string]: T[];
  }>((acc, cur) => {
    const item = acc[cur[key] as string];
    return {
      ...acc,
      [cur[key] as string]: item ? [...item, cur] : [cur],
    };
  }, {});
  return groupedObject;
};

export const deleteFromArray = <T>(array: T[], keys: string[]) => {
  return array.map(item => omit(item as object, keys)) as Required<T>[];
};

export const isV1Plan = (plan: IPlanBe) => {
  return plan.paymentPlan.abiName === "PaymentPlanV1";
};
