import { Children, useCallback, useRef, useState } from "react";
import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

type IScollerProps = {
  loader?: React.ReactElement<any, any>;
  emptyChildren?: React.ReactElement<any, any>;
  hasMore: boolean;
  loadMore?: () => Promise<void>;
  isGrid: boolean;
};
export const InfiniteScroller: React.FC<IScollerProps> = ({
  loadMore,
  loader,
  hasMore,
  children,
  isGrid,
  emptyChildren,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const observer = useRef<IntersectionObserver | null>(null);

  const handleLoadMore = async () => {
    setIsLoading(true);
    try {
      if (loadMore) await loadMore();
    } catch (error) {
      console.error("Error in loadMore:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const containerRef = useCallback(
    node => {
      if (isLoading || !hasMore) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        entries => {
          if (entries[entries.length - 1].isIntersecting && !isLoading) {
            handleLoadMore();
          }
        },
        {
          threshold: 1,
        },
      );

      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore],
  );

  if (children && Children.count(children) === 0) {
    return emptyChildren ?? null;
  }

  return (
    <InfiniteScrollerDiv $showByGrid={isGrid}>
      {children}
      {loader && isLoading && loader}
      <div
        ref={containerRef}
        style={{
          height: "1px",
          visibility: "hidden",
          opacity: 0,
          pointerEvents: "none",
        }}
      />
    </InfiniteScrollerDiv>
  );
};

const InfiniteScrollerDiv = styled.div<{ $showByGrid: boolean }>`
  ${({ $showByGrid }) => {
    if ($showByGrid) {
      return ` display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  width: 100%;
  ${getStyleWithMediaQuery("grid-column-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-row-gap", "", [{ [breakpoints.mobile]: "10px" }])};
  ${getStyleWithMediaQuery("grid-template-columns", "", [
    { [breakpoints.tablet]: "repeat(auto-fill, minmax(180px, 1fr))" },
    { [breakpoints.mobile]: "repeat(auto-fill, minmax(140px, 1fr))" },
  ])}; `;
    } else {
      return ` display: flex;
      flex-direction: column;
      width: 100%;
   @media only screen and (max-width: 414px) {
     border: none;
     border-radius: 20px;
     padding: 0;
   }
   > * {
     &:last-child {
       border-bottom-left-radius: 20px;
       border-bottom-right-radius: 20px;
       @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
     }
   }`;
    }
  }}
`;
