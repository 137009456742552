import { createContext, useContext, useEffect, useState } from "react";

import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { currencyList } from "@/apis/coinbase";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { SupportedChainId } from "@/constants/chains";

export enum OnSaleNftsView {
  list = "list",
  grid = "grid",
}

type IOnSaleNftsViewType = keyof typeof OnSaleNftsView;
interface IBnplPageContext {
  selectedChain: SupportedChainId;
  selectedOnSaleNftsView: IOnSaleNftsViewType;
  selectedTokenId: string;
  selectedCollectionSearch: string;
  selectedPriceCurrency?: SupportedCurrenciesType;
  selectedCurrency: currencyList;
  setSelectedCurrency: (currency: currencyList) => void;
  showFilterAttributes: boolean;
  setShowFilterAttributes: (show: boolean) => void;
  setSelectedTokenId: (tokenId: string) => void;
  setSelectedChain: (chainId: SupportedChainId) => void;
  setSelectedOnSaleNftsView: (view: IOnSaleNftsViewType) => void;
  setSelectedCollectionSearch: (search: string) => void;
  setSelectedPriceCurrency: (priceCurrency: SupportedCurrenciesType) => void;
}

const BnplPageContext = createContext<IBnplPageContext>({
  selectedChain: SupportedChainId.MAINNET,
  selectedTokenId: "",
  selectedOnSaleNftsView: "grid",
  selectedCollectionSearch: "",
  showFilterAttributes: false,
  selectedCurrency: currencyList.eth,
  setSelectedCurrency: () => {},
  setShowFilterAttributes: () => {},
  setSelectedCollectionSearch: () => {},
  setSelectedChain: () => {},
  setSelectedTokenId: () => {},
  setSelectedOnSaleNftsView: () => {},
  setSelectedPriceCurrency: () => {},
});

export const BnplPageProvider = ({ children }: { children: React.ReactNode }) => {
  const { chainId } = useWeb3React();
  const [selectedChain, setSelectedChain] = useState<SupportedChainId>(chainId);
  const [selectedOnSaleNftsView, setSelectedOnSaleNftsView] = useState<IOnSaleNftsViewType>("grid");
  const [selectedTokenId, setSelectedTokenId] = useState<string>("");
  const [selectedCollectionSearch, setSelectedCollectionSearch] = useState<string>("");
  const [showFilterAttributes, setShowFilterAttributes] = useState<boolean>(false);
  const [selectedCurrency, setSelectedCurrency] = useState<currencyList>(currencyList.eth);
  const [selectedPriceCurrency, setSelectedPriceCurrency] = useState<SupportedCurrenciesType | undefined>(undefined);

  useEffect(() => {
    let ignorePreviousState = false;
    if (!ignorePreviousState) {
      setSelectedChain(chainId);
    }
    return () => {
      ignorePreviousState = true;
    };
  }, [chainId]);

  useEffect(() => {
    let ignorePreviousState = false;
    if (!ignorePreviousState) {
      switch (selectedChain) {
        case SupportedChainId.POLYGON:
          setSelectedCurrency(currencyList.weth);
          break;
        case SupportedChainId.APECHAIN:
          setSelectedCurrency(currencyList.ape);
          break;
        default:
          setSelectedCurrency(currencyList.eth);
          break;
      }
    }
    return () => {
      ignorePreviousState = true;
    };
  }, [selectedChain]);

  return (
    <BnplPageContext.Provider
      value={{
        selectedOnSaleNftsView,
        selectedChain,
        selectedTokenId,
        selectedCollectionSearch,
        showFilterAttributes,
        selectedCurrency,
        selectedPriceCurrency,
        setSelectedCurrency,
        setShowFilterAttributes,
        setSelectedCollectionSearch,
        setSelectedTokenId,
        setSelectedChain,
        setSelectedOnSaleNftsView,
        setSelectedPriceCurrency,
      }}
    >
      {children}
    </BnplPageContext.Provider>
  );
};

export const useBnplPageContext = () => {
  return useContext(BnplPageContext);
};

export const useBnplSelectors = () => {
  const {
    selectedChain,
    selectedTokenId,
    selectedCollectionSearch,
    showFilterAttributes,
    selectedCurrency,
    selectedPriceCurrency,
    setSelectedCurrency,
    setShowFilterAttributes,
    setSelectedCollectionSearch,
    setSelectedTokenId,
    setSelectedChain,
    setSelectedPriceCurrency,
  } = useBnplPageContext();
  return {
    selectedChain,
    selectedTokenId,
    selectedCollectionSearch,
    showFilterAttributes,
    selectedCurrency,
    selectedPriceCurrency,
    setSelectedCurrency,
    setShowFilterAttributes,
    setSelectedCollectionSearch,
    setSelectedTokenId,
    setSelectedChain,
    setSelectedPriceCurrency,
  };
};

export const useBnplViewType = () => {
  const { selectedOnSaleNftsView, setSelectedOnSaleNftsView } = useBnplPageContext();
  return {
    selectedOnSaleNftsView,
    setSelectedOnSaleNftsView,
  };
};
