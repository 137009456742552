import { useWeb3React } from "@web3-react/core";
import { useMemo, useRef } from "react";
import { ChevronDown } from "react-feather";
import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Text } from "@cyanco/components/theme/v3";

import { isProd } from "@/config";
import { CHAIN_INFO, CYAN_SUPPORTED_CHAIN_IDS, SupportedChainId } from "@/constants/chains";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { useModalOpen, useToggleModal } from "@/state/application/hooks";
import { ApplicationModal } from "@/state/application/reducer";

import { Logo, NetworkMenuItem } from "./NetworkMenuItem";

const FlyoutHeader = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 400;
`;

const FlyoutMenu = styled.div`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.gray30};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: auto;
  padding: 0.3rem;
  position: absolute;
  top: 2.5rem;
  right: 10px;
  ${getStyleWithMediaQuery("left", "", [{ [breakpoints.mobile]: "0" }])}
  width: 180px;
  z-index: 99;
  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  @media screen and (max-width: 1600px) {
    width: 216px;
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    width: 185px;
  }
`;

export const FlyoutRow = styled.div`
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  text-align: left;
  padding: 0.2rem 0.5rem;
  width: calc(100% - 1rem);
  transition: 0.2s;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => (theme.theme === "light" ? theme.colors.gray10 : theme.colors.gray20)};
    font-weight: 400;
    border-radius: 5px;
  }
`;

const StyledChevronDown = styled(ChevronDown)<{ $isOpen: boolean }>`
  color: ${({ theme, $isOpen }) => ($isOpen ? theme.colors.secondary : theme.colors.gray0)};
  margin-left: 0.2rem;
  transition: 0.2s ease-in-out;
`;

const SelectorWrapper = styled.div`
  user-select: none;
  position: relative;
  height: 30px;
`;

const SelectorControls = styled.div<{ $isOpen: boolean }>`
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  align-items: center;
  background: ${({ theme, $isOpen }) => ($isOpen ? theme.colors.gray10 : theme.colors.primary)};
  border: solid 1px;
  border-color: ${({ theme, $isOpen }) => ($isOpen ? theme.colors.gray30 : theme.colors.gray20)};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  display: flex;
  height: 28px;
  justify-content: space-between;
  padding: 0 0.7rem;
  margin-right: 10px;
  transition: 0.2s;
  :hover {
    border: 1px solid ${({ theme }) => theme.colors.gray30};
    background-color: ${({ theme }) => theme.colors.gray10};
  }
  :hover ${StyledChevronDown} {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const SelectorLogo = styled(Logo)`
  margin-right: 0;
`;

const WrongNetworkWrapper = styled.div`
  background: red;
  border-radius: 10px;
  height: 28px;
  cursor: pointer;
  margin-right: 0.5rem;
  white-space: nowrap;
  align-items: center;
  display: flex;
  padding: 0 0.5rem;
  border: 1px solid red;
  transition: 0.2s ease-in;
`;

const NetworkSelector = ({ borderRadius = "10px" }: { borderRadius?: string }) => {
  const node = useRef<HTMLDivElement>(null);
  const open = useModalOpen(ApplicationModal.NETWORK_SELECTOR);
  const toggle = useToggleModal(ApplicationModal.NETWORK_SELECTOR);
  useOnClickOutside(node, open ? toggle : undefined);

  const { chainId, connector } = useWeb3React();
  const supportedChainIDs = useMemo(() => {
    if (isProd) {
      return CYAN_SUPPORTED_CHAIN_IDS.filter(
        _chainId =>
          _chainId !== SupportedChainId.SEPOLIA &&
          _chainId !== SupportedChainId.BLAST_SEPOLIA &&
          _chainId !== SupportedChainId.CURTIS,
      );
    }
    return CYAN_SUPPORTED_CHAIN_IDS;
  }, [isProd]);

  const info = chainId
    ? CHAIN_INFO[chainId as typeof CYAN_SUPPORTED_CHAIN_IDS[number]]
    : CHAIN_INFO[SupportedChainId.MAINNET];

  if (!chainId || !connector) {
    return null;
  }
  return (
    <SelectorWrapper ref={node}>
      {supportedChainIDs.includes(chainId) ? (
        <SelectorControls
          onClick={toggle}
          $isOpen={open}
          style={{
            borderRadius,
          }}
        >
          <SelectorLogo src={info?.logoUrl} alt="chainlogo" />
          <StyledChevronDown size={16} $isOpen={open} />
        </SelectorControls>
      ) : (
        <WrongNetworkWrapper
          onClick={toggle}
          role="button"
          style={{
            borderRadius,
          }}
        >
          <Text weight="600" size="sm" textWrap={false}>
            {`Wrong Network`}
          </Text>
        </WrongNetworkWrapper>
      )}
      {open && (
        <FlyoutMenu>
          <>
            {!(chainId in SupportedChainId) && (
              <FlyoutHeader>
                <Text weight="500" size="md" color="secondary">
                  {`Please switch to:`}
                </Text>
              </FlyoutHeader>
            )}
            {supportedChainIDs.map(_chainId => (
              <NetworkMenuItem targetChain={_chainId} isActive={chainId === _chainId} onClick={toggle} key={_chainId} />
            ))}
          </>
        </FlyoutMenu>
      )}
    </SelectorWrapper>
  );
};

export default NetworkSelector;
