import { useMemo } from "react";
import styled from "styled-components";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Carousel } from "@cyanco/components/theme/v3";

import { useAppContext } from "@/components/AppContextProvider";
import { Experiments } from "@/utils/experimentList";

import { useFilteredVaultsNew } from "../../VaultDataProvider";
import { VaultCard, VaultCardLoading } from "./VaultCard";

export const VaultCards = () => {
  const { vaults, fetchingVaults } = useFilteredVaultsNew();
  const { experiment } = useAppContext();
  const isAutoLiquidationVaultEnabled = useMemo(() => {
    return experiment.result && experiment.result[Experiments.AUTO_LIQUIDATION_VAULT];
  }, [experiment]);

  return (
    <Container>
      {fetchingVaults ? (
        <Carousel>
          {Array.from(Array(3).keys()).map(loader => (
            <VaultCardLoading key={loader} />
          ))}
        </Carousel>
      ) : (
        <Carousel autoScroll>
          {vaults
            .filter(vault => (!isAutoLiquidationVaultEnabled ? !vault.isAutoLiquidated : true))
            .filter(vault => vault.history && vault.history.length !== 0)
            .map(vault => (
              <VaultCard vault={vault} key={vault.id} />
            ))}
        </Carousel>
      )}
    </Container>
  );
};
const Container = styled.div`
  width: 480px;
  ${getStyleWithMediaQuery("width", "px", [
    { [breakpoints.desktop]: 480 },
    { [breakpoints.laptop]: 460 },
    { [breakpoints.mobile]: 370 },
  ])}
`;
