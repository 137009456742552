import { Clock, DollarSign } from "react-feather";

import { Flex } from "@cyanco/components/theme/components";
import { SystemMessage, Text, useModal } from "@cyanco/components/theme/v3";

import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { PositionDefaulted } from "@/components/PlanPayment/PlanStatuses";
import { RevivalModal } from "@/components/PlanPayment/Revival/RevivalModal";
import { RevivalWithRefinance } from "@/components/PlanPayment/Revival/ReviveAndRefinance";
import { IPlanType, PlanTypes } from "@/constants/plans";

import { IPawn, isPawnPlan } from "../../../pawn.types";
import { UserNftMetadata } from "./UserNftMetadata";
import { FeatureButton, FeatureContainer, StyledConfirmButton } from "./UserPositionDetails";

export const UserDefaultedPositionDetails = ({ plan, planType }: { plan: IPawn | IBNPL; planType: IPlanType }) => {
  const { unsetModal, setModalContent } = useModal();
  const appraisalValue = isPawnPlan(plan) ? plan.appraisalValue : plan.price;
  const open = () => {
    setModalContent({
      title: "Loan Revival",
      content: <RevivalWithRefinance plan={plan} planType={planType} />,
    });
  };
  const openRevive = () => {
    const isPawn = planType === PlanTypes.Pawn;
    setModalContent({
      title: "Loan Revival",
      content: (
        <RevivalModal
          pawn={isPawn ? (plan as IPawn) : undefined}
          bnpl={!isPawn ? (plan as IBNPL) : undefined}
          planType={isPawn ? "pawn" : "bnpl"}
        />
      ),
    });
  };
  return (
    <Flex direction="column" gap="0.5rem">
      <UserNftMetadata
        nft={{
          collectionName: plan.metadata.collection.name,
          address: plan.metadata.collectionAddress,
          tokenId: plan.tokenId,
          appraisalValue,
          currency: plan.currency,
          imageUrl: plan.metadata.imageUrl,
          isCyanWalletAsset: plan.isCyanWallet || false,
          ownership: { tokenCount: 1 },
          tokenType: plan.metadata.tokenType,
          isDefaultedPosition: true,
        }}
      />
      {!plan.revivalInfo || !plan.revivalInfo.isRevivalPossible ? (
        <>
          <PositionDefaulted />
          <SystemMessage
            variant="warning"
            title="This plan has defaulted"
            msg="You can no longer revive or refinance this plan due to the reviving period has passed."
          />
          <StyledConfirmButton onClick={unsetModal} variant="outlined">
            Close
          </StyledConfirmButton>
        </>
      ) : (
        <FeatureContainer>
          <FeatureButton>
            <StyledConfirmButton onClick={openRevive}>
              <Flex gap="4px" justifyContent="center" alignItems="center">
                <Clock width={13} height={13} strokeWidth="3px" />
                <Text color="black" size="sm" weight="600">
                  {`Revive`}
                </Text>
              </Flex>
            </StyledConfirmButton>
          </FeatureButton>

          <FeatureButton>
            <StyledConfirmButton onClick={open}>
              <Flex gap="4px" justifyContent="center" alignItems="center">
                <DollarSign width={13} height={13} strokeWidth="3px" />
                <Text color="black" size="sm" weight="600">
                  {`Revive & Refinance`}
                </Text>
              </Flex>
            </StyledConfirmButton>
          </FeatureButton>
        </FeatureContainer>
      )}
    </Flex>
  );
};
