import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { ArrowDown } from "react-feather";
import { NumberFormatValues, NumericFormat, SourceInfo } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { Button, Card, CurrencyLogo, Text, useModal } from "@cyanco/components/theme/v3";
import { ChevronDown } from "@cyanco/components/theme/v3/icons";
import { CyanC } from "@cyanco/components/theme/v3/images";

import { IVault } from "@/apis/vault/types";
import { VaultContractAbiNames, VaultDecimalFormatMap } from "@/components/Vault/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import {
  IsInTestDrive,
  bigNumToFloat,
  displayInUSD,
  numberWithCommas,
  roundDown,
  shortenName,
  watchSmallAmount,
} from "@/utils";
import { SourceType } from "@/utils/types";

import { useAppContext } from "../../../AppContextProvider";
import ChangeNetworkModal from "../../../ChangeNetworkModal/ChangeNetworkModal";
import { useFilteredVaults } from "../../VaultDataProvider";
import { StakingModal } from "./StakingModal";
import {
  ChevronDownWrapper,
  PillWrapper,
  StakeInput,
  StyledChevronDown,
  StyledSelect,
  VaultImage,
  VaultImageWrapper,
} from "./VaultStake";
import { StyledLink } from "./common";

export const StakingCard = ({
  vault,
  toggle,
  availableUserBalance,
  availableTokenBalance,
  selectedWallet,
}: {
  vault: IVault;
  toggle: () => void;
  availableUserBalance: number;
  availableTokenBalance?: BigNumber;
  selectedWallet: string;
}) => {
  const theme = useTheme();
  const { chainId, account } = useWeb3React();
  const { usdPrice } = useAppContext();
  const { setModalContent } = useModal();
  const { vaults } = useFilteredVaults();
  const navigate = useNavigate();
  // vault currency token
  const [stakingAmount, setStakingAmount] = useState<string>("");
  // cyan vault token
  const [amountInCyanToken, setAmountInCyanToken] = useState<string>("");
  const [error, setError] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    setStakingAmount("");
    setAmountInCyanToken("");
  }, [selectedWallet]);

  const formatNumber = useMemo(() => {
    if (vault.abiName === VaultContractAbiNames.ApeCoinVaultV1) {
      return 2;
    }
    return VaultDecimalFormatMap.get(vault.decimals) || 5;
  }, [vault.decimals, vault.abiName]);
  const onCyanTokenAmountChange = (values: NumberFormatValues, source: SourceInfo) => {
    if (source.source === SourceType.props) return;
    if (values.value === "") {
      setStakingAmount("");
      setAmountInCyanToken("");
      return;
    }
    setError("");
    const amount = (parseFloat(values.value) * vault.priceUsd) / usdPrice[vault.currency];
    setStakingAmount(roundDown(amount, formatNumber).toString());
    setAmountInCyanToken(values.value);
  };
  const onStakingAmountChange = (values: NumberFormatValues, source: SourceInfo) => {
    if (source.source === SourceType.props) return;
    if (values.value === "") {
      setStakingAmount("");
      setAmountInCyanToken("");
      return;
    }
    setError("");
    const amount =
      (parseFloat(values.value) / vault.priceUsd) * usdPrice[vault.currency] * (1 - vault.transactionFee / 10000);
    setAmountInCyanToken(roundDown(amount, formatNumber).toString());
    setStakingAmount(values.value);
  };
  const stakingAmountInToken = useMemo(() => {
    const amount =
      (parseFloat(stakingAmount) / vault.priceUsd) * usdPrice[vault.currency] * (1 - vault.transactionFee / 10000);
    return Math.trunc(amount * Math.pow(10, formatNumber)) / Math.pow(10, formatNumber);
  }, [stakingAmount, vault]);
  const onSetMaxAmount = () => {
    if (availableUserBalance === 0) return;
    if (availableUserBalance <= 1 && vault.abiName.toLowerCase() === VaultContractAbiNames.ApeCoinVaultV1.toLowerCase())
      return;
    const amount =
      (parseFloat(availableUserBalance.toString()) / vault.priceUsd) *
      usdPrice[vault.currency] *
      (1 - vault.transactionFee / 10000);
    setAmountInCyanToken(roundDown(amount, formatNumber).toString());
    setStakingAmount(roundDown(availableUserBalance, formatNumber).toString());
  };
  const handleStake = () => {
    if (!account) return;
    if (vault.chainId !== chainId) {
      setModalContent({
        title: `Change Network`,
        content: <ChangeNetworkModal chainId={vault.chainId} />,
      });
      return;
    }
    setModalContent({
      title: `Confirm Stake`,
      content: (
        <StakingModal
          stakingAmount={parseFloat(stakingAmount === "" ? "0" : stakingAmount)}
          vault={vault}
          selectedWallet={selectedWallet}
          setStakingAmount={setStakingAmount}
        />
      ),
    });
  };
  const isStakingPossible = useMemo(() => {
    if (vault.abiName === VaultContractAbiNames.ApeCoinVaultV1) {
      return availableUserBalance >= parseFloat(stakingAmount) && parseFloat(stakingAmount) > 1;
    }
    return watchSmallAmount(parseFloat(stakingAmount)) && availableUserBalance >= parseFloat(stakingAmount);
  }, [vault.abiName, stakingAmount, availableUserBalance]);
  return (
    <>
      <Flex gap="0.2rem" direction="column">
        <Card mb="-0.2rem">
          <Flex direction="column" gap="2.5rem" p="1.3rem 0.8rem">
            <Flex direction="column" gap="0.3rem">
              {selectedWallet !== "" ? (
                <Text weight="500" color="gray0" size="xs">
                  {`Minimum deposit duration is ${parseInt(vault.withdrawLockTerm.toString()) / (24 * 60 * 60)} days. `}
                  <StyledLink href="https://docs.usecyan.com/docs/cyan-vaults" target="_blank">
                    {`Learn more.`}
                  </StyledLink>
                </Text>
              ) : (
                <Text weight="600" color="gray0" size="sm">
                  {`You pay`}
                </Text>
              )}
              <Flex w="100%" justifyContent="space-between" alignItems="center" gap="15px">
                <Text color="secondary" size="xxl">
                  <NumericFormat
                    placeholder="0"
                    thousandSeparator=","
                    allowLeadingZeros={false}
                    allowNegative={false}
                    onValueChange={onStakingAmountChange}
                    value={stakingAmount}
                    customInput={StakeInput}
                    decimalScale={formatNumber}
                    valueIsNumericString
                    disabled={availableUserBalance === 0}
                  />{" "}
                </Text>
                <PillWrapper gap="5px" pt="0.4rem">
                  <CurrencyLogo symbol={vault.currency} />
                  <Text color="secondary" weight="400" size="md">
                    {vault.currency}
                  </Text>
                </PillWrapper>{" "}
              </Flex>
              <Flex w="100%" justifyContent="space-between" alignItems="center">
                <Text weight="500" color="gray0" size="sm">
                  {shortenName(displayInUSD(Number(stakingAmount) * usdPrice[vault.currency]), 18, 16, 2)}
                </Text>
                <Flex alignItems="center">
                  <Text color="gray0" weight="400" size="sm" textWrap={false}>
                    {`Balance`}: {numberWithCommas(roundDown(availableUserBalance, formatNumber), formatNumber)}
                  </Text>
                  <div>
                    <Button variant="ghost" onClick={onSetMaxAmount}>
                      <Text color={theme.theme == "dark" ? "cyan" : "black"} weight="400" size="sm">
                        {`Max`}
                      </Text>
                    </Button>
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>
        <div style={{ position: "relative" }}>
          <ChevronDownWrapper onClick={() => toggle()}>
            <ArrowDown color={theme.colors.secondary} size={22} />
          </ChevronDownWrapper>
        </div>
        <Card
          style={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Flex direction="column" gap="2.5rem" p="1.3rem 0.8rem">
            <Flex direction="column" gap="0.3rem">
              <Text weight="600" color="gray0" size="sm">
                {`You receive`}
              </Text>
              <Flex w="100%" justifyContent="space-between" alignItems="center" gap="15px">
                <Text color="secondary" size="xxl">
                  <NumericFormat
                    placeholder="0"
                    thousandSeparator=","
                    allowLeadingZeros={false}
                    allowNegative={false}
                    onValueChange={onCyanTokenAmountChange}
                    value={amountInCyanToken}
                    customInput={StakeInput}
                    decimalScale={formatNumber}
                    valueIsNumericString
                    disabled={availableUserBalance === 0}
                  />{" "}
                </Text>
                <PillWrapper
                  style={{
                    paddingRight: "0.2rem",
                  }}
                >
                  <VaultImageWrapper style={{ background: vault.colorCode }}>
                    <VaultImage src={CyanC} alt={vault.name} />
                  </VaultImageWrapper>
                  <StyledSelect
                    value={vault.contractAddress}
                    onChange={e => {
                      setError("");
                      navigate(
                        `/${location.pathname.split("/")[1]}/${
                          CHAIN_IDS_TO_NAMES[
                            vaults.find(v => v.contractAddress === e.target.value)?.chainId as SupportedChainId
                          ]
                        }/${e.target.value}`,
                      );
                    }}
                  >
                    {vaults.map(v => (
                      <option value={v.contractAddress} key={v.contractAddress}>
                        {vault.name === v.name ? v.symbol : `${v.name} (${v.symbol})`}
                      </option>
                    ))}
                  </StyledSelect>
                  <StyledChevronDown>
                    <ChevronDown color={theme.colors.secondary} width="10px" />
                  </StyledChevronDown>
                </PillWrapper>
              </Flex>
              <Flex w="100%" justifyContent="space-between" alignItems="center">
                <Text weight="500" color="gray0" size="sm">
                  {shortenName(
                    displayInUSD(stakingAmount === "" ? 0.0 : stakingAmountInToken * vault.priceUsd),
                    18,
                    16,
                    2,
                  )}{" "}
                </Text>
                <Flex alignItems="center">
                  <Text color="gray0" weight="400" size="sm" textWrap={false}>
                    {`Balance`}:{" "}
                    {availableTokenBalance
                      ? numberWithCommas(
                          roundDown(bigNumToFloat(availableTokenBalance, vault.decimals), formatNumber),
                          formatNumber,
                        )
                      : 0}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>
        {vault.price && (
          <Card
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Flex p="0.8rem" justifyContent="space-between" alignItems="center">
              <Text color="secondary" size="xs">
                {`1 ${vault.currency} = ${(usdPrice[vault.currency] / vault.priceUsd).toFixed(formatNumber)} ${
                  vault.symbol
                }`}{" "}
                <Text color="gray0" size="xs">
                  ({displayInUSD(usdPrice[vault.currency])})
                </Text>
              </Text>
              <Text color="gray0" size="xxs">
                {`${(vault.transactionFee / 100).toFixed(2)}% deposit fee`}
              </Text>
            </Flex>
          </Card>
        )}
      </Flex>
      {parseFloat(stakingAmount) <= 1 && vault.abiName === VaultContractAbiNames.ApeCoinVaultV1 && (
        <Text color="red" size="xs" textAlign="right">
          {`To stake, more than one ${vault.currency} is required`}
        </Text>
      )}
      {stakingAmount !== "" && !watchSmallAmount(parseFloat(stakingAmount)) && parseFloat(stakingAmount) !== 0 && (
        <Text color="red" size="xs" textAlign="right">
          {`Too small amount to stake`}
        </Text>
      )}
      {error !== "" && (
        <Text color="red" size="xs" textAlign="right" textWrap style={{ overflowWrap: "anywhere" }}>
          {error}
        </Text>
      )}
      <StakeButton onClick={handleStake} disabled={IsInTestDrive || !isStakingPossible}>{`Stake`}</StakeButton>
    </>
  );
};

const StakeButton = styled(Button)`
  padding: 1rem;
  height: 50px;
`;
