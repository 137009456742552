import { BigNumber, ethers } from "ethers";
import orderBy from "lodash.orderby";
import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-async-hook";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { fetchUserTokens as fetchUserTokensBe } from "@/apis/user";
import { IUserToken } from "@/apis/user/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { SupportedChainId, isSupportedChain } from "@/constants/chains";
import { IsInTestDrive, getCryptoSymbolForChain } from "@/utils";

import ethereumLogoUrl from "../../assets/images/ethereum-logo.png";
import polygonLogoUrl from "../../assets/images/polygon-logo.svg";
import { WalletTypes, useWalletTabContext } from "../Account/AccountPageContext";
import { useAppContext } from "../AppContextProvider";
import { useVaults } from "../Vault/VaultDataProvider";

interface ITokenContext {
  tokens: Array<IUserToken & { tokenInUsd?: number }>;
  fetchUserTokens: (a: string) => void;
  refreshUserTokens: () => Promise<void>;
}
const TokenContext = createContext<ITokenContext>({
  tokens: [],
  fetchUserTokens: () => {},
  refreshUserTokens: async () => {},
});

export const TokenContextProvider: FC = ({ children }) => {
  const { usdPrice } = useAppContext();
  const cyanWallet = useCyanWallet();
  const { chainId, account, provider } = useWeb3React();
  const [tokens, setTokens] = useState<Array<IUserToken & { tokenInUsd?: number }>>([]);
  const { vaults, fetchingVaults } = useVaults();
  const { experiment } = useAppContext();
  const fetchUserTokens = async (wallet: string, isCyanWallet?: boolean) => {
    const tokensBe = await fetchUserTokensBe({ chainId, wallet });
    return tokensBe.map(token => ({ ...token, isCyanWallet: isCyanWallet ?? false }));
  };

  const fetchNativeCurrencyFromWallet = async (account: string, chainId: SupportedChainId, isCyanWallet: boolean) => {
    let balance = BigNumber.from(0);
    if (provider) {
      balance = await provider.getBalance(account);
    }
    const walletData: IUserToken = {
      name: chainId === SupportedChainId.POLYGON ? "POL" : "Ethereum",
      symbol: getCryptoSymbolForChain(chainId),
      decimal: 18,
      imageUrl: chainId === SupportedChainId.POLYGON ? polygonLogoUrl : ethereumLogoUrl,
      address: ethers.constants.AddressZero,
      tokenBalance: balance,
      isCyanWallet,
    };
    return walletData;
  };

  const {
    result: cyanWalletTokens = [],
    loading: isCyanWalletTokensLoading,
    execute: fetchCyanWalletTokens,
  } = useAsync<Array<IUserToken>>(async () => {
    if (!cyanWallet || !experiment.result || !isSupportedChain(chainId)) return [];
    return await fetchUserTokens(cyanWallet.walletAddress, true);
  }, [cyanWallet, chainId, experiment]);

  const {
    result: mainWalletTokens = [],
    loading: isMainWalletTokensLoading,
    execute: fetchMainWalletTokens,
  } = useAsync<Array<IUserToken>>(async () => {
    if (!account || !experiment.result || !isSupportedChain(chainId)) return [];
    return await fetchUserTokens(account);
  }, [account, IsInTestDrive, chainId, experiment]);

  const applyUsdPricesToTokens = useCallback(
    (tokens: Array<IUserToken>) => {
      if (tokens.length) {
        const tokensWithPrice = tokens.map(token => {
          const tokenInUsd = usdPrice[token.symbol] || 0;
          const vaultToken = vaults.find(v => v.symbol === token.symbol);
          if (vaultToken) {
            return {
              ...token,
              tokenInUsd: vaultToken.priceUsd,
            };
          } else {
            return {
              ...token,
              tokenInUsd,
            };
          }
        });
        setTokens(tokensWithPrice);
      }
    },
    [vaults, usdPrice],
  );

  const { result: mainWalletNativeTokenBalance, execute: fetchMainWalletNativeTokenBalance } = useAsync(async () => {
    if (!account || !isSupportedChain(chainId)) return;
    return fetchNativeCurrencyFromWallet(account, chainId, false);
  }, [account, chainId]);

  const { result: cyanWalletNativeTokenBalance, execute: fetchCyanWalletNativeTokenBalance } = useAsync(async () => {
    if (!cyanWallet || !isSupportedChain(chainId)) return;
    return fetchNativeCurrencyFromWallet(cyanWallet.walletAddress, chainId, true);
  }, [cyanWallet, chainId]);

  const refreshUserTokens = async () => {
    await Promise.all([
      fetchMainWalletTokens(),
      fetchMainWalletNativeTokenBalance(),
      fetchCyanWalletTokens(),
      fetchCyanWalletNativeTokenBalance(),
    ]);
  };

  useEffect(() => {
    if (
      !isCyanWalletTokensLoading &&
      !isMainWalletTokensLoading &&
      !fetchingVaults &&
      vaults.every(v => v.currency in usdPrice)
    ) {
      applyUsdPricesToTokens([...mainWalletTokens, ...cyanWalletTokens]);
    }
  }, [fetchingVaults, isCyanWalletTokensLoading, isMainWalletTokensLoading, mainWalletTokens, cyanWalletTokens]);

  const userTokensFinal = useMemo(() => {
    const final = [...tokens];
    if (mainWalletNativeTokenBalance) final.push(mainWalletNativeTokenBalance);
    if (cyanWalletNativeTokenBalance) final.push(cyanWalletNativeTokenBalance);
    return final;
  }, [tokens, mainWalletNativeTokenBalance, cyanWalletNativeTokenBalance]);

  return (
    <TokenContext.Provider
      value={{
        tokens: userTokensFinal,
        fetchUserTokens,
        refreshUserTokens,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export const useUserTokenContext = () => {
  return useContext(TokenContext);
};

export const useUserTokens = () => {
  const { tokens, fetchUserTokens, refreshUserTokens } = useContext(TokenContext);
  const { selectedTokenSearch, selectedWalletType } = useWalletTabContext();
  const userTokensFiltered = useMemo(() => {
    if (!tokens) return [];
    if (selectedTokenSearch !== "") {
      return tokens.filter(
        token =>
          (token.symbol.toLowerCase().startsWith(selectedTokenSearch.toLowerCase()) ||
            token.name.toLowerCase().startsWith(selectedTokenSearch.toLowerCase()) ||
            token.address.toLowerCase().startsWith(selectedTokenSearch.toLowerCase())) &&
          ((selectedWalletType === WalletTypes.mainWallet && !token.isCyanWallet) ||
            (selectedWalletType === WalletTypes.cyanWallet && token.isCyanWallet) ||
            selectedWalletType === WalletTypes.allWallets),
      );
    } else {
      return tokens.filter(
        token =>
          (selectedWalletType === WalletTypes.mainWallet && !token.isCyanWallet) ||
          (selectedWalletType === WalletTypes.cyanWallet && token.isCyanWallet) ||
          selectedWalletType === WalletTypes.allWallets,
      );
    }
  }, [tokens, selectedTokenSearch, selectedWalletType]);

  return {
    tokens: [
      ...userTokensFiltered.filter(token => token.symbol === "ETH"),
      ...orderBy(
        userTokensFiltered.filter(token => token.symbol !== "ETH"),
        "name",
        "asc",
      ),
    ],
    userTokensFiltered,
    fetchUserTokens,
    refreshUserTokens,
  };
};
