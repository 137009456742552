import { JsonRpcSigner } from "@ethersproject/providers";

import { CyanWallet } from "@usecyan/cyan-wallet";

import { useModal } from "@cyanco/components/theme/v3";

import { IResponseData } from "@/apis/pricer/pricer-ape-coin";
import { ApePlanModal } from "@/components/ApeCoinStaking/new/components/PlanModal";
import { ApeCoinStakingModal as ApeStakingModal } from "@/components/ApeCoinStaking/new/components/PlanModal/StakingModal";
import { OwnedApeBulkStaking } from "@/components/ApeCoinStaking/new/components/PlanModal/progress/BulkStaking";
import { BulkStakingWithApePlan } from "@/components/ApeCoinStaking/new/components/PlanModal/progress/BulkStakingWithPlan";
import { BulkUnstaking } from "@/components/ApeCoinStaking/new/components/PlanModal/progress/BulkUnstaking";
import { OwnedApeStaking } from "@/components/ApeCoinStaking/new/components/PlanModal/progress/Staking";
import { StakingWithApePlan } from "@/components/ApeCoinStaking/new/components/PlanModal/progress/StakingWithApePlan";
import { OwnedApeUnstaking } from "@/components/ApeCoinStaking/new/components/PlanModal/progress/Unstaking";
import { UnstakingWithApePlan } from "@/components/ApeCoinStaking/new/components/PlanModal/progress/UnstakingWithApePlan";
import { IApeBulkStakingModal, IApeStakingModal } from "@/components/ApeCoinStaking/new/types";
import { PoolId, apePaymentPlanContract } from "@/config";
import { createHashSHA256 } from "@/utils";

import {
  IInitiateApeBulkStake,
  IInitiateApeBulkUnstake,
  IInitiateBorrowedApeStake,
  IInitiateBorrowedApeUnstake,
  IInitiateOwnedApeStake,
  IInitiateOwnedApeUnstake,
  domain,
  types,
} from "./useApePlanCreation.types";

const generateNonce = async (apePlans: Awaited<IResponseData>[]): Promise<string> => {
  const apePlansString = apePlans
    .sort((a, b) => a.planId - b.planId)
    .map(data => {
      if (data.poolId === PoolId.COIN) {
        throw new Error("Ape Coin staking is not supported");
      }
      const planData: (string | number | boolean)[] = [data.planId, data.amount.toString(), data.poolId];
      planData.push(data.tokenId, data.signedBlockNumber, data.signature, data.wallet);

      if (data.poolId === PoolId.BAKC) {
        if (!data.mainPoolId || !data.mainTokenId) {
          throw new Error("Main pool and token id is required");
        }
        planData.push(data.mainPoolId, data.mainTokenId);
      }
      return planData.join("|");
    })
    .join("|");
  return await createHashSHA256(apePlansString);
};

export const signNewPlanSignature = async (
  signer: JsonRpcSigner,
  data: Array<Omit<IResponseData, "rewardStakeToCyanVault">>,
): Promise<string> => {
  if (data.some(({ poolId }) => poolId === PoolId.BAKC) && data.length > 1) {
    throw new Error("Bulk staking is only possible for MAYC and BAYC.");
  }
  const nonce = await generateNonce(data);
  domain.verifyingContract = apePaymentPlanContract;
  const value = {
    "ApeCoin Plans": data
      .sort((a, b) => a.planId - b.planId)
      .map(plan => ({
        ...plan,
        amount: plan.amount.toString(),
        blockNumber: plan.signedBlockNumber,
        ...(plan.poolId === PoolId.BAKC ? { mainPoolId: plan.mainPoolId, mainTokenId: plan.mainTokenId } : {}),
      })),
    nonce,
  };
  let apeCoinPlanType = [...types.ApeCoinPlan];
  const isApeCoinStaking = data.every(({ poolId }) => poolId === PoolId.COIN);
  if (!isApeCoinStaking && data.some(({ poolId }) => poolId === PoolId.BAKC)) {
    apeCoinPlanType.push({ name: "mainPoolId", type: "uint256" }, { name: "mainTokenId", type: "uint32" });
  }
  if (isApeCoinStaking) {
    apeCoinPlanType = apeCoinPlanType.filter(({ name }) => name !== "tokenId");
  }
  const TYPED_DATA_TYPES_CONVERTED = {
    ...types,
    ApeCoinPlan: apeCoinPlanType,
  };
  const signature = await signer._signTypedData(domain, TYPED_DATA_TYPES_CONVERTED, value);
  return signature;
};

export const createBatchTxn = (
  cyanWallet: CyanWallet,
  transactions: {
    to: string;
    data: string;
    value: string;
  }[],
) => {
  const data = cyanWallet.interface.encodeFunctionData("executeBatch", [transactions]);
  return { to: cyanWallet.walletAddress, data, value: 0 };
};

export const useApePlanCreation = () => {
  const { setModalContent } = useModal();

  const showPlanModal = (args: IApeStakingModal) => {
    setModalContent({
      title: `ApeCoin NFT Staking`,
      content: <ApeStakingModal {...args} />,
    });
  };

  const showApeBulkPlanModal = (args: IApeBulkStakingModal) => {
    setModalContent({
      title: `ApeCoin NFT Staking`,
      content: <ApePlanModal {...args} />,
    });
  };

  const initiateBorrowedApeStake = (args: IInitiateBorrowedApeStake) => {
    setModalContent({
      title: `ApeCoin NFT Staking`,
      content: <StakingWithApePlan {...args} />,
    });
  };

  const initiateOwnedApeStake = (_args: IInitiateOwnedApeStake) => {
    setModalContent({
      title: `ApeCoin NFT Staking`,
      content: <OwnedApeStaking {..._args} />,
    });
  };

  const initiateBorrowedApeUnstake = (_args: IInitiateBorrowedApeUnstake) => {
    setModalContent({
      title: `ApeCoin NFT Unstaking`,
      content: <UnstakingWithApePlan {..._args} />,
    });
  };

  const initiateOwnedApeUnstake = (_args: IInitiateOwnedApeUnstake) => {
    setModalContent({
      title: `ApeCoin NFT Unstaking`,
      content: <OwnedApeUnstaking {..._args} />,
    });
  };

  const initiateBorrowedApeBulkStake = (_args: IInitiateApeBulkStake) => {
    setModalContent({
      title: `ApeCoin NFT Bulk Staking`,
      content: <BulkStakingWithApePlan {..._args} />,
    });
  };

  const initiateOwnedBulkStake = (_args: IInitiateApeBulkStake) => {
    setModalContent({
      title: `ApeCoin NFT Staking`,
      content: <OwnedApeBulkStaking {..._args} />,
    });
  };

  const initiateBulkUnstake = (_args: IInitiateApeBulkUnstake) => {
    setModalContent({
      title: `ApeCoin NFT Unstaking`,
      content: <BulkUnstaking {..._args} />,
    });
  };

  return {
    showPlanModal,
    initiateBorrowedApeStake,
    initiateOwnedApeStake,
    initiateBorrowedApeUnstake,
    initiateOwnedApeUnstake,
    showApeBulkPlanModal,
    initiateBorrowedApeBulkStake,
    initiateOwnedBulkStake,
    initiateBulkUnstake,
  };
};
