import { BigNumber } from "ethers";
import { useMemo } from "react";
import { AlertTriangle } from "react-feather";
import styled, { useTheme } from "styled-components";

import {
  Box,
  Button,
  Card,
  Flex,
  SwitchButton,
  SwitchButtonGroup,
  SystemMessage,
  Text,
  Tooltip,
  TooltipText,
} from "@cyanco/components/theme";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";

import { DATE_TIME_FORMAT_OPTIONS_DAYJS } from "@/config";
import { SupportedChainId } from "@/constants/chains";
import { useGetUserTime } from "@/hooks";
import { IAutoRepayStatuses } from "@/types";

import { useWeb3React } from "../Web3ReactProvider";

const switch_btn_height = "22px";

export const AutoRepaymentBox: React.FC<{
  plan: {
    amount: BigNumber;
    currency: string;
    dueDate: Date;
  };
  isProcessing: boolean;
  onPay: () => Promise<void>;
}> = ({ plan, isProcessing, onPay }) => {
  const { getUserPreferredTime } = useGetUserTime();

  return (
    <Card p={"15px"} hover>
      <Flex justifyContent="space-between" w="100%">
        <Flex direction="column" gap="4px" w="100%">
          <Text size="sm" weight="500" color="gray0">
            {`Next auto-repayment:`}
          </Text>
          <Text size="lg" weight="500" color="secondary">
            {getUserPreferredTime(plan.dueDate).format(DATE_TIME_FORMAT_OPTIONS_DAYJS)}
          </Text>
        </Flex>
        <Box w="120px">
          <PayButton loading={isProcessing} disabled={isProcessing} onClick={onPay}>{`Pay Now`}</PayButton>
        </Box>
      </Flex>
    </Card>
  );
};

const PayButton = styled(Button)`
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
`;

export const AutoRepaymentWarning = ({ autoRepayStatus }: { autoRepayStatus: IAutoRepayStatuses }) => {
  const { chainId } = useWeb3React();
  const currencySymbol = useMemo(() => {
    switch (chainId) {
      case SupportedChainId.POLYGON: {
        return autoRepayStatus == IAutoRepayStatuses.FromMainWallet ? "WPOL " : "POL ";
      }
      case SupportedChainId.APECHAIN: {
        return autoRepayStatus == IAutoRepayStatuses.FromMainWallet ? "WAPE " : "APE ";
      }
      default: {
        return autoRepayStatus == IAutoRepayStatuses.FromMainWallet ? "WETH " : "ETH ";
      }
    }
  }, [chainId, autoRepayStatus]);
  return (
    <MsgBox gap="2px">
      <Flex direction="column" gap="2px">
        <Flex alignItems="center" gap="0.2rem">
          <AlertTriangle width={16} height={16} color="black" />
          <Text size="md" weight="700" color="black">
            Auto-repayment selected
          </Text>
        </Flex>
      </Flex>
      <Text size="xs" weight="500" color="black">
        {currencySymbol} payments will be automatically deducted from your{" "}
        {autoRepayStatus == IAutoRepayStatuses.FromMainWallet ? "Main " : "Cyan "}Wallet. Please fund your{" "}
        {autoRepayStatus == IAutoRepayStatuses.FromMainWallet ? "Main " : "Cyan "}
        Wallet with sufficient {currencySymbol}by the payment due date. Notices will be sent on failed attempts at
        retrieving funds automatically.
      </Text>
    </MsgBox>
  );
};

export const AutoRepaymentWarningNonNative = ({
  currencySymbol,
  isNativeCurrency,
}: {
  currencySymbol: string;
  isNativeCurrency: boolean;
}) => {
  const { chainId } = useWeb3React();
  const selectedCurrencySymbol = useMemo(() => {
    switch (chainId) {
      case SupportedChainId.APECHAIN: {
        return isNativeCurrency ? "WAPE " : currencySymbol;
      }
      case SupportedChainId.POLYGON: {
        return isNativeCurrency ? "WPOL " : currencySymbol;
      }
      default: {
        return isNativeCurrency ? "WETH " : currencySymbol;
      }
    }
  }, [chainId, isNativeCurrency]);
  return (
    <SystemMessage
      variant="warning"
      title={"Auto-repayment selected"}
      msg={`Enabling automatic payments for non-native currency plans requires obtaining currency approval.`}
      description={
        <Text size="xs" weight="500" color="black">
          {selectedCurrencySymbol} payments will be automatically deducted from your Main Wallet. Please fund your Main
          Wallet with sufficient {selectedCurrencySymbol} by the payment due date. Notices will be sent on failed
          attempts at retrieving funds automatically.
        </Text>
      }
    />
  );
};
const MsgBox = styled(Flex)`
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 1rem;
  background-color: #e49b2d;
  flex-direction: column;
`;

export const AutoRepaymentApprove: React.FC<{
  isProcessing: boolean;
  onPay: () => Promise<void>;
}> = ({ isProcessing, onPay }) => {
  return (
    <Card p={"15px"} hover>
      <Flex justifyContent="space-between" w="100%">
        <Flex direction="column" gap="4px" w="100%">
          <Text size="sm" weight="500" color="gray0">
            {`Token approval is required for activating auto-repayment.`}
          </Text>
        </Flex>
        <Box w="120px">
          <PayButton loading={isProcessing} onClick={onPay} style={{ opacity: 0.8 }}>{`Approve`}</PayButton>
        </Box>
      </Flex>
    </Card>
  );
};

export const AutoRepaymentSwitch: React.FC<{
  autoRepayStatus: IAutoRepayStatuses;
  disabled: boolean;
  onChange: (a: IAutoRepayStatuses) => void;
}> = ({ autoRepayStatus, disabled, onChange }) => {
  const theme = useTheme();
  return (
    <StyledCard>
      <Flex direction="column" gap="5px">
        <Flex alignItems="center" gap="4px">
          <Text size="sm" weight="600" color="secondary">
            {`Auto-repayments`}
          </Text>
          <Tooltip>
            <HelpCircle height={16} width={16} color={theme.colors.secondary} />
            <TooltipText showArrow position="top" top="-75px" right="-70px" style={{ width: "150px" }}>
              <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                <div>{`You can provide Cyan permission to automatically withdraw from your wallet every month.`}</div>
              </Text>
            </TooltipText>
          </Tooltip>
        </Flex>
        <SwitchButtonGroup<IAutoRepayStatuses>
          activeBackground={theme.colors.cyan}
          activeTextColor={theme.colors.black}
          borderColor={theme.colors.gray10}
          value={autoRepayStatus}
          onChange={v => onChange(v)}
          hover
        >
          <SwitchButton height={switch_btn_height} value={IAutoRepayStatuses.Disabled} disabled={disabled}>
            {"Turn off"}
          </SwitchButton>
          <SwitchButton height={switch_btn_height} value={IAutoRepayStatuses.FromMainWallet} disabled={disabled}>
            {"Main Wallet"}
          </SwitchButton>
          <SwitchButton height={switch_btn_height} value={IAutoRepayStatuses.FromCyanWallet} disabled={disabled}>
            {"Cyan Wallet"}
          </SwitchButton>
        </SwitchButtonGroup>
      </Flex>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  padding: 8px;
`;
