import * as Sentry from "@sentry/react";
import { BigNumber, ethers } from "ethers";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useAsync } from "react-async-hook";

import { factories as f } from "@cyanco/contract";

import { fetchApeStakedAmounts } from "@/apis/ape-plans";
import { useWeb3React } from "@/components/Web3ReactProvider";
import {
  CAPS_MAPPED_BY_POOL_ID,
  PoolId,
  apeCoinContract,
  apeStakingContract,
  apeVaultContract,
  apeVaultTokenContract,
} from "@/config";
import { bigNumToFloat, getJsonRpcProvider, isApeCoinStakingPossible } from "@/utils";
import { executeBatchRead, getCyanWalletAddress } from "@/utils/contract";
import { IBatchReaderData } from "@/utils/types";

import {
  IApeCoinContext,
  IApeVault,
  IPools,
  IUserBalance,
  apeVaultInitialState,
  poolInfoInitialState,
} from "./ApeCoinStatsContext.types";
import { DAYS_IN_YEAR, getPoolAndVaultData } from "./util";

const initialContext: IApeCoinContext = {
  userBalance: {
    apeBalance: BigNumber.from(0),
    vaultBalance: BigNumber.from(0),
    totalStaked: BigNumber.from(0),
    helpers: {
      tokenPrice: BigNumber.from(0),
    },
  },
  poolsWithBorrow: {
    MAYC: poolInfoInitialState,
    BAYC: poolInfoInitialState,
    BAKC: poolInfoInitialState,
  },
  poolsWithoutBorrow: {
    MAYC: poolInfoInitialState,
    BAYC: poolInfoInitialState,
    BAKC: poolInfoInitialState,
  },
  apeVault: apeVaultInitialState,
  updateUserBalance: async () => {},
};

const ApeCoinStatsContext = createContext<IApeCoinContext>(initialContext);

const _getPoolData = (
  pool: {
    borrowApy: number;
    apy: number;
    loanedAmount: BigNumber;
    rewardsPerDay: number;
    rewardsPerHour: BigNumber;
    totalStakedAmount: BigNumber;
    interestRate: number;
  },
  serviceFeeRate: number,
) => {
  return {
    borrow: {
      apy: pool.borrowApy,
      totalStakedAmount: bigNumToFloat(pool.totalStakedAmount),
      rewardsPerDay: pool.rewardsPerDay * (1 - (pool.interestRate / 100 - serviceFeeRate / 100)),
      totalStakedAmountOnCyan: bigNumToFloat(pool.loanedAmount),
    },
    withoutBorrow: {
      apy: pool.apy,
      totalStakedAmount: bigNumToFloat(pool.totalStakedAmount),
      rewardsPerDay: pool.rewardsPerDay * (1 - serviceFeeRate / 100),
      totalStakedAmountOnCyan: bigNumToFloat(pool.loanedAmount),
    },
  };
};
export const ApeCoinStatsProvider: React.FC = ({ children }) => {
  const { provider, account, chainId } = useWeb3React();

  const [userBalance, setUserBalance] = useState<IUserBalance>(initialContext.userBalance);
  const [poolsWithBorrow, setPoolsWithBorrow] = useState<IPools>(initialContext.poolsWithBorrow);
  const [poolsWithoutBorrow, setPoolsWithoutBorrow] = useState<IPools>(initialContext.poolsWithoutBorrow);
  const [apeVault, setApeVault] = useState<IApeVault>(apeVaultInitialState);

  const { result: apeStakingData } = useAsync(async () => {
    if (isApeCoinStakingPossible(chainId)) {
      const _provider = getJsonRpcProvider(chainId);
      if (_provider) {
        const data = await getPoolAndVaultData(_provider, chainId);
        return data;
      }
    }
  }, [chainId]);

  const _updateUserBalance = async () => {
    if (!chainId || !provider || !account || !isApeCoinStakingPossible(chainId) || !apeStakingData) return;

    const iVaultTokenContract = f.SampleERC20TokenFactory.createInterface();
    const iApeCoinContract = f.ApeCoinFactory.createInterface();
    const iApeStakingContract = f.ApeCoinStakingFactory.createInterface();
    const cyanWalletAddress = await getCyanWalletAddress({
      provider,
      mainWallet: account,
    });

    const batchReadData: IBatchReaderData[] = [];
    batchReadData.push(
      {
        interface: iApeCoinContract,
        contractAddress: apeCoinContract,
        functionName: "balanceOf",
        params: [account],
      },
      {
        interface: iApeStakingContract,
        contractAddress: apeStakingContract,
        functionName: "stakedTotal",
        params: [account],
      },
      {
        interface: iVaultTokenContract,
        contractAddress: apeVaultTokenContract,
        functionName: "balanceOf",
        params: [account],
      },
    );

    if (cyanWalletAddress) {
      batchReadData.push(
        {
          interface: iApeCoinContract,
          contractAddress: apeCoinContract,
          functionName: "balanceOf",
          params: [cyanWalletAddress],
        },
        {
          interface: iApeStakingContract,
          contractAddress: apeStakingContract,
          functionName: "stakedTotal",
          params: [cyanWalletAddress],
        },
        {
          interface: iVaultTokenContract,
          contractAddress: apeVaultTokenContract,
          functionName: "balanceOf",
          params: [cyanWalletAddress],
        },
      );
    }

    const batchResult = await executeBatchRead(chainId, provider, batchReadData);

    const apeBalanceMain = batchResult[0][0];
    const stakedMain = batchResult[1][0];
    const vaultBalance = batchResult[2][0];

    const apeBalanceCyan = batchResult[3]?.[0] ?? BigNumber.from(0);
    const stakedCyan = batchResult[4]?.[0] ?? BigNumber.from(0);
    const vaultBalanceCyan = batchResult[5]?.[0] ?? BigNumber.from(0);

    const { vaultTokenPrice, poolsData: _pools } = apeStakingData;
    setUserBalance({
      apeBalance: apeBalanceMain.add(apeBalanceCyan),
      vaultBalance: ethers.utils.parseEther(
        (bigNumToFloat(vaultBalance.add(vaultBalanceCyan)) * bigNumToFloat(vaultTokenPrice)).toFixed(18),
      ),
      totalStaked: stakedMain.add(stakedCyan),
      helpers: {
        tokenPrice: BigNumber.from(vaultTokenPrice),
      },
    });
  };

  const updateUserBalance = async () => {
    try {
      await _updateUserBalance();
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const _safeSetData = async () => {
    try {
      await _setData();
    } catch (e) {
      Sentry.captureException(e);
    }
  };
  const _setData = async () => {
    if (!provider || !apeStakingData) return;

    const { poolsData, serviceFeeRate: _serviceFeeRate } = apeStakingData;
    const apeVaultContractWriter = f.CyanApeCoinVaultV1Factory.connect(apeVaultContract, provider);
    const [{ stakedAmountBakc, stakedAmountBayc, stakedAmountMayc }, { loanedAmounts, remainingAmount }] =
      await Promise.all([fetchApeStakedAmounts(), apeVaultContractWriter.getCurrentAssetAmountsMapped()]);

    const serviceFeeRate = bigNumToFloat(_serviceFeeRate, 0) / 100;
    // Current borrowed and auto compounded apes
    const totalApeStakedAmount = stakedAmountBakc.add(stakedAmountBayc).add(stakedAmountMayc);
    // Total staked amount on Ape Vault
    const totalVaultStakedAmount = remainingAmount.add(totalApeStakedAmount);
    const totalApeAmount = loanedAmounts.reduce((acc, cur) => acc.add(cur), BigNumber.from(0)).add(remainingAmount);
    const vaultNetRate = Object.keys(poolsData).reduce((totalLendingApr, poolId) => {
      const pool = poolsData[Number(poolId)];
      const rewardsPerDay = bigNumToFloat(pool.rewardsPerHour.mul(24));
      const apr = ((rewardsPerDay * DAYS_IN_YEAR) / bigNumToFloat(pool.totalStakedAmount)) * 100;
      const borrowUtilizationRate =
        (bigNumToFloat(loanedAmounts[Number(poolId)]) * 100) / bigNumToFloat(totalApeAmount);
      const lendingApr = (borrowUtilizationRate * (apr * (pool.interestRate / 100))) / 100;
      return totalLendingApr + lendingApr;
    }, 0);
    const poolsWithAPY: {
      [key: string]: {
        borrowApy: number;
        apy: number;
        loanedAmount: BigNumber;
        rewardsPerDay: number;
        rewardsPerHour: BigNumber;
        totalStakedAmount: BigNumber;
        interestRate: number;
        apr: number;
      };
    } = Object.keys(poolsData).reduce((acc, _poolId) => {
      const poolId = Number(_poolId) as PoolId;
      const pool = poolsData[poolId];
      const rewardsPerDay = bigNumToFloat(pool.rewardsPerHour.mul(24));
      const apr = ((rewardsPerDay * DAYS_IN_YEAR) / bigNumToFloat(pool.totalStakedAmount)) * 100;
      const frequency = 14;
      const vaultStake = Array.from({ length: DAYS_IN_YEAR - 1 }).reduce(
        (
          acc: {
            autoCompound: number;
            borrow: number;
          },
          _cur,
          _index,
        ) => {
          acc.borrow += ((acc.borrow / 100.0) * vaultNetRate) / DAYS_IN_YEAR;
          acc.autoCompound += ((acc.autoCompound / 100.0) * vaultNetRate) / DAYS_IN_YEAR;
          if (_index % frequency === 0) {
            const reward =
              (frequency * rewardsPerDay * CAPS_MAPPED_BY_POOL_ID[poolId]) / bigNumToFloat(pool.totalStakedAmount);
            acc.autoCompound += reward * (1 - serviceFeeRate / 100);
            acc.borrow += reward * (1 - (serviceFeeRate + pool.interestRate) / 100);
          }
          return acc;
        },
        {
          autoCompound: 0,
          borrow: 0,
        },
      );
      const apy = (vaultStake.autoCompound / CAPS_MAPPED_BY_POOL_ID[poolId as PoolId]) * 100.0;
      const borrowApy = (vaultStake.borrow / CAPS_MAPPED_BY_POOL_ID[poolId as PoolId]) * 100.0;
      return {
        ...acc,
        [_poolId]: {
          ...pool,
          borrowApy,
          apy,
          loanedAmount: loanedAmounts[poolId],
          rewardsPerDay,
          apr,
        },
      };
    }, {});
    // Total staked - Horizen contract
    const totalStakedAmount = [poolsData[PoolId.BAYC], poolsData[PoolId.MAYC], poolsData[PoolId.BAKC]].reduce(
      (acc, cur) => acc.add(cur.totalStakedAmount),
      BigNumber.from(0),
    );
    setApeVault({
      totalStaked: bigNumToFloat(totalStakedAmount),
      totalStakedOnCyan: bigNumToFloat(totalVaultStakedAmount),
      cap: 0,
      apr: {
        MAYC: poolsWithAPY[PoolId.MAYC].apr,
        BAKC: poolsWithAPY[PoolId.BAKC].apr,
        BAYC: poolsWithAPY[PoolId.BAYC].apr,
      },
    });
    const maycPool = _getPoolData(poolsWithAPY[PoolId.MAYC], serviceFeeRate);
    const baycPool = _getPoolData(poolsWithAPY[PoolId.BAYC], serviceFeeRate);
    const bakcPool = _getPoolData(poolsWithAPY[PoolId.BAKC], serviceFeeRate);
    setPoolsWithBorrow({
      MAYC: maycPool.borrow,
      BAYC: baycPool.borrow,
      BAKC: bakcPool.borrow,
    });
    const totalAutoCompoundMayc = bigNumToFloat(stakedAmountMayc) - bigNumToFloat(loanedAmounts[PoolId.MAYC]);
    const totalAutoCompoundBayc = bigNumToFloat(stakedAmountBayc) - bigNumToFloat(loanedAmounts[PoolId.BAYC]);
    const totalAutoCompoundBakc = bigNumToFloat(stakedAmountBakc) - bigNumToFloat(loanedAmounts[PoolId.BAKC]);

    setPoolsWithoutBorrow({
      MAYC: {
        ...maycPool.withoutBorrow,
        totalStakedAmountOnCyan: totalAutoCompoundMayc < 0 ? 0 : totalAutoCompoundMayc,
      },
      BAKC: {
        ...bakcPool.withoutBorrow,
        totalStakedAmountOnCyan: totalAutoCompoundBakc < 0 ? 0 : totalAutoCompoundBakc,
      },
      BAYC: {
        ...baycPool.withoutBorrow,
        totalStakedAmountOnCyan: totalAutoCompoundBayc < 0 ? 0 : totalAutoCompoundBayc,
      },
    });
  };

  useEffect(() => {
    if (!apeStakingData) return;
    if (isApeCoinStakingPossible(chainId)) {
      _safeSetData();
      updateUserBalance();
    }
  }, [chainId, provider, apeStakingData]);

  return (
    <ApeCoinStatsContext.Provider
      value={{
        userBalance,
        poolsWithBorrow,
        poolsWithoutBorrow,
        apeVault,
        updateUserBalance,
      }}
    >
      {children}
    </ApeCoinStatsContext.Provider>
  );
};

export const useApeCoinStatsContext = () => useContext(ApeCoinStatsContext);
