import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ApeCoin } from "@cyanco/components/theme/v3/images";

import { useVaults } from "@/components/Vault/VaultDataProvider";
import { BAKCAddress, BAYCAddress, CAPS_MAPPED_BY_ADDRESS, MAYCAddress, apeVaultContract, isProd } from "@/config";
import { useApePlanCreation } from "@/hooks/useApePlanCreation";
import { numberWithCommas } from "@/utils";

import Bakc from "../../../../../assets/images/bakc.svg";
import Bayc from "../../../../../assets/images/bayc.svg";
import Mayc from "../../../../../assets/images/mayc.svg";
import { useApeCoinStatsContext } from "../../ApeCoinStatsContext";
import { IActionType, IApeCoinSource } from "../../types";
import { Container, ContainerBox, Header, ListContainer, ListViewBox } from "../common";
import { ApePoolsHeader, ApePoolsRow } from "./ApePoolListRow";

export const ListView = () => {
  const { showPlanModal } = useApePlanCreation();
  const navigate = useNavigate();
  const { poolsWithBorrow, poolsWithoutBorrow, apeVault } = useApeCoinStatsContext();
  const { vaults } = useVaults();
  const vaultApy = useMemo(() => {
    const apeVault = vaults.find(
      ({ contractAddress }) => apeVaultContract.toLowerCase() === contractAddress.toLowerCase(),
    );
    if (!apeVault || !apeVault.estimatedYield) return 0;
    return apeVault.estimatedYield / 100;
  }, [vaults]);
  const vaultReward = useMemo(() => {
    if (!apeVault.totalStakedOnCyan || !vaultApy) return 0;
    return apeVault.totalStakedOnCyan * (1 - vaultApy / 365 / 100);
  }, [vaultApy, apeVault]);

  const poolsBorrow = useMemo(() => {
    return [
      {
        ...poolsWithBorrow.BAYC,
        name: `BAYC w/ borrow`,
        cap: numberWithCommas(CAPS_MAPPED_BY_ADDRESS[BAYCAddress]),
        image: Bayc,
        hasApeCoin: false,
        onClick: () =>
          showPlanModal({
            action: IActionType.stake,
            apeCoinSource: IApeCoinSource.borrow,
            selectedCollection: BAYCAddress.toLowerCase(),
          }),
      },
      {
        ...poolsWithBorrow.MAYC,
        name: `MAYC w/ borrow`,
        cap: numberWithCommas(CAPS_MAPPED_BY_ADDRESS[MAYCAddress]),
        image: Mayc,
        hasApeCoin: false,
        onClick: () =>
          showPlanModal({
            action: IActionType.stake,
            apeCoinSource: IApeCoinSource.borrow,
            selectedCollection: MAYCAddress.toLowerCase(),
          }),
      },
      {
        ...poolsWithBorrow.BAKC,
        name: `BAKC w/ borrow`,
        cap: numberWithCommas(CAPS_MAPPED_BY_ADDRESS[BAKCAddress]),
        image: Bakc,
        hasApeCoin: false,
        onClick: () =>
          showPlanModal({
            action: IActionType.stake,
            apeCoinSource: IApeCoinSource.borrow,
          }),
      },
    ];
  }, [poolsWithBorrow]);
  const pools = useMemo(() => {
    return [
      {
        ...poolsWithoutBorrow.BAYC,
        name: `BAYC`,
        cap: numberWithCommas(CAPS_MAPPED_BY_ADDRESS[BAYCAddress]),
        image: Bayc,
        hasApeCoin: true,
        onClick: () =>
          showPlanModal({
            action: IActionType.stake,
            apeCoinSource: IApeCoinSource.owned,
            selectedCollection: BAYCAddress.toLowerCase(),
          }),
      },
      {
        ...poolsWithoutBorrow.MAYC,
        name: `MAYC`,
        cap: numberWithCommas(CAPS_MAPPED_BY_ADDRESS[MAYCAddress]),
        image: Mayc,
        hasApeCoin: true,
        onClick: () =>
          showPlanModal({
            action: IActionType.stake,
            apeCoinSource: IApeCoinSource.owned,
            selectedCollection: MAYCAddress.toLowerCase(),
          }),
      },
      {
        ...poolsWithoutBorrow.BAKC,
        name: `BAKC`,
        cap: numberWithCommas(CAPS_MAPPED_BY_ADDRESS[BAKCAddress]),
        image: Bakc,
        hasApeCoin: true,
        onClick: () =>
          showPlanModal({
            action: IActionType.stake,
            apeCoinSource: IApeCoinSource.owned,
          }),
      },
    ];
  }, [poolsWithBorrow, poolsWithoutBorrow]);

  return (
    <ListContainer>
      <ListViewBox>
        <Header>
          <ApePoolsHeader />
        </Header>
        <ContainerBox>
          <Container>
            <ApePoolsRow
              item={{
                name: `Cyan ApeCoin Vault`,
                apy: vaultApy,
                totalStakedAmount: apeVault.totalStaked,
                totalStakedAmountOnCyan: apeVault.totalStakedOnCyan,
                rewardsPerDay: vaultReward,
                cap: "-",
                image: ApeCoin,
              }}
              onClick={() => navigate(`/vault/${isProd ? "mainnet" : "sepolia"}/${apeVaultContract}`)}
            />
            {poolsBorrow.map(pool => (
              <ApePoolsRow item={pool} onClick={pool.onClick} key={pool.name} />
            ))}
            {pools.map(pool => (
              <ApePoolsRow item={pool} onClick={pool.onClick} key={pool.name} />
            ))}
          </Container>
        </ContainerBox>
      </ListViewBox>
    </ListContainer>
  );
};
