import { Clock, DollarSign } from "react-feather";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { Button, Text } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IPeerPlan } from "@/apis/p2p/types";
import { ApeCoinStakingButtonOnNft } from "@/components/ApeCoinStaking/ApeCoinStakingButtonOnNFT";
import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { PaymentOptions } from "@/components/PlanPayment/PlanRepayment";
import { useWeb3React } from "@/components/Web3ReactProvider";

import { APE_COIN_STAKABLE_ADDRESSES } from "../../../../../config";
import { isApeCoinStakingPossible } from "../../../../../utils";
import { IPawn } from "../../../pawn.types";
import { UserNftMetadata } from "./UserNftMetadata";

export const UserPositionDetails = ({
  bnpl,
  pawn,
  peerPlan,
  onMakePayment,
  onClose,
}: {
  bnpl?: IBNPL;
  pawn?: IPawn;
  peerPlan?: IPeerPlan;
  onMakePayment: (a: PaymentOptions) => void;
  onClose: () => void;
}) => {
  const { chainId } = useWeb3React();
  const position = bnpl ?? pawn;
  const appraisalValue = bnpl?.price ?? pawn?.appraisalValue;
  const enableApeCoinStaking =
    isApeCoinStakingPossible(chainId) &&
    APE_COIN_STAKABLE_ADDRESSES.includes(
      peerPlan ? peerPlan.collectionAddress : position?.metadata.collectionAddress ?? "",
    );

  if (peerPlan) {
    return (
      <Flex direction="column" gap="0.5rem">
        <UserNftMetadata
          nft={{
            collectionName: peerPlan.collection.name,
            address: peerPlan.collectionAddress,
            tokenId: peerPlan.tokenId,
            currency: peerPlan.loanBid.currency,
            imageUrl: peerPlan.metadata ? peerPlan.metadata.imageUrl : NoImage,
            isCyanWalletAsset: true,
            ownership: {
              tokenCount: 1,
            },
          }}
        />
        <FeatureContainer>
          <FeatureButton>
            <StyledConfirmButton onClick={() => onMakePayment(PaymentOptions.regularPay)}>
              <Flex gap="4px" justifyContent="center" alignItems="center">
                <Clock width={13} height={13} strokeWidth="3px" />
                <Text color="black" size="sm" weight="600">
                  {`Make Payment`}
                </Text>
              </Flex>
            </StyledConfirmButton>
          </FeatureButton>

          {enableApeCoinStaking && (
            <FeatureButton>
              <ApeCoinStakingButtonOnNft onClose={onClose} />
            </FeatureButton>
          )}
        </FeatureContainer>
      </Flex>
    );
  }
  return (
    <>
      {position && (
        <Flex direction="column" gap="0.5rem">
          <UserNftMetadata
            nft={{
              collectionName: position.metadata.collection.name,
              address: position.metadata.collectionAddress,
              tokenId: position.tokenId,
              appraisalValue,
              currency: position.currency,
              imageUrl: position.metadata.imageUrl,
              isCyanWalletAsset: position.isCyanWallet || false,
              ownership: { tokenCount: 1 },
              tokenType: position.metadata.tokenType,
            }}
          />
          <FeatureContainer>
            <FeatureButton>
              <StyledConfirmButton onClick={() => onMakePayment(PaymentOptions.regularPay)}>
                <Flex gap="4px" justifyContent="center" alignItems="center">
                  <Clock width={13} height={13} strokeWidth="3px" />
                  <Text color="black" size="sm" weight="600">
                    {`Make Payment`}
                  </Text>
                </Flex>
              </StyledConfirmButton>
            </FeatureButton>

            <FeatureButton>
              <StyledConfirmButton onClick={() => onMakePayment(PaymentOptions.refinance)}>
                <Flex gap="4px" justifyContent="center" alignItems="center">
                  <DollarSign width={13} height={13} strokeWidth="3px" />
                  <Text color="black" size="sm" weight="600">
                    {`Refinance`}
                  </Text>
                </Flex>
              </StyledConfirmButton>
            </FeatureButton>

            {enableApeCoinStaking && position.isCyanWallet && (
              <FeatureButton>
                <ApeCoinStakingButtonOnNft onClose={onClose} />
              </FeatureButton>
            )}
          </FeatureContainer>
        </Flex>
      )}
    </>
  );
};
export const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;

export const FeatureContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 14px;
`;

export const FeatureButton = styled(Flex)`
  height: 36px;
  min-width: 40%;
  max-width: 50%;
  flex: 1;
`;
