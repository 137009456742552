import { BigNumber, constants } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UseAsyncReturn, useAsyncCallback } from "react-async-hook";
import { ArrowDown } from "react-feather";
import styled, { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";
import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import { Button, Card, CurrencyLogo, Flex, SkeletonLine, SystemMessage, Text } from "@cyanco/components/theme";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { priceBnplStep1, priceBnplStep2, pricePawnStep1, pricePawnStep2 } from "@/apis/pricer/index-v2";
import { IResult } from "@/apis/pricer/pricer-pawn-step1-v2";
import { useAppContext } from "@/components/AppContextProvider";
import { useUserAssets } from "@/components/Loans/LoanPageProvider";
import {
  AutoRepaymentSwitch,
  AutoRepaymentWarning,
  AutoRepaymentWarningNonNative,
} from "@/components/PlanPayment/PlanAutoRepayment";
import { ChevronDownWrapper } from "@/components/Vault/components/VaultDetail/VaultStake";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { usePlanCreation } from "@/hooks";
import { _getPossibilityMap } from "@/hooks/usePlanCreationForm";
import { useWalletModalToggle } from "@/state/application/hooks";
import { IAutoRepayStatuses, ICurrency, INftType } from "@/types";
import { bigNumToFloat, bigNumToFloatFormatted, displayInUSD, shortenName } from "@/utils";
import { mapAndLogError } from "@/utils/error";
import { pricerPossibleErrors } from "@/utils/error/api";
import { IMappedError, REQUEST_MORE_INFO, defaultError } from "@/utils/error/msgs";
import { IBendDaoLoan } from "@/utils/types";

import { PlanCreationDecimalFormatMap } from "../types";
import { autoLiquidationExperimentFlag, getPricerCalculator } from "../utils";
import { PaymentsStepsBulk } from "./PaymentsStepsBulk";
import {
  IItemWithPricer,
  IItemsWithPricer,
  IPlanConfig,
  PlanCreationSteps,
  getPricerStep2Result,
} from "./PlanCreationModal";
import { PillWrapper } from "./PricerStep1Result";

export const Pricer2Result = ({
  items,
  currency,
  loading,
  pricePlanStep1,
  pricePlanStep2,
  setItemsWithPricer,
  setCreationStep,
  planType,
  pricePlanStep1Result,
  triggeredError,
  setTriggeredError,
  pricerStep2,
  onClose,
}: {
  items: Array<IItemWithPricer & { chosenConfig: IPlanConfig }>;
  planType: "bnpl" | "pawn";
  currency: ICurrency;
  loading: boolean;
  bendDaoLoan?: IBendDaoLoan;
  pricePlanStep1Result: IResult;
  pricePlanStep1: typeof priceBnplStep1 | typeof pricePawnStep1;
  pricePlanStep2: typeof priceBnplStep2 | typeof pricePawnStep2;
  setCreationStep: React.Dispatch<React.SetStateAction<PlanCreationSteps>>;
  setItemsWithPricer: React.Dispatch<React.SetStateAction<IItemsWithPricer>>;
  triggeredError?: IMappedError;
  setTriggeredError: (a?: IMappedError) => void;
  pricerStep2: UseAsyncReturn<Awaited<ReturnType<typeof getPricerStep2Result>> | undefined>;
  onClose?: () => void;
}) => {
  const cyanWallet = useCyanWallet();
  const theme = useTheme();
  const { usdPrice, experiment } = useAppContext();
  const toggleWalletModal = useWalletModalToggle();
  const { chainId, account } = useWeb3React();
  const { initiatePlanCreationV2, checkAccountBalanceAndProceed } = usePlanCreation(planType);

  const [erc1155Warning, setErc1155Warning] = useState(false);
  const [autoRepayStatus, setAutoRepayStatus] = useState(IAutoRepayStatuses.Disabled);
  const isAutoLiquidationEnabled = useMemo(() => {
    return experiment.result && experiment.result[autoLiquidationExperimentFlag(chainId)];
  }, [experiment, chainId]);
  const { isDemoAsset } = useUserAssets();
  const isDemo = useMemo(() => {
    return items.some(item => isDemoAsset(item.tokenId, item.address));
  }, [items]);
  const currencyFormatNumber = PlanCreationDecimalFormatMap.get(currency.decimal) || 4;
  const itemsWithPricerMethod = items.map(item => {
    const getPricingMethod = getPricerCalculator(planType, {
      config: item.config,
      baseInterestRate: item.baseInterestRate,
      totalPrice: item.price,
    });
    const pricerMethod = getPricingMethod({
      term: item.chosenConfig.term,
      totalNumberOfPayments: item.chosenConfig.totalNumberOfPayments,
      loanRate: item.chosenConfig.loanRate,
      autoRepayStatus,
    });
    return {
      ...item,
      pricerMethod,
    };
  });
  const isReadyToPreview =
    items.every(item => item.chosenConfig) && itemsWithPricerMethod.every(item => item.pricerMethod);

  const isNativeCurrencyPlan = currency.address.toLowerCase() === constants.AddressZero.toLowerCase();
  const _checkAccountBalanceAndProceed = async () => {
    if (!account) return;
    if (planType === "bnpl" && cyanWallet) {
      await checkAccountBalanceAndProceed({
        currency,
        items,
        payAmount: totalDownpayment,
        setTriggeredError,
        onNext: proceedPlanCreation,
        planType,
      });
      return;
    }
    proceedPlanCreation({ selectedWalletAddress: account });
  };

  const { execute: onConfirm, loading: onConfirmLoading } = useAsyncCallback(async () => {
    setTriggeredError(undefined);
    if (!account) {
      toggleWalletModal();
      return;
    }
    if (!chainId || !isReadyToPreview) return;
    if (
      planType === "pawn" &&
      items.length > 1 &&
      items.some(item => !item.isCyanWalletAsset && item.itemType === INftType.ERC1155)
    ) {
      if (!erc1155Warning) {
        setErc1155Warning(true);
        return;
      }
    }
    if (pricerStep2.result && !pricerStep2.result.hasError) {
      await _checkAccountBalanceAndProceed();
    } else {
      pricerStep2.execute(autoRepayStatus);
    }
  });

  const _initiatePlanCreation = (
    selectedWalletAddress: string,
    createdItems: Array<IItemWithPricer & { chosenConfig: IPlanConfig }>,
  ) => {
    if (!pricerStep2.result?.pricedItemsWithoutError || !pricerStep2.result) return;
    if (
      itemsWithPricerMethod.length !== createdItems.length ||
      !itemsWithPricerMethod.every(item => item.pricerMethod?.serviceFeeRate)
    ) {
      setCreationStep(PlanCreationSteps.SelectTerm);
      setTriggeredError({
        title: defaultError.title,
        msg: "Getting loan with selected items is not possible at the moment.",
        description: REQUEST_MORE_INFO,
      });
      return;
    }
    const { pricedItemsWithoutError } = pricerStep2.result;
    setItemsWithPricer(itemsWithPricerMethod.map(({ error: _error, pricerMethod: _, ...item }) => item));
    initiatePlanCreationV2({
      items: createdItems.map(({ error: _, ...item }, i) => {
        return {
          ...item,
          ...pricedItemsWithoutError[i],
          serviceFeeRate: itemsWithPricerMethod[i].pricerMethod?.serviceFeeRate ?? 0,
        };
      }),
      autoRepayStatus: autoRepayStatus,
      blockNumber: pricerStep2.result.blockNumber,
      step1Result: {
        currency,
        pricePlanStep1,
        pricePlanStep2,
        pricerPlan: pricePlanStep1Result,
      },
      selectedWalletAddress,
      onClose: onClose,
    });
  };

  const proceedPlanCreation = useCallback(
    ({ selectedWalletAddress }: { selectedWalletAddress: string }) => {
      if (!pricerStep2.result) return;
      const createdItems = items.filter(item => {
        if (item?.error) {
          return [
            pricerPossibleErrors.DifferentMarketplace,
            pricerPossibleErrors.PriceChanged,
            pricerPossibleErrors.AppraisalChanged,
          ].includes(item.error);
        }
        return true;
      });
      if (createdItems.length === 0) {
        setCreationStep(PlanCreationSteps.SelectTerm);
        setTriggeredError({
          title: defaultError.title,
          msg: "Getting loan with selected items is not possible at the moment.",
          description: REQUEST_MORE_INFO,
        });
        return;
      }
      _initiatePlanCreation(selectedWalletAddress, createdItems);
    },
    [items, pricerStep2.result],
  );
  useEffect(() => {
    if (!isReadyToPreview || !pricerStep2.result || pricerStep2.result.hasError) return;
    _checkAccountBalanceAndProceed();
  }, [proceedPlanCreation, pricerStep2.result, pricerStep2.error]);

  useEffect(() => {
    if (triggeredError) {
      setCreationStep(PlanCreationSteps.SelectTerm);
    }
  }, [triggeredError]);

  const totalBorrowAmount = itemsWithPricerMethod.reduce((acc, cur) => {
    return acc.add(cur.pricerMethod?.loaningAmount ?? 0);
  }, BigNumber.from(0));

  const totalDownpayment = itemsWithPricerMethod.reduce((acc, cur) => {
    return acc.add(cur.pricerMethod?.downpaymentAmount ?? 0);
  }, BigNumber.from(0));
  const pricerStep2ErrorMapped = pricerStep2.error ? mapAndLogError(pricerStep2.error) : undefined;

  return (
    <Flex gap="12px" direction="column">
      {pricerStep2ErrorMapped && (
        <SystemMessage
          variant="error"
          title={pricerStep2ErrorMapped.title}
          msg={pricerStep2ErrorMapped.msg}
          description={pricerStep2ErrorMapped.description}
        />
      )}
      <Flex gap="0.2rem" direction="column">
        <Card pt="8px" pb="14px" pl="8px" pr="8px">
          <Flex direction="column" gap="12px">
            <Text color="gray0" weight="500" size="sm">
              You collateralise
            </Text>
            <Flex direction="column" gap="0.5rem">
              {itemsWithPricerMethod.map(item => (
                <Flex justifyContent="space-between" key={`${item.address}:${item.tokenId}`} alignItems="center">
                  <Flex gap="0.3rem" alignItems="center">
                    <ItemImage src={item.imageUrl ?? NoImage} />
                    <Text color="secondary">
                      {`${shortenName(item.collectionName, 30)} #${shortenName(item.tokenId, 7, 3, 3)}`}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between" alignItems="center">
                    {isAutoLiquidationEnabled && item.isAutoLiquidated && (
                      <AutoLiquidationBox>
                        <span>Auto-Liquidation</span>
                      </AutoLiquidationBox>
                    )}
                    <Text color="secondary">
                      {`${bigNumToFloatFormatted(
                        item.pricerMethod?.loaningAmount ?? 0,
                        currency.decimal,
                        currencyFormatNumber,
                      )} ${currency.symbol}`}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Card>
        <div style={{ position: "relative" }}>
          <ChevronDownWrapper>
            <ArrowDown color={theme.colors.secondary} size={22} />
          </ChevronDownWrapper>
        </div>
        <Card p="8px">
          <Flex direction="column" gap="0.2rem">
            <Text size="sm" weight="too" color="gray0">
              You borrow
            </Text>
            <Flex justifyContent="space-between" alignItems="center">
              {loading ? (
                <SkeletonLine w="50%" h="22px" />
              ) : (
                <Text size="lg" weight="600" color="secondary">
                  {`${bigNumToFloatFormatted(totalBorrowAmount, currency.decimal, currencyFormatNumber)} ${
                    currency.symbol
                  }`}
                </Text>
              )}
              <PillWrapper>
                <CurrencyLogo symbol={currency.symbol as SupportedCurrenciesType} />
                <Text color="secondary" weight="500" size="md">
                  {currency.symbol}
                </Text>
              </PillWrapper>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Text size="xs" weight="500" color="gray0">
                {displayInUSD(bigNumToFloat(totalBorrowAmount, currency.decimal) * (usdPrice[currency.symbol] ?? 0))}
              </Text>
            </Flex>
          </Flex>
        </Card>
      </Flex>
      <PaymentsStepsBulk
        planType={planType}
        currency={currency}
        pricerMethods={itemsWithPricerMethod.map(item => item.pricerMethod)}
      />
      <AutoRepaymentSwitch
        autoRepayStatus={autoRepayStatus}
        onChange={v => {
          setAutoRepayStatus(v);
        }}
        disabled={false}
      />
      {autoRepayStatus === IAutoRepayStatuses.FromCyanWallet && (
        <AutoRepaymentWarning autoRepayStatus={autoRepayStatus} />
      )}
      {autoRepayStatus === IAutoRepayStatuses.FromMainWallet && (
        <AutoRepaymentWarningNonNative isNativeCurrency={isNativeCurrencyPlan} currencySymbol={currency.symbol} />
      )}
      <Button
        style={{ height: "50px" }}
        disabled={onConfirmLoading || pricerStep2.loading || isDemo}
        loading={onConfirmLoading || pricerStep2.loading}
        onClick={onConfirm}
      >
        Confirm
      </Button>
    </Flex>
  );
};

const ItemImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 6px;
`;
const AutoLiquidationBox = styled(Flex)`
  background-color: #3c3c3c;
  color: ${({ theme }) => theme.colors.gray90};
  font-size: 8px;
  font-family: Inter;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 0.1rem 0.3rem;
  margin-right: 0.5rem;
`;
