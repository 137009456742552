import { useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Flex } from "@cyanco/components/theme";
import { Text, Tooltip, TooltipText } from "@cyanco/components/theme/v3";
import { HelpCircle } from "@cyanco/components/theme/v3/icons";

import { BAKCAddress, BAYCAddress, CAPS_MAPPED_BY_ADDRESS, MAYCAddress } from "@/config";
import { bigNumToFloat, formatCompactNumber, numberWithCommas } from "@/utils";

import { useApeCoinStatsContext } from "../../../ApeCoinStatsContext";
import { IApeCoinUserBalance, ISelectedNft } from "../../../types";
import { ApeCoinStakingInput } from "../../common";

export const ApeCoinStakingWithoutPlan = ({
  selectedMainNfts: nfts,
  selectedCollection,
  stakingAmount,
  cyanWalletStakingAmount,
  userBalance,
  onMainInputChange,
  onCyanInputChange,
}: {
  selectedMainNfts: ISelectedNft[];
  selectedCollection: string;
  stakingAmount?: string;
  cyanWalletStakingAmount?: string;
  userBalance: IApeCoinUserBalance;
  onMainInputChange: (a: string) => void;
  onCyanInputChange: (a: string) => void;
}) => {
  const theme = useTheme();
  const cyanWallet = useCyanWallet();
  const { poolsWithoutBorrow, poolsWithBorrow } = useApeCoinStatsContext();
  const cap = useMemo(() => {
    if (nfts.length == 0) return CAPS_MAPPED_BY_ADDRESS[selectedCollection];
    return nfts ? CAPS_MAPPED_BY_ADDRESS[nfts[0].address] : CAPS_MAPPED_BY_ADDRESS[selectedCollection];
  }, [nfts]);
  const isStakingPossible = useMemo(() => {
    if (
      nfts &&
      nfts.length > 0 &&
      (nfts[0].apeStaking.stakedAmount === null || cap > bigNumToFloat(nfts[0].apeStaking.stakedAmount))
    )
      return true;
    return false;
  }, [nfts, cap]);
  const maxStakingAmount = useMemo(() => {
    if (nfts.length == 0) return 0;
    return cap * nfts.length;
  }, [nfts, cap]);
  const cyanBalance = userBalance.cyanWalletMax ? bigNumToFloat(userBalance.cyanWalletMax) : 0;
  const mainBalance = userBalance.mainWalletMax ? bigNumToFloat(userBalance.mainWalletMax) : 0;

  const inputAmount = useMemo(() => {
    return Number(stakingAmount || 0) + Number(cyanWalletStakingAmount || 0);
  }, [stakingAmount, cyanWalletStakingAmount]);
  const mainMax = useMemo(() => {
    const rest = maxStakingAmount - Number(cyanWalletStakingAmount || 0);
    return mainBalance >= rest ? rest : mainBalance;
  }, [maxStakingAmount, cyanWalletStakingAmount, mainBalance]);
  const cyanMax = useMemo(() => {
    const rest = maxStakingAmount - Number(stakingAmount || 0);
    return cyanBalance >= rest ? rest : cyanBalance;
  }, [stakingAmount, maxStakingAmount, cyanBalance]);
  const borrowingAmount = useMemo(() => {
    return maxStakingAmount - inputAmount;
  }, [inputAmount, maxStakingAmount]);

  const estimatedApy = useMemo(() => {
    const isBorrowing = borrowingAmount > 0;
    switch (selectedCollection.toLowerCase()) {
      case BAYCAddress:
        return formatCompactNumber(isBorrowing ? poolsWithBorrow.BAYC.apy : poolsWithoutBorrow.BAYC.apy);
      case MAYCAddress:
        return formatCompactNumber(isBorrowing ? poolsWithBorrow.MAYC.apy : poolsWithoutBorrow.MAYC.apy);
      case BAKCAddress:
        return formatCompactNumber(isBorrowing ? poolsWithBorrow.BAKC.apy : poolsWithoutBorrow.BAKC.apy);
    }
  }, [poolsWithoutBorrow, borrowingAmount, selectedCollection]);

  const alreadyStaked = useMemo(() => {
    if (nfts.length == 0) return 0;
    return nfts.reduce((acc, nft) => {
      return acc + (nft.apeStaking.stakedAmount ? bigNumToFloat(nft.apeStaking.stakedAmount) : 0);
    }, 0);
  }, [nfts]);

  return (
    <Flex direction="column" gap="1.4rem">
      <Flex direction="column" gap="0.8rem">
        <Flex direction="column" gap="0.3rem">
          <Flex justifyContent="space-between" p="0 2px">
            <Text color="gray0" size="xs" weight="600">
              {`Main Wallet`}
            </Text>
            <Text color="gray0" size="xs" weight="500">
              {`${`Balance`}: ${numberWithCommas(mainBalance, 2)} APE`}
            </Text>
          </Flex>
          <ApeCoinStakingInput
            disabled={!isStakingPossible}
            onInputChange={onMainInputChange}
            stakingAmount={stakingAmount}
            max={mainMax}
          />
        </Flex>
        {cyanWallet && (
          <Flex direction="column" gap="0.3rem">
            <Flex justifyContent="space-between" p="0 2px">
              <Text color="gray0" size="xs" weight="600">
                {`Cyan Wallet`}
              </Text>
              <Text color="gray0" size="xs" weight="500">
                {`${`Balance`}: ${numberWithCommas(cyanBalance, 2)} APE`}
              </Text>
            </Flex>
            <ApeCoinStakingInput
              disabled={!isStakingPossible}
              onInputChange={onCyanInputChange}
              stakingAmount={cyanWalletStakingAmount}
              max={cyanMax}
            />
          </Flex>
        )}
      </Flex>

      <StakedBox>
        <Flex direction="column" w="100%" gap="0.3rem">
          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="600">
              {`Staking with owned APE`}
            </Text>
            <Text color="secondary" size="xs" weight="600">
              {numberWithCommas(inputAmount)} APE
            </Text>
          </Flex>

          <Flex justifyContent="space-between" w="100%">
            <Flex alignItems="center" gap="3px">
              <Text color="secondary" size="xs" weight="600">
                {`Borrowing APE to top up`}
              </Text>
              <Tooltip>
                <HelpCircle height={12} width={12} color={theme.colors.secondary} />
                <TooltipText showArrow position="top" top="-122px" right="-73px" style={{ width: "150px" }}>
                  <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                    <div>{`When a maximum allowed amount of APE is not available or chosen by the user, APE is automatically borrowed from the Cyan ApeCoin Staking Vault to top up. This ensures every NFT stake is being fully utilized.`}</div>
                  </Text>
                </TooltipText>
              </Tooltip>
            </Flex>

            <Text color="secondary" size="xs" weight="600">
              {numberWithCommas(borrowingAmount)} APE
            </Text>
          </Flex>

          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="600">
              {`Already Staked`}
            </Text>
            <Text color="secondary" size="xs" weight="600">
              {numberWithCommas(alreadyStaked)} APE
            </Text>
          </Flex>

          <Flex justifyContent="space-between" w="100%">
            <Text color="secondary" size="xs" weight="600">
              {`Estimated APY`}
            </Text>
            <Text color="secondary" size="xs" weight="600">
              {estimatedApy}%
            </Text>
          </Flex>
        </Flex>
      </StakedBox>
    </Flex>
  );
};

const StakedBox = styled(Flex)`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 1rem 0.8rem;
`;
