import { JsonRpcSigner } from "@ethersproject/providers";
import { BigNumber, ethers } from "ethers";
import React, { useEffect, useMemo, useState } from "react";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme/components";
import { Stepper, useModal } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { notifyApeCoinActivity } from "@/apis/notification";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { apeCoinContract, apeStakingContract } from "@/config";
import { useApproval } from "@/hooks/useApproval";
import { getChainExplorerURL } from "@/utils";

import { useAppContext } from "../AppContextProvider";
import { useUserTokenContext } from "../Token/TokenContextProvider";
import { useTransactionContext } from "../TransactionContextProvider";
import { ApeCoinBalance } from "./CommonComponents";
import { ApeCoinStakingWithERC20 } from "./StakingWithErc20";

type IStakingWithERC20Stepper = {
  chainId: number;
  signer: JsonRpcSigner;
  stakingAmount: string;
  isCyanWalletToken: boolean;
  apeCoinBalance: {
    stakedAmount: null | BigNumber;
    earnedAmount: BigNumber | null;
    mainWalletMax: BigNumber | null;
    cyanWalletMax: BigNumber | null;
    allowance: BigNumber | null;
  };
};

export const StakingWithERC20Stepper: React.FC<IStakingWithERC20Stepper> = ({
  chainId,
  stakingAmount,
  apeCoinBalance,
  isCyanWalletToken,
  signer,
}) => {
  const { account, provider } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const { setFireConfetti } = useAppContext();
  const { giveErc20Approval } = useApproval();
  const { refreshUserTokens } = useUserTokenContext();
  const { setModalContent } = useModal();
  const { addTransaction } = useTransactionContext();
  const stakingAmountConverted = useMemo(() => ethers.utils.parseEther(stakingAmount), [stakingAmount]);
  const [selectedStep, setSelectedStep] = useState<StakingSteps>(StakingSteps.TranferApe);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const apeCoinStakingContract = f.ApeCoinStakingFactory.connect(apeStakingContract, signer);
  const apeCoinStakingIFace = f.ApeCoinStakingFactory.createInterface();

  const transfer = async () => {
    if (isCyanWalletToken) {
      if (!cyanWallet || apeCoinBalance.cyanWalletMax === null) {
        return;
      }
      if (apeCoinBalance.cyanWalletMax.lt(stakingAmountConverted)) {
        const requiredAmount = stakingAmountConverted.sub(apeCoinBalance.cyanWalletMax);
        const contractWriter = f.SampleERC20TokenFactory.connect(apeCoinContract, signer);
        const tx = await contractWriter.transfer(cyanWallet.walletAddress, requiredAmount);
        await tx.wait();
      }
    } else {
      if (apeCoinBalance.mainWalletMax === null || !account) {
        return;
      }
      if (apeCoinBalance.mainWalletMax.lt(stakingAmountConverted)) {
        if (!cyanWallet) return;
        const contractIFace = f.SampleERC20TokenFactory.createInterface();
        const requiredAmount = stakingAmountConverted.sub(apeCoinBalance.mainWalletMax);
        const encodedFnData = contractIFace.encodeFunctionData("transfer", [account, requiredAmount]);
        const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [
          apeCoinContract,
          "0",
          encodedFnData,
        ]);
        const tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data: encodedCyanFnData,
        });
        await tx.wait();
      }
    }
  };

  const accept = async () => {
    if (!account) return;
    const stakingAmountConverted = ethers.utils.parseEther(stakingAmount);
    if (apeCoinBalance.allowance && apeCoinBalance.allowance.gte(stakingAmountConverted)) {
      return;
    }
    if (isCyanWalletToken && !cyanWallet) throw new Error("Cyan wallet not found");
    await giveErc20Approval(
      {
        currencyAddress: apeCoinContract,
        amount: stakingAmountConverted,
        cyanWallet: isCyanWalletToken && cyanWallet ? cyanWallet : undefined,
      },
      apeStakingContract,
    );
  };

  const stakeApeCoin = async () => {
    if (!account) return;
    let tx: ethers.ContractTransaction;
    if (isCyanWalletToken) {
      if (!cyanWallet) throw new Error("Cyan wallet not found");
      const encodedFnData = apeCoinStakingIFace.encodeFunctionData("depositApeCoin", [
        ethers.utils.parseEther(stakingAmount),
        cyanWallet.walletAddress,
      ]);
      const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [
        apeStakingContract,
        "0",
        encodedFnData,
      ]);
      tx = await signer.sendTransaction({
        to: cyanWallet.walletAddress,
        data: encodedCyanFnData,
      });
    } else {
      tx = await apeCoinStakingContract.depositApeCoin(ethers.utils.parseEther(stakingAmount), account);
    }
    addTransaction({ hash: tx.hash, type: "ape-stake" });
    await tx.wait();
    setTxnFinal(tx.hash);
    notifyApeCoinActivity({
      amount: stakingAmount,
      user: account,
      action: "Staked with ERC20",
      token: "erc20",
    });
    refreshUserTokens();
  };
  useEffect(() => {
    const onStepChange = async (step: StakingSteps) => {
      if (!provider) return;
      try {
        switch (step) {
          case StakingSteps.TranferApe:
            await transfer();
            setSelectedStep(StakingSteps.Accept);
            break;
          case StakingSteps.Accept:
            await accept();
            setSelectedStep(StakingSteps.Stake);
            break;
          case StakingSteps.Stake:
            await stakeApeCoin();
            setSelectedStep(StakingSteps.Done);
            setFireConfetti(true);
            break;
        }
      } catch (e) {
        setModalContent({
          title: `ApeCoin Staking`,
          content: (
            <ApeCoinStakingWithERC20
              chainId={chainId}
              isStaked={false}
              provider={provider}
              signer={signer}
              isCyanWalletToken={isCyanWalletToken}
              processFailedData={{
                error: e,
                stakingAmount,
              }}
            />
          ),
        });
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep]);
  const steps = useMemo(() => {
    const filteredSteps = StakingStepMarks.filter(step => {
      if (
        step.value === StakingSteps.TranferApe &&
        ((isCyanWalletToken &&
          apeCoinBalance.cyanWalletMax != null &&
          apeCoinBalance.cyanWalletMax.gte(stakingAmountConverted)) ||
          (!isCyanWalletToken &&
            apeCoinBalance.mainWalletMax != null &&
            apeCoinBalance.mainWalletMax.gte(stakingAmountConverted)))
      ) {
        return false;
      }
      if (apeCoinBalance.allowance && apeCoinBalance.allowance.gte(stakingAmountConverted)) {
        return step.value !== StakingSteps.Accept;
      }
      return true;
    });
    return filteredSteps.map(step => {
      if (step.value === StakingSteps.TranferApe) {
        return !isCyanWalletToken
          ? {
              ...step,
              title: `Move $APE from Cyan Wallet`,
              description: `Move $APE from your Cyan Wallet to your Main Wallet.`,
            }
          : {
              ...step,
              title: `Move $APE from Main Wallet`,
              description: `Move $APE from your Main Wallet to your Cyan Wallet.`,
            };
      } else {
        return {
          ...step,
        };
      }
    });
  }, [isCyanWalletToken]);

  return (
    <Flex direction="column" gap="1rem">
      <ApeCoinBalance stakedAmount={apeCoinBalance.stakedAmount} earnedAmount={apeCoinBalance.earnedAmount} />
      <Box pb="1rem" pt="1rem">
        <Stepper
          marks={steps}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
    </Flex>
  );
};

enum StakingSteps {
  TranferApe = 1,
  Accept = 2,
  Stake = 3,
  Done = 4,
}
const StakingStepMarks = [
  {
    value: StakingSteps.TranferApe,
    title: `Move $APE from Cyan Wallet`,
  },
  {
    value: StakingSteps.Accept,
    title: `User Accepts Terms & Conditions`,
    description: `Permission to move my ApeCoin to stake into ApeCoin pools.`,
  },
  {
    value: StakingSteps.Stake,
    title: `$APE successfully staked`,
    description: `View on Etherscan`,
  },
];
