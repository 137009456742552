import { Provider } from "@ethersproject/providers";
import { BigNumber } from "ethers";
import { parseEther } from "ethers/lib/utils";

import { factories as f } from "@cyanco/contract";
import { ApeCoinStaking } from "@cyanco/contract/abis";

import { IApePlanData } from "@/apis/ape-plans";
import { PoolId, apePaymentPlanContract, apeStakingContract, apeVaultContract } from "@/config";
import { bigNumToFloat } from "@/utils";
import { executeBatchRead, getCyanWalletAddress } from "@/utils/contract";
import { IBatchReaderData } from "@/utils/types";

import { IApeCoinBalance, IApeCoinPlanType, IApeStakingDataContract, IApeStakingDataCyan } from "./types";

export const getAllStakingData = async (args: { chainId: number; provider: Provider; mainWallet: string }) => {
  const { chainId, provider, mainWallet } = args;
  const cyanWalletAddress = await getCyanWalletAddress({ mainWallet, provider });
  const iApeStakingContract = f.ApeCoinStakingFactory.createInterface();
  const batchReadParam: IBatchReaderData[] = [
    {
      interface: iApeStakingContract,
      functionName: "getAllStakes",
      params: [mainWallet],
      contractAddress: apeStakingContract,
    },
  ];
  if (cyanWalletAddress) {
    batchReadParam.push({
      interface: iApeStakingContract,
      functionName: "getAllStakes",
      params: [cyanWalletAddress],
      contractAddress: apeStakingContract,
    });
  }
  const batchResult = await executeBatchRead(chainId, provider, batchReadParam);
  const allStakesMapped: IApeStakingDataContract[] = [];

  const apeCoinBalance: IApeCoinBalance = {
    stakedAmount: BigNumber.from("0"),
    stakedAmountCyan: BigNumber.from("0"),
    earnedAmount: BigNumber.from("0"),
    earnedAmountCyan: BigNumber.from("0"),
  };

  const mainWalletStakes = batchResult[0][0] as ApeCoinStaking.DashboardStakeStructOutput[];
  const cyanWalletStakes = (batchResult[1]?.[0] ?? []) as ApeCoinStaking.DashboardStakeStructOutput[];
  const allNftStakes = [...mainWalletStakes, ...cyanWalletStakes].filter(stake => !stake.poolId.eq(0));
  for (const stake of allNftStakes) {
    const pairedTokenId = bigNumToFloat(stake.pair.mainTokenId, 0);
    const pairedTokenPool = bigNumToFloat(stake.pair.mainTypePoolId, 0);
    const isPaired = pairedTokenId !== 0 && pairedTokenPool !== 0;
    allStakesMapped.push({
      tokenId: bigNumToFloat(stake.tokenId, 0).toString(),
      poolId: bigNumToFloat(stake.poolId, 0),
      stakedAmount: stake.deposited,
      earnedAmount: stake.unclaimed,
      pairedTokenId: isPaired ? pairedTokenId.toString() : null,
      pairedTokenPool: isPaired ? pairedTokenPool : null,
      isPaired,
    });
  }
  const apeCoinPoolStakeMain = mainWalletStakes.find(stake => stake.poolId.eq(0));
  if (apeCoinPoolStakeMain) {
    apeCoinBalance.stakedAmount = apeCoinPoolStakeMain.deposited;
    apeCoinBalance.earnedAmount = apeCoinPoolStakeMain.unclaimed;
  }
  if (cyanWalletAddress) {
    const apeCoinPoolStakeCyan = cyanWalletStakes.find(stake => stake.poolId.eq(0));
    if (apeCoinPoolStakeCyan) {
      apeCoinBalance.stakedAmountCyan = apeCoinPoolStakeCyan.deposited;
      apeCoinBalance.earnedAmountCyan = apeCoinPoolStakeCyan.unclaimed;
    }
  }
  // get bakc pairing info to main tokens
  allStakesMapped.forEach(stake => {
    const stakedBakc = allStakesMapped.find(
      s =>
        s.pairedTokenId === stake.tokenId &&
        s.pairedTokenPool === stake.poolId &&
        s.isPaired &&
        s.poolId === PoolId.BAKC,
    );
    if (stakedBakc) {
      stake.isPaired = true;
      stake.pairedTokenId = stakedBakc.tokenId;
      stake.pairedTokenPool = PoolId.BAKC;
    }
  });
  return { allNftStakes: allStakesMapped, apeCoinBalance };
};

export const applyApeStakingDataToAssets = <T>(args: {
  apeStakeDatasFromContract: IApeStakingDataContract[];
  assets: Array<
    T & {
      poolId: number;
      tokenId: string;
    } & IApePlanData
  >;
  vaultTokenPrice: BigNumber;
  serviceFeeRate: BigNumber;
  pools: {
    [key: number]: {
      rewardsPerHour: BigNumber;
      totalStakedAmount: BigNumber;
      interestRate: number;
    };
  };
}): Array<T & IApeStakingDataCyan> => {
  const { apeStakeDatasFromContract, assets, vaultTokenPrice, serviceFeeRate, pools } = args;
  const newItems = [...assets].map(asset => {
    const apeStakeDataFromContract = apeStakeDatasFromContract.find(
      stake => stake.tokenId === asset.tokenId && asset.poolId === stake.poolId,
    );
    const apeStakingData: IApeStakingDataCyan["apeStaking"] = {
      poolId: asset.poolId,
      stakedAmount: apeStakeDataFromContract?.stakedAmount ?? BigNumber.from(asset?.apeStaking?.borrowedApe ?? 0),
      earnedAmount: apeStakeDataFromContract?.earnedAmount ?? BigNumber.from(0),
      pairedTokenId: apeStakeDataFromContract?.pairedTokenId ?? null,
      pairedTokenPool: apeStakeDataFromContract?.pairedTokenPool ?? null,
      isPaired: apeStakeDataFromContract?.isPaired ?? false,
      plan: null,
    };
    if (asset.apeStaking) {
      const { stakedAmount, earnedAmount, poolId } = apeStakingData;
      const interestRateScaled = BigNumber.from(pools[poolId].interestRate).mul(100);
      const totalServiceFeeRate = serviceFeeRate.add(interestRateScaled);
      const remainingRateForBorrowed = BigNumber.from(10000).sub(totalServiceFeeRate);
      const remainingRateForRewards = BigNumber.from(10000).sub(serviceFeeRate);
      if (apeStakeDataFromContract && !stakedAmount.isZero()) {
        const borrowApeRewards = earnedAmount
          .mul(asset.apeStaking.borrowedApe)
          .div(stakedAmount)
          .mul(remainingRateForBorrowed)
          .div(10000);

        const rewards = earnedAmount
          .mul(stakedAmount.sub(asset.apeStaking.borrowedApe))
          .div(stakedAmount)
          .mul(remainingRateForRewards)
          .div(10000);

        apeStakingData.earnedAmount = rewards.add(borrowApeRewards);
      } else {
        apeStakingData.earnedAmount = BigNumber.from(0);
      }
      apeStakingData.plan = {
        ...asset.apeStaking,
        type: BigNumber.from(asset.apeStaking.borrowedApe).gt(0)
          ? IApeCoinPlanType.Borrow
          : IApeCoinPlanType.AutoCompound,
        totalRewards: vaultTokenPrice.mul(asset.apeStaking.totalRewards),
        rewardStakeToCyanVault: asset.apeStaking.rewardStakeToCyanVault,
      };
    }
    return { ...asset, apeStaking: apeStakingData };
  });
  return newItems;
};

const getPoolsUI = async (provider: Provider, chainId: number) => {
  try {
    const pools = [PoolId.BAYC, PoolId.MAYC, PoolId.BAKC];
    const iPoolContract = f.ApeCoinStakingFactory.createInterface();
    const batchParams: IBatchReaderData[] = [];
    for (const poolId of pools) {
      batchParams.push({
        interface: iPoolContract,
        functionName: "pools",
        params: [poolId],
        contractAddress: apeStakingContract,
      });
    }
    const batchResult = await executeBatchRead(chainId, provider, batchParams);

    const batchParamsForRewards = [];
    for (const [index, poolId] of pools.entries()) {
      const pool = batchResult[index];
      batchParamsForRewards.push({
        interface: iPoolContract,
        functionName: "getTimeRangeBy",
        params: [poolId, pool.lastRewardsRangeIndex],
        contractAddress: apeStakingContract,
      });
    }
    const batchResultRewards = await executeBatchRead(chainId, provider, batchParamsForRewards);
    const poolsData = pools.reduce((acc, poolId, index) => {
      const pool = batchResult[index];
      const currentTimeRange = batchResultRewards[index][0];
      acc[poolId] = {
        stakedAmount: pool.stakedAmount,
        rewardsPerHour: currentTimeRange.rewardsPerHour,
      };
      return acc;
    }, {} as { [key: number]: { stakedAmount: BigNumber; rewardsPerHour: BigNumber } });
    return poolsData;
  } catch (e) {
    console.error("Error getting pools UI", e);
    return null;
  }
};

export const getPoolAndVaultData = async (
  provider: Provider,
  chainId: number,
): Promise<{
  poolsData: {
    [key: number]: {
      rewardsPerHour: BigNumber;
      totalStakedAmount: BigNumber;
      interestRate: number;
    };
  };
  serviceFeeRate: BigNumber;
  vaultTokenPrice: BigNumber;
}> => {
  const iApeVaultContract = f.CyanApeCoinVaultV1Factory.createInterface();
  const iApePlanContract = f.CyanApeCoinPlanV1Factory.createInterface();
  const oneCyanVaultToken = parseEther("1");
  const batchResult = await executeBatchRead(chainId, provider, [
    {
      interface: iApeVaultContract,
      functionName: "getPoolInterestRates",
      params: [],
      contractAddress: apeVaultContract,
    },
    {
      interface: iApePlanContract,
      functionName: "serviceFeeRate",
      params: [],
      contractAddress: apePaymentPlanContract,
    },
    {
      interface: iApeVaultContract,
      functionName: "calculateCurrencyByToken",
      params: [oneCyanVaultToken],
      contractAddress: apeVaultContract,
    },
  ]);
  const _interestRates = batchResult[0][0];
  const serviceFeeRate = batchResult[1][0];
  const vaultTokenPrice = batchResult[2][0];
  const poolsUi = await getPoolsUI(provider, chainId);
  return {
    poolsData: {
      [PoolId.MAYC]: {
        totalStakedAmount: poolsUi ? poolsUi[PoolId.MAYC].stakedAmount : BigNumber.from(0),
        rewardsPerHour: poolsUi ? poolsUi[PoolId.MAYC].rewardsPerHour : BigNumber.from(0),
        interestRate: bigNumToFloat(_interestRates[PoolId.MAYC], 0) / 100,
      },
      [PoolId.BAYC]: {
        totalStakedAmount: poolsUi ? poolsUi[PoolId.BAYC].stakedAmount : BigNumber.from(0),
        rewardsPerHour: poolsUi ? poolsUi[PoolId.BAYC].rewardsPerHour : BigNumber.from(0),
        interestRate: bigNumToFloat(_interestRates[PoolId.BAYC], 0) / 100,
      },
      [PoolId.BAKC]: {
        totalStakedAmount: poolsUi ? poolsUi[PoolId.BAKC].stakedAmount : BigNumber.from(0),
        rewardsPerHour: poolsUi ? poolsUi[PoolId.BAKC].rewardsPerHour : BigNumber.from(0),
        interestRate: bigNumToFloat(_interestRates[PoolId.BAKC], 0) / 100,
      },
    },
    serviceFeeRate: serviceFeeRate,
    vaultTokenPrice: vaultTokenPrice,
  };
};

export const DAYS_IN_YEAR = 365;
