import { BigNumber } from "ethers";
import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-async-hook";
import { useMatch } from "react-router-dom";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { fetchVaultPositionsByUser } from "@/apis/user";
import {
  fetchVaultAdditionalMetrics as fetchVaultAdditionalMetricsApi,
  fetchVaults as fetchVaultsApi,
} from "@/apis/vault";
import { IVault, IVaultAdditionalMetrics, IVaultBE } from "@/apis/vault/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { SupportedChainId } from "@/constants/chains";
import { IsInTestDrive, bigNumToFloat } from "@/utils";
import { getLockTermAndTokenPriceOfVaults } from "@/utils/contract";

import { useAppContext } from "../AppContextProvider";
import { IVaultPositionBe } from "../Vault/types";
import { useVaultSelectors } from "./VaultPageContext";

interface IVaultContext {
  vaults: Array<IVault>;
  setVaults: (vaults: Array<IVault>) => void;
  fetchVaults: () => Promise<IVaultBE[]>;
  fetchingVaults: boolean;

  positions: Array<IVaultPositionBe>;
  positionsCyanWallet: Array<IVaultPositionBe>;
  fetchPositionsCyan: () => Promise<Array<IVaultPositionBe>>;
  fetchPositions: () => Promise<Array<IVaultPositionBe>>;
  vaultStats: {
    tvl: number;
    numOfStakers: number;
  };
  setVaultStats: (a: { tvl: number; numOfStakers: number; volume: number }) => void;
  vaultAdditionalMetrics: IVaultAdditionalMetrics;
  fetchingVaultAdditionalMetrics: boolean;
  fetchVaultAdditionalMetrics: () => Promise<IVaultAdditionalMetrics>;
}
const VaultDataContext = createContext<IVaultContext>({
  vaults: [],
  setVaults: () => {},
  fetchVaults: async () => [],
  fetchingVaults: false,
  positions: [],
  positionsCyanWallet: [],
  fetchPositionsCyan: async () => [],
  fetchPositions: async () => [],
  vaultStats: {
    tvl: 0,
    numOfStakers: 0,
  },
  setVaultStats: () => {},
  vaultAdditionalMetrics: {
    collections: [],
    latestLoans: [],
    totalDefaultRate: 0,
    cashflowData: [],
  },
  fetchingVaultAdditionalMetrics: false,
  fetchVaultAdditionalMetrics: async () => {
    return {
      collections: [],
      history: [],
      latestLoans: [],
      totalDefaultRate: 0,
      cashflowData: [],
    };
  },
});

export const VaultDataProvider: FC = ({ children }) => {
  const match = useMatch("/vault/:chain/:contractAddress");
  const matchAdmin = useMatch("/vault-admin/:chain/:contractAddress");
  const contractAddress = match ? match.params.contractAddress : matchAdmin?.params.contractAddress;

  const cyanWallet = useCyanWallet();
  const { chainId, account } = useWeb3React();

  const { usdPrice } = useAppContext();
  const [vaults, setVaults] = useState<Array<IVault>>([]);
  const [loading, setLoading] = useState(true);
  const [vaultStats, setVaultStats] = useState({
    tvl: 0,
    numOfStakers: 0,
  });

  const { result: positions = [], merge: setPositions } = useAsync<Array<IVaultPositionBe>>(() => {
    if (IsInTestDrive || !account) return Promise.resolve([]);
    return fetchVaultPositionsByUser(account);
  }, [account, IsInTestDrive]);

  const { result: positionsCyanWallet = [], merge: setPositionsCyanWallet } = useAsync<Array<IVaultPositionBe>>(() => {
    if (!cyanWallet) return Promise.resolve([]);
    return fetchVaultPositionsByUser(cyanWallet.walletAddress);
  }, [cyanWallet]);

  const fetchPositions = useCallback(async () => {
    if (!account) return Promise.resolve([]);
    const positions = await fetchVaultPositionsByUser(account);
    setPositions({
      result: positions,
    });
    return positions;
  }, [account, setPositions]);

  const fetchPositionsCyan = useCallback(async () => {
    if (!cyanWallet) return Promise.resolve([]);
    const positions = await fetchVaultPositionsByUser(cyanWallet.walletAddress);
    setPositionsCyanWallet({
      result: positions,
    });
    return positions;
  }, [cyanWallet, setPositionsCyanWallet]);

  const {
    result: vaultsBE,
    loading: fetchingVaults,
    execute: fetchVaults,
  } = useAsync<Array<IVaultBE>>(fetchVaultsApi, []);

  const {
    result: vaultAdditionalMetrics,
    loading: fetchingVaultAdditionalMetrics,
    execute: fetchVaultAdditionalMetrics,
  } = useAsync<IVaultAdditionalMetrics>(
    async () => {
      if (!contractAddress) {
        return Promise.resolve({
          collections: [],
          latestLoans: [],
          totalDefaultRate: 0,
          cashflowData: [],
        });
      }
      return fetchVaultAdditionalMetricsApi(contractAddress);
    },
    [contractAddress],
    {
      initialState() {
        return {
          status: "loading",
          error: undefined,
          loading: true,
          result: {
            collections: [],
            latestLoans: [],
            totalDefaultRate: 0,
            cashflowData: [],
          },
        };
      },
    },
  );

  useEffect(() => {
    const updateVaultsWithPrices = async () => {
      if (!vaultsBE) return;
      setLoading(true);
      const currentChainVaults = vaultsBE.filter(vault => vault.chainId === chainId);
      const otherChainVaults = vaultsBE.filter(vault => vault.chainId !== chainId);
      const vaultsContractDatas = await getLockTermAndTokenPriceOfVaults({
        chainId,
        vaults: currentChainVaults,
      });
      const vaultsWithPrice = [...currentChainVaults, ...otherChainVaults].map((vault, index) => {
        const vaultContractData = vaultsContractDatas[index];
        if (vaultContractData) {
          return {
            ...vault,
            totalValueLockedUsd: bigNumToFloat(vault?.totalValueLocked || 0, vault.decimals) * usdPrice[vault.currency],
            priceUsd: bigNumToFloat(vaultContractData.tokenPrice, vault.decimals) * usdPrice[vault.currency],
            price: vaultContractData.tokenPrice,
            withdrawLockTerm: vaultContractData.withdrawLockTerm,
          };
        } else {
          return {
            ...vault,
            totalValueLockedUsd: bigNumToFloat(vault?.totalValueLocked || 0, vault.decimals) * usdPrice[vault.currency],
            priceUsd: bigNumToFloat(vault.price ?? 0, vault.decimals) * usdPrice[vault.currency],
            price: vault.price,
            withdrawLockTerm: BigNumber.from(0),
          };
        }
      });
      setVaults(vaultsWithPrice);
      setLoading(false);
    };
    if (!fetchingVaults && vaultsBE && vaultsBE.length > 0 && chainId && vaultsBE.every(v => v.currency in usdPrice)) {
      updateVaultsWithPrices();
    }
  }, [vaultsBE, chainId, usdPrice]);

  useEffect(() => {
    if (vaultsBE && vaultsBE.length > 0 && vaultsBE.every(v => v.currency in usdPrice)) {
      const totalStats = {
        tvl: 0,
        numOfStakers: 0,
        volume: 0,
      };
      const stats = [...vaultsBE].reduce(
        (acc, vault) => {
          acc.numOfStakers += vault.numOfStakers;
          acc.tvl += bigNumToFloat(vault.totalValueLocked || 0, vault.decimals) * usdPrice[vault.currency];
          return acc;
        },
        { ...totalStats },
      );
      setVaultStats(stats);
    }
  }, [vaultsBE, usdPrice]);

  return (
    <VaultDataContext.Provider
      value={{
        positionsCyanWallet,
        fetchPositionsCyan,
        vaults,
        setVaults,
        fetchVaults,
        fetchingVaults: fetchingVaults || loading,
        positions,
        fetchPositions,
        vaultStats,
        setVaultStats,
        vaultAdditionalMetrics: vaultAdditionalMetrics ?? {
          collections: [],
          latestLoans: [],
          totalDefaultRate: 0,
          cashflowData: [],
        },
        fetchingVaultAdditionalMetrics,
        fetchVaultAdditionalMetrics,
      }}
    >
      {children}
    </VaultDataContext.Provider>
  );
};

export const useVaultDataContext = () => {
  const { fetchPositions: fetchPositionsMain, fetchPositionsCyan } = useContext(VaultDataContext);
  const fetchPositions = async () => {
    const [positions, positionsCyanWallet] = await Promise.all([fetchPositionsMain(), fetchPositionsCyan()]);
    return [...positions, ...positionsCyanWallet];
  };
  return { ...useContext(VaultDataContext), fetchPositions };
};

export const useVaults = () => {
  const {
    vaults,
    fetchingVaults,
    fetchVaults,
    vaultAdditionalMetrics,
    fetchVaultAdditionalMetrics,
    fetchingVaultAdditionalMetrics,
    setVaults,
  } = useVaultDataContext();
  return {
    vaults,
    fetchingVaults,
    fetchVaults,
    vaultAdditionalMetrics,
    fetchVaultAdditionalMetrics,
    fetchingVaultAdditionalMetrics,
    setVaults,
  };
};

export const useVaultPositions = () => {
  const { positions, fetchPositions, positionsCyanWallet } = useVaultDataContext();
  return { vaultPositions: positions, fetchPositions, vaultPositionsCyan: positionsCyanWallet };
};

export const useFilteredVaults = () => {
  const { experiment } = useAppContext();
  const { vaults, fetchingVaults } = useVaults();
  const { selectedVaultSearch, selectedChain } = useVaultSelectors();
  const filteredVaults = useMemo(() => {
    if (selectedVaultSearch !== "") {
      return vaults.filter(({ chainId: vaultChainId, name }) => {
        return selectedChain === "all"
          ? name.toLowerCase().startsWith(selectedVaultSearch.toLowerCase())
          : vaultChainId === selectedChain && name.toLowerCase().startsWith(selectedVaultSearch.toLowerCase());
      });
    } else {
      return vaults.filter(({ chainId: vaultChainId }) => {
        return selectedChain === "all" || vaultChainId === selectedChain;
      });
    }
  }, [vaults, selectedVaultSearch, selectedChain, experiment.result]);
  return {
    vaults: filteredVaults,
    fetchingVaults,
  };
};

export const useFilteredVaultsNew = () => {
  const { experiment } = useAppContext();
  const { vaults, fetchingVaults } = useVaults();
  const { selectedChainType, selectedCurrency, selectedAutoLiquidationStatus } = useVaultSelectors();
  const layer2Chains = [
    SupportedChainId.BLAST,
    SupportedChainId.BLAST_SEPOLIA,
    SupportedChainId.POLYGON,
    SupportedChainId.CURTIS,
    SupportedChainId.APECHAIN,
  ];
  const filteredVaults = useMemo(() => {
    return vaults
      .filter(vault => selectedCurrency === "all" || vault.currency === selectedCurrency)
      .filter(({ chainId: vaultChainId }) => {
        return selectedChainType === "all" || layer2Chains.includes(vaultChainId);
      })
      .filter(({ isAutoLiquidated }) => {
        return (
          selectedAutoLiquidationStatus === "All liquidations" ||
          (selectedAutoLiquidationStatus === "No liquidation" && isAutoLiquidated === false) ||
          (selectedAutoLiquidationStatus === "Auto-liquidation" && isAutoLiquidated === true)
        );
      });
  }, [vaults, selectedChainType, selectedCurrency, selectedAutoLiquidationStatus, experiment.result]);
  const uniqueCurrencies = useMemo(() => {
    return [...new Set(filteredVaults.map(({ currency }) => currency))];
  }, [vaults]);
  return {
    vaults: filteredVaults,
    fetchingVaults,
    layer2Chains,
    uniqueCurrencies,
  };
};
export const useVaultStats = () => {
  const { vaultStats, fetchingVaults } = useVaultDataContext();
  return {
    totalTvl: vaultStats.tvl,
    totalStakers: vaultStats.numOfStakers,
    loading: fetchingVaults,
  };
};
