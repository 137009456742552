import { JsonRpcSigner, Provider } from "@ethersproject/providers";
import { BigNumber, ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useAsyncCallback } from "react-async-hook";

import { CyanWallet, useCyanWallet } from "@usecyan/cyan-wallet";

import { Flex } from "@cyanco/components/theme/components";
import { Option, Select, SystemMessage, Text, useModal } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { currencyList } from "@/apis/coinbase";
import { notifyApeCoinActivity } from "@/apis/notification";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { apeCoinContract as apeCoinContractAddress, apeStakingContract } from "@/config";
import { mapAndLogError } from "@/utils/error";
import { IMappedError } from "@/utils/error/msgs";

import { bigNumToFixedStr, bigNumToFloat, isApeCoinStakingPossible, shortenAddress } from "../../utils";
import { useAppContext } from "../AppContextProvider";
import { useUserTokenContext } from "../Token/TokenContextProvider";
import { useTransactionContext } from "../TransactionContextProvider";
import {
  ApeCoinBalance,
  ApeCoinClaim,
  ApeCoinStaking,
  ApeCoinStakingInput,
  ApeCoinUnstake,
  ApeCoinWithdraw,
  SuccessText,
} from "./CommonComponents";
import { StakingWithERC20Stepper } from "./StakingWithERC20Stepper";

export const ApeCoinStakingWithERC20 = ({
  provider,
  isStaked,
  chainId,
  isCyanWalletToken,
  processFailedData,
  signer,
}: {
  provider: Provider;
  signer: JsonRpcSigner;
  isStaked: boolean;
  chainId: number;
  isCyanWalletToken: boolean;
  processFailedData?: {
    stakingAmount: string;
    error: any;
  };
}) => {
  const { addTransaction } = useTransactionContext();
  const { setModalContent } = useModal();
  const { usdPrice } = useAppContext();
  const { refreshUserTokens } = useUserTokenContext();
  const [stakingAmount, setStakingAmount] = useState<string>();
  const [error, setError] = useState<IMappedError | null>(null);
  const [apeCoinBalance, setApeCoinBalance] = useState<{
    stakedAmount: null | BigNumber;
    earnedAmount: BigNumber | null;
    mainWalletMax: BigNumber | null;
    cyanWalletMax: BigNumber | null;
    allowance: BigNumber | null;
  }>({
    stakedAmount: null,
    earnedAmount: null,
    mainWalletMax: null,
    cyanWalletMax: null,
    allowance: null,
  });
  const [withdrawAction, setWithdrawAction] = useState<"unstake" | "claim">();
  const [withdrawWallet, setWithdrawWallet] = useState<string>();

  const { account } = useWeb3React();
  const cyanWallet = useCyanWallet();
  const apeCoinStakingContract = f.ApeCoinStakingFactory.connect(apeStakingContract, signer);
  const apeCoinStakingIFace = f.ApeCoinStakingFactory.createInterface();
  const isStakingAmountPossible = useMemo(() => {
    if (!apeCoinBalance.mainWalletMax) return false;
    const stakingAmountBN = ethers.utils.parseEther(stakingAmount || "0");
    const totalBN = apeCoinBalance.mainWalletMax.add(apeCoinBalance.cyanWalletMax || 0);
    return stakingAmountBN.lte(totalBN);
  }, [apeCoinBalance, stakingAmount]);

  const buildCyanWalletExecuteFnData = (cyanWallet: CyanWallet, encodedFnData: string) => {
    const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [
      apeStakingContract,
      "0",
      encodedFnData,
    ]);
    return encodedCyanFnData;
  };

  const stake = () => {
    if (!stakingAmount || !provider || !account) return;
    setModalContent({
      title: `Processing`,
      content: (
        <StakingWithERC20Stepper
          stakingAmount={stakingAmount}
          chainId={chainId}
          signer={signer}
          apeCoinBalance={apeCoinBalance}
          isCyanWalletToken={isCyanWalletToken}
        />
      ),
    });
  };
  const {
    execute: claimReward,
    loading: claimRewardProcessing,
    result: claimRewardProcessCompleted = false,
  } = useAsyncCallback(async () => {
    try {
      setError(null);
      if (!signer || !account) {
        throw new Error(`No provider found!`);
      }
      if (!stakingAmount || !apeCoinBalance.earnedAmount || !withdrawWallet) return;
      let tx: ethers.ContractTransaction;
      if (isCyanWalletToken && cyanWallet) {
        const encodedFnData = apeCoinStakingIFace.encodeFunctionData("claimApeCoin", [withdrawWallet]);
        const encodedCyanFnData = cyanWallet.interface.encodeFunctionData("execute", [
          apeStakingContract,
          "0",
          encodedFnData,
        ]);
        tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data: encodedCyanFnData,
        });
      } else {
        tx = await apeCoinStakingContract.claimApeCoin(withdrawWallet);
      }
      addTransaction({ hash: tx.hash, type: "ape-claim" });
      await tx.wait();
      notifyApeCoinActivity({
        amount: bigNumToFixedStr(apeCoinBalance.earnedAmount),
        user: withdrawWallet,
        action: "Claimed with ERC20",
        token: "erc20",
      });
      refreshUserTokens();
      return true;
    } catch (e: any) {
      const mappedError = mapAndLogError(e, account);
      setError(mappedError);
    }
  });

  const {
    execute: unstakeApeCoin,
    loading: unstakeProcessing,
    result: unstakingProcessCompleted = false,
  } = useAsyncCallback(async () => {
    try {
      setError(null);
      if (!signer || !account) {
        throw new Error(`No provider found!`);
      }
      if (!stakingAmount || !apeCoinBalance.stakedAmount || !withdrawWallet) return;
      let tx: ethers.ContractTransaction;
      if (isCyanWalletToken && cyanWallet) {
        const encodedFnData = apeCoinStakingIFace.encodeFunctionData("withdrawApeCoin", [
          apeCoinBalance.stakedAmount,
          withdrawWallet,
        ]);
        tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          data: buildCyanWalletExecuteFnData(cyanWallet, encodedFnData),
        });
      } else {
        tx = await apeCoinStakingContract.withdrawApeCoin(apeCoinBalance.stakedAmount, withdrawWallet);
      }
      addTransaction({ hash: tx.hash, type: "ape-unstake" });
      await tx.wait();
      notifyApeCoinActivity({
        amount: `${bigNumToFixedStr(apeCoinBalance.stakedAmount)} + ${bigNumToFixedStr(
          apeCoinBalance.earnedAmount || 0,
        )}`,
        user: withdrawWallet,
        action: "Unstaked with ERC20",
        token: "erc20",
      });
      refreshUserTokens();
      return true;
    } catch (e: any) {
      const mappedError = mapAndLogError(e, account);
      setError(mappedError);
    }
  });

  useEffect(() => {
    const _setApeCoinBalance = async () => {
      if (!signer || !account) return;

      const apeCoinContract = f.ApeCoinFactory.connect(apeCoinContractAddress, signer);
      const apeCoinStakingContract = f.ApeCoinStakingFactory.connect(apeStakingContract, provider);
      let addressPosition = {
        stakedAmount: BigNumber.from(0),
        rewardsDebt: BigNumber.from(0),
      };
      let balanceCyan = BigNumber.from(0);
      let allowance = BigNumber.from(0);
      let pendingRewards = BigNumber.from(0);
      if (isCyanWalletToken) {
        if (cyanWallet) {
          addressPosition = await apeCoinStakingContract.addressPosition(cyanWallet.walletAddress);
          allowance = await apeCoinContract.allowance(cyanWallet.walletAddress, apeStakingContract);
          pendingRewards = await apeCoinStakingContract.pendingRewards(0, cyanWallet.walletAddress, 0);
        }
      } else {
        addressPosition = await apeCoinStakingContract.addressPosition(account);
        allowance = await apeCoinContract.allowance(account, apeStakingContract);
        pendingRewards = await apeCoinStakingContract.pendingRewards(0, account, 0);
      }
      const balanceMain = await apeCoinContract.balanceOf(account);
      if (cyanWallet) balanceCyan = await apeCoinContract.balanceOf(cyanWallet.walletAddress);
      setApeCoinBalance({
        stakedAmount: addressPosition.stakedAmount,
        earnedAmount: pendingRewards,
        mainWalletMax: balanceMain,
        cyanWalletMax: balanceCyan,
        allowance,
      });
      if (processFailedData) {
        setStakingAmount(processFailedData.stakingAmount);
        return;
      }
      if (bigNumToFloat(addressPosition.stakedAmount) > 0) {
        setStakingAmount(bigNumToFixedStr(pendingRewards.add(addressPosition.stakedAmount), 2));
      } else if (!allowance.isZero()) {
        setStakingAmount(bigNumToFloat(allowance).toString());
      }
    };

    if (isApeCoinStakingPossible(chainId)) {
      _setApeCoinBalance();
    }
  }, [account, cyanWallet, unstakingProcessCompleted, claimRewardProcessCompleted]);
  const ClosedErrorMemod = useMemo(() => {
    if (!processFailedData) return null;
    if (typeof processFailedData.error === "string")
      return <SystemMessage variant="error" title={processFailedData.error} msg={processFailedData.error} />;
    const mappedError = mapAndLogError(processFailedData.error);
    return <SystemMessage variant="error" title={mappedError.title} msg={mappedError.msg} />;
  }, [processFailedData]);
  return (
    <Flex direction="column">
      <Flex direction="column" gap="1rem">
        <ApeCoinBalance stakedAmount={apeCoinBalance.stakedAmount} earnedAmount={apeCoinBalance.earnedAmount} />
        {ClosedErrorMemod && ClosedErrorMemod}
        {!unstakeProcessing && !claimRewardProcessing && error && (
          <SystemMessage msg={error.msg} title={error.title} variant="error" description={error.description} />
        )}
        {!claimRewardProcessCompleted && !unstakingProcessCompleted && account && (
          <>
            <ApeCoinStakingInput
              isStaked={isStaked}
              stakingAmount={stakingAmount}
              balance={{
                mainWallet: apeCoinBalance.mainWalletMax,
                cyanWallet: apeCoinBalance.cyanWalletMax,
              }}
              account={{
                mainWallet: account,
              }}
              onInputChange={setStakingAmount}
              apeUsd={usdPrice[currencyList.ape]}
            />
            {!isStaked && (
              <ApeCoinStaking
                onClick={stake}
                disabled={!!(!isStakingAmountPossible || !stakingAmount || parseFloat(stakingAmount) === 0)}
              />
            )}
            {isStaked && (
              <Flex direction="column" gap="1.5rem">
                <Flex direction="column" gap="0.5em">
                  <Text size="sm" color="secondary">
                    {`Claim or Unstake your APE: `}
                  </Text>
                  <Select onChange={setWithdrawAction} value={withdrawAction} textSize="xs">
                    <Option value={"claim"}>
                      <Text size="xs" color="secondary">
                        {`${`Claim`} ${bigNumToFixedStr(apeCoinBalance.earnedAmount || 0, 3)} APE`}
                      </Text>
                    </Option>
                    <Option value={"unstake"}>
                      <Text size="xs" color="secondary">
                        {`${`Unstake`} ${
                          apeCoinBalance.earnedAmount !== null &&
                          apeCoinBalance.stakedAmount !== null &&
                          bigNumToFixedStr(apeCoinBalance.stakedAmount?.add(apeCoinBalance.earnedAmount) || 0, 3)
                        } APE`}
                      </Text>
                    </Option>
                  </Select>
                </Flex>
                <Flex direction="column" gap="0.5em">
                  <Text size="sm" color="secondary">
                    {`Select Wallet to send APE to:`}
                  </Text>
                  <Select onChange={setWithdrawWallet} value={withdrawWallet} textSize="xs">
                    <Option value={account}>
                      <Text size="xs" color="secondary">
                        {`${`MAIN`} (${shortenAddress(account, 15)})`}
                      </Text>
                    </Option>
                    {cyanWallet && (
                      <Option value={cyanWallet.walletAddress}>
                        <Text size="xs" color="secondary">
                          {`${`CYAN`} (${shortenAddress(cyanWallet.walletAddress, 15)})`}
                        </Text>
                      </Option>
                    )}
                  </Select>
                </Flex>
                {withdrawAction === "claim" && withdrawWallet && (
                  <ApeCoinClaim
                    earnedAmount={apeCoinBalance.earnedAmount}
                    onClick={claimReward}
                    processing={claimRewardProcessing}
                    disabled={claimRewardProcessing || unstakeProcessing}
                  />
                )}
                {withdrawAction === "unstake" && withdrawWallet && (
                  <ApeCoinUnstake
                    earnedAmount={apeCoinBalance.earnedAmount}
                    stakedAmount={apeCoinBalance.stakedAmount}
                    processing={unstakeProcessing}
                    onClick={unstakeApeCoin}
                    disabled={claimRewardProcessing || unstakeProcessing}
                  />
                )}
                {(!withdrawAction || !withdrawWallet) && <ApeCoinWithdraw />}
              </Flex>
            )}
          </>
        )}
      </Flex>
      {claimRewardProcessCompleted && <SuccessText text={`Successfully Claimed!`} />}
      {unstakingProcessCompleted && <SuccessText text={`Successfully Unstaked!`} />}
    </Flex>
  );
};
