import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useAsyncAbortable } from "react-async-hook";
import styled from "styled-components";

import { SupportedMarketPlaces } from "@usecyan/shared/utils/marketplaces.type";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Card, NftCardLoading, NotFound, useModal } from "@cyanco/components/theme/v3";

import { fetchCollectionAttributes } from "@/apis/collection";
import { FlagStatuses, IResult as IFetchListedNftsResult, fetchListedNfts } from "@/apis/collection/fetch-listed-nfts";
import { ICollecionAttributes, ICollectionAttribute, ICollectionBe } from "@/apis/collection/types";
import { fetchPrivateSalesWithNft } from "@/apis/private-sale";
import { useBNPLPositions } from "@/components/Account/AccountDataContext";
import { useBargainAssets } from "@/components/BargainShop/BargainDataContext";
import { BargainItems } from "@/components/BargainShop/BargainItems";
import { MarketNotice } from "@/components/NoticeModals";
import { InfiniteScroller } from "@/components/Pagination/InfiniteScroller";
import { PrivateSalePurchaseModal } from "@/components/PrivateSale/PrivateSaleBuyPurchaseModal";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { MAX_BNPL_LIMIT, SupportedCurrenciesByChain } from "@/config";
import { usePlanCreation } from "@/hooks";
import { useBnplQueryParams } from "@/hooks/useBnplQueryParams";
import { INftBe } from "@/types";
import { ERRORS, getRarityRankV3 } from "@/utils";
import { isMobile } from "@/utils/userAgent";

import { FLOOR_PRICE_THRESHOLD, FLOOR_PRICE_THRESHOLD_MAX_COUNT, useBnplCart } from "../../BnplCartContext";
import { OnSaleNftsView, useBnplSelectors, useBnplViewType } from "../../BnplPageContext";
import { OnSaleNftCard, OnSaleNftCardLoading } from "../OnSaleNftCard";
import { OnSaleNftRow, OnSaleNftRowLoading, OnSaleNftsListHeader } from "../OnSaleNftRow";
import { PrivateSaleItems } from "../PrivateSaleItems";
import { CartItemsMultiSelection } from "../SelectItems/MultiSelectionBox";
import { AttributeFilters } from "./Filter/AttributeFilters";
import { Selectors } from "./Selectors";
import { TokenModalLoading } from "./TokenModalLoading";

const AttributeFilterContainer = styled.div`
  ${getStyleWithMediaQuery("position", "", [{ [breakpoints.desktop]: "static" }, { [breakpoints.mobile]: "fixed" }])}
  ${getStyleWithMediaQuery("top", "", [{ [breakpoints.mobile]: "0" }])}
  ${getStyleWithMediaQuery("left", "", [{ [breakpoints.mobile]: "0" }])}
  ${getStyleWithMediaQuery("z-index", "", [{ [breakpoints.mobile]: "200" }])}
  ${getStyleWithMediaQuery("height", "", [{ [breakpoints.mobile]: "100vh" }])}
`;

const OnSaleNftWrapper: React.FC<{
  showByGrid: boolean;
  loading: boolean;
  collection: ICollectionBe;
  collectionAttributes: ICollectionAttribute[];
  collectionSupportedCurrencies: { symbol: string; address: string }[];
}> = ({ children, showByGrid, loading, collection, collectionAttributes, collectionSupportedCurrencies }) => {
  const { showFilterAttributes } = useBnplSelectors();
  useEffect(() => {
    if (window.innerWidth > breakpoints.mobile) {
      return;
    }
    if (showFilterAttributes) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("html")[0].style.overflow = "auto";
    }
    return () => {
      document.getElementsByTagName("html")[0].style.overflow = "auto";
    };
  }, [showFilterAttributes]);

  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 580;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (showByGrid) {
    return (
      <Flex direction="column">
        <Selectors collection={collection} border={scrolled} />
        <GridContainer direction="column" gap="10px">
          <Flex direction="row" gap="2rem" mb="80px">
            <AttributeFilterContainer
              style={{
                display: showFilterAttributes ? "block" : "none",
              }}
            >
              <AttributeFilters
                collection={collection}
                attributes={collectionAttributes}
                supportedCurrencies={collectionSupportedCurrencies}
              />
            </AttributeFilterContainer>
            {loading ? <OnSaleNftCardLoading /> : <BnplNFTWrapper>{children}</BnplNFTWrapper>}
          </Flex>
        </GridContainer>
      </Flex>
    );
  } else {
    return (
      <ListContainer>
        <Selectors collection={collection} />
        <StyledListViewBox>
          <Flex direction="row" w="100%" gap="2rem">
            <AttributeFilterContainer
              style={{
                display: showFilterAttributes ? "block" : "none",
              }}
            >
              <AttributeFilters
                collection={collection}
                attributes={collectionAttributes}
                supportedCurrencies={collectionSupportedCurrencies}
              />
            </AttributeFilterContainer>
            <ListViewBox>
              <Header>
                <HeaderWrapper>
                  <OnSaleNftsListHeader />
                </HeaderWrapper>
              </Header>
              <ContainerBox>
                {loading ? (
                  <div>
                    {Array.from(Array(4).keys()).map(loader => (
                      <OnSaleNftRowLoading key={loader} />
                    ))}
                  </div>
                ) : (
                  children
                )}
              </ContainerBox>
            </ListViewBox>
          </Flex>
        </StyledListViewBox>
      </ListContainer>
    );
  }
};

export const OnSaleNfts: React.FC<{ collection: ICollectionBe }> = ({ collection }) => {
  const { account } = useWeb3React();
  const { setModalContent, unsetModal } = useModal();
  const { showNewPlanModal } = usePlanCreation("bnpl");
  const { activeBnplPositions } = useBNPLPositions();
  const { selectedOnSaleNftsView } = useBnplViewType();
  const { itemsCountGtThreshold, getCollectionFloorPrice, items } = useBnplCart();
  const {
    minPrice,
    maxPrice,
    maxRarity,
    minRarity,
    showOSFlagged,
    sortBy,
    currencies,
    attributes,
    marketplaces,
    tokenId,
    setTokenParam,
  } = useBnplQueryParams();
  const { onSaleAssetsLoading: bargainAssetsLoading, onSaleAsset: bargainAsset } = useBargainAssets();
  const { selectedTokenId, selectedPriceCurrency } = useBnplSelectors();
  const { result: privateSaleNfts = [], loading: privateSaleNftsLoading } = useAsyncAbortable(
    async abortSignal => {
      if (account) {
        const response = await Promise.all([
          fetchPrivateSalesWithNft({
            collectionAddress: collection.address,
            buyerAddresses: [account],
            abortSignal,
            chainId: collection.chainId,
          }),
          fetchPrivateSalesWithNft({
            collectionAddress: collection.address,
            sellerAddresses: [account],
            abortSignal,
            chainId: collection.chainId,
          }),
        ]);
        return response
          .flat()
          .filter(
            e =>
              !activeBnplPositions.find(
                item => item.metadata.collectionAddress === e.address && item.tokenId === e.tokenId,
              ),
          );
      }
      return [];
    },
    [collection, account],
  );
  const memoizedCurrenciesAddresses = useMemo(() => {
    if (!currencies) return;
    const result = SupportedCurrenciesByChain[collection.chainId]
      .filter(item => currencies.includes(item.symbol))
      .map(item => item.address);
    if (result.length) return result;
  }, [currencies, collection.chainId]);
  const memoizedMarketPlaces = useMemo(() => {
    if (!marketplaces) return;
    return marketplaces.map(element => element.toLowerCase());
  }, [marketplaces]);
  const {
    result: listedNftResult,
    merge: updateAsset,
    loading: onSaleAssetsLoading = false,
  } = useAsyncAbortable<IFetchListedNftsResult | undefined>(
    async abortSignal => {
      return fetchListedNfts({
        slug: collection.slug,
        tokenId: selectedTokenId === "" ? undefined : selectedTokenId,
        sortBy,
        abortSignal,
        attributes,
        minFloorAskPrice: minPrice,
        maxFloorAskPrice: maxPrice,
        minRarityRank: minRarity,
        maxRarityRank: maxRarity,
        priceCurrency: selectedPriceCurrency,
        currencies: memoizedCurrenciesAddresses,
        excludeSources: memoizedMarketPlaces,
        flagStatus: showOSFlagged ? FlagStatuses.default : FlagStatuses.nonFlagged,
      });
    },
    [
      collection.slug,
      memoizedCurrenciesAddresses,
      sortBy,
      selectedTokenId,
      minPrice,
      maxPrice,
      minRarity,
      maxRarity,
      showOSFlagged,
      attributes,
    ],
  );
  const { result: collectionTraits, loading: collectionTraitsLoading = false } =
    useAsyncAbortable<ICollecionAttributes>(
      async abortSignal => {
        return fetchCollectionAttributes({
          address: collection.address,
          chainId: collection.chainId,
          abortSignal,
        });
      },
      [collection],
    );

  const loadMoreOnSaleNfts = async () => {
    if (listedNftResult && listedNftResult.continuation && !onSaleAssetsLoading) {
      const { items, continuation } = await fetchListedNfts({
        slug: collection.slug,
        continuation: listedNftResult.continuation,
        tokenId: selectedTokenId ? selectedTokenId : undefined,
        sortBy,
        attributes,
        minFloorAskPrice: minPrice,
        currencies: memoizedCurrenciesAddresses,
        maxFloorAskPrice: maxPrice,
        minRarityRank: minRarity,
        maxRarityRank: maxRarity,
        priceCurrency: selectedPriceCurrency,
        flagStatus: showOSFlagged ? FlagStatuses.default : FlagStatuses.nonFlagged,
      });
      if (collection.address.toLowerCase() === listedNftResult.collectionAddress.toLowerCase()) {
        updateAsset({
          result: {
            continuation,
            items: [...listedNftResult.items, ...items],
            fetchedTimestamp: listedNftResult.fetchedTimestamp,
            collectionAddress: listedNftResult.collectionAddress,
          },
        });
      }
    }
  };

  const isSelectToCardPossible = useMemo(() => {
    if (items.length >= MAX_BNPL_LIMIT) return false;
    const floorPriceInEth = getCollectionFloorPrice(collection.address);
    if (floorPriceInEth.gt(FLOOR_PRICE_THRESHOLD)) {
      const availableGtThresholdItemsCount = FLOOR_PRICE_THRESHOLD_MAX_COUNT - itemsCountGtThreshold;
      return availableGtThresholdItemsCount > 0;
    }
    return true;
  }, [itemsCountGtThreshold, collection, items]);

  const bargainAssetsFiltered = useMemo(() => {
    if (!bargainAsset || !bargainAsset.length) return [];
    return (bargainAsset ?? [])
      .filter(item => item.address.toLowerCase() === collection.address.toLowerCase())
      .filter(
        e =>
          !activeBnplPositions.find(
            item => item.metadata.collectionAddress === e.address && item.tokenId === e.tokenId,
          ),
      );
  }, [collection, activeBnplPositions]);

  const isGrid = selectedOnSaleNftsView === OnSaleNftsView.grid;
  const isLoading = onSaleAssetsLoading || privateSaleNftsLoading || bargainAssetsLoading || collectionTraitsLoading;
  const assetsLength =
    privateSaleNfts.length +
    bargainAssetsFiltered.length +
    (
      listedNftResult?.items.filter(
        e =>
          !activeBnplPositions.find(
            item => item.metadata.collectionAddress === e.address && item.tokenId === e.tokenId,
          ),
      ) ?? []
    ).length;
  const openModal = (nft: INftBe) => {
    if (Object.values<string>(SupportedMarketPlaces).includes(nft.marketName.toLowerCase())) {
      showNewPlanModal({ currency: nft.currency, items: [nft], onClose });
    } else {
      setModalContent({
        title: `Unsupported Marketplace`,
        content: <MarketNotice marketName={nft.marketName} onClick={unsetModal} />,
      });
    }
  };
  const onClose = async () => {
    setTokenParam();
  };
  useEffect(() => {
    if (!tokenId) return;
    if (isLoading) {
      setModalContent({
        title: "Pay Later",
        content: (
          <TokenModalLoading
            item={{
              collectionName: collection.name,
              tokenId: tokenId,
            }}
          />
        ),
        onClose,
      });
      return;
    }
    const privateSaleNft = privateSaleNfts.find(item => item.tokenId === tokenId);
    if (privateSaleNft && privateSaleNft.privateSale) {
      setModalContent({
        title: `Private Sale`,
        content: (
          <PrivateSalePurchaseModal
            nft={{
              ...privateSaleNft,
              address: privateSaleNft.privateSale.collectionAddress,
              price: BigNumber.from(privateSaleNft.privateSale.price),
              itemType: privateSaleNft.privateSale.tokenType,
              amount: privateSaleNft.privateSale.tokenAmount,
              privateSaleId: privateSaleNft.privateSale.id,
              marketName: SupportedMarketPlaces.CYANPRIVATESALE,
            }}
            privateSale={privateSaleNft.privateSale}
          />
        ),
        onClose,
      });
      return;
    }
    const bargainAsset = bargainAssetsFiltered.find(item => item.tokenId === tokenId);
    if (bargainAsset && bargainAsset.privateSale) {
      showNewPlanModal({
        currency: bargainAsset.currency,
        items: [
          {
            ...bargainAsset,
            address: bargainAsset.privateSale.collectionAddress,
            price: BigNumber.from(bargainAsset.privateSale.price),
            itemType: bargainAsset.privateSale.tokenType,
            amount: bargainAsset.privateSale.tokenAmount,
            marketName: SupportedMarketPlaces.CYANPRIVATESALE,
            privateSaleId: bargainAsset.privateSale.id,
          },
        ],
        onClose,
      });
      return;
    }
    const listedNft = (listedNftResult?.items ?? []).find(item => item.tokenId === tokenId);
    if (listedNft) {
      openModal(listedNft);
      return;
    }
    const fetchToken = async () => {
      const token = await fetchListedNfts({
        slug: collection.slug,
        tokenId,
      });
      if (token?.items?.length && token.items[0]?.price) {
        openModal(token.items[0]);
      } else {
        setModalContent({
          title: "Pay Later",
          content: (
            <TokenModalLoading
              item={{
                collectionName: collection.name,
                tokenId: tokenId,
              }}
              error={{
                title: "Not found",
                message: ERRORS.NO_BUY_NOW_PRICE,
              }}
            />
          ),
          onClose,
        });
      }
    };
    fetchToken();
  }, [tokenId, isLoading]);

  return (
    <OnSaleNftWrapper
      showByGrid={isGrid}
      loading={isLoading}
      collection={collection}
      collectionAttributes={collectionTraits?.attributes ?? []}
      collectionSupportedCurrencies={collectionTraits?.supportedCurrencies ?? []}
    >
      {listedNftResult && (
        <div
          style={{
            width: isGrid ? "100%" : "calc(100% - 2px)",
          }}
        >
          {assetsLength === 0 ? (
            <NotFound msg={`No availabe NFT found.`} />
          ) : (
            <InfiniteScroller
              hasMore={listedNftResult.continuation !== null}
              loadMore={loadMoreOnSaleNfts}
              loader={
                isGrid ? (
                  <>
                    {Array.from(Array(isMobile ? 8 : 12).keys()).map(loader => (
                      <NftCardLoading key={loader} actionText={`Buy Now, Pay Later `} />
                    ))}
                  </>
                ) : (
                  <>
                    {Array.from(Array(3).keys()).map(loader => (
                      <OnSaleNftRowLoading key={loader} />
                    ))}
                  </>
                )
              }
              isGrid={isGrid}
            >
              <PrivateSaleItems
                items={privateSaleNfts}
                collection={collection}
                isGrid={isGrid}
                isMultiSelectPossible={isSelectToCardPossible}
              />
              <BargainItems
                isGrid={isGrid}
                items={bargainAssetsFiltered}
                isMultiSelectPossible={isSelectToCardPossible}
                isInBnplPage
              />
              {listedNftResult.items
                .filter(
                  e =>
                    !activeBnplPositions.find(
                      item => item.metadata.collectionAddress === e.address && item.tokenId === e.tokenId,
                    ),
                )
                .map(item =>
                  isGrid ? (
                    <OnSaleNftCard
                      nft={{
                        ...item,
                        rarity: getRarityRankV3(item.rarityRank, Number(collection.tokenCount)) ?? undefined,
                      }}
                      key={`${item.address}:${item.tokenId}`}
                      onClick={() => {
                        setTokenParam(item.tokenId);
                      }}
                      isSelectToCardPossible={isSelectToCardPossible}
                    />
                  ) : (
                    <OnSaleNftRow
                      nft={item}
                      isSelectToCardPossible={isSelectToCardPossible}
                      key={`${item.address}:${item.tokenId}`}
                      onClick={() => setTokenParam(item.tokenId)}
                    />
                  ),
                )}
            </InfiniteScroller>
          )}
        </div>
      )}
      {listedNftResult && (
        <CartItemsMultiSelection
          assets={listedNftResult.items.filter(
            e =>
              !activeBnplPositions.find(
                item => item.metadata.collectionAddress === e.address && item.tokenId === e.tokenId,
              ),
          )}
          selectedCollection={collection}
        />
      )}
    </OnSaleNftWrapper>
  );
};

export const ListViewBox: React.FC = ({ children }) => {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <MainBox>{children}</MainBox>
    </div>
  );
};

const MainBox = styled(Card)`
  background: ${({ theme }) => theme.colors.transparent};
  border: none;
  > * {
    &:last-child {
      background-color: ${({ theme }) => theme.colors.primary};
      border: 1px solid ${({ theme }) => theme.colors.gray20};
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      border-top: none;
      ${getStyleWithMediaQuery("filter", "", [{ [breakpoints.tablet]: "none" }])}
    }
  }
`;

const BnplNFTWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 30px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const GridContainer = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 18px" },
    { [breakpoints.tablet]: "0.5rem calc(0.5rem + 5px) 1.5rem" },
  ])}
`;

const StyledListViewBox = styled.div`
  gap: 1rem;
  padding: 0rem 18px 0 18px;
  ${getStyleWithMediaQuery("padding", "", [
    { [breakpoints.desktop]: "0 18px" },
    { [breakpoints.tablet]: "0.5rem calc(0.5rem + 5px) 1.5rem" },
  ])}
`;

const HeaderWrapper = styled.div``;

export const Header = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: 65px;
  }
  position: sticky;
  align-items: end;
  top: 170px;
  border-bottom: none;
  background: ${({ theme }) => theme.colors.primary};
  transform-style: preserve-3d;
  z-index: 5;
  :before {
    background-color: ${({ theme }) => theme.backgroundColor};
    content: "";
    z-index: -1;
    display: block;
    position: absolute;
    transform: translateZ(-1px);
    height: 120px;
    top: -10px;
    height: calc(100% + 10px);
    left: -5px;
    width: calc(100% + 10px);
  }
`;
export const ContainerBox = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  border-top: none;
  margin-bottom: 80px;
  > * {
    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      @media only screen and (max-width: ${breakpoints.tablet}px) {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
`;
