import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints } from "@cyanco/components/theme/config";
import { Button, Text } from "@cyanco/components/theme/v3";
import { ApeCoin } from "@cyanco/components/theme/v3/images";

import { useVaults } from "@/components/Vault/VaultDataProvider";
import { apeVaultContract, isProd } from "@/config";
import { formatCompactNumber } from "@/utils";

import { PoolsApy } from "./PoolsApy";

export const GridView = () => {
  return (
    <Container>
      <VaultCard />
      <PoolsApy />
    </Container>
  );
};

const VaultCard = () => {
  const navigate = useNavigate();
  const { vaults } = useVaults();
  const apy = useMemo(() => {
    const apeVault = vaults.find(
      ({ contractAddress }) => apeVaultContract.toLowerCase() === contractAddress.toLowerCase(),
    );
    if (!apeVault || !apeVault.estimatedYield) return null;
    return apeVault.estimatedYield / 100;
  }, [vaults]);

  return (
    <Card>
      <Flex direction="column" alignItems="center" gap="16px">
        <StyledImg
          src={ApeCoin}
          alt="ApeCoin"
          style={{
            border: "2px solid #0FF",
            borderRadius: "50%",
          }}
        />
        <Flex direction="column" gap="5px" alignItems="center">
          <Text color="secondary" size="xl" weight="600">
            {`ApeCoin Staking`}
          </Text>
          <Text color="secondary" size="xs" weight="400">
            {`Auto-Compound APY`}
          </Text>
        </Flex>
        <Flex direction="column" gap="4rem" alignItems="center" w="100%">
          <Flex direction="column" w="100%">
            <ApyBox direction="column" alignItems="center" gap="2px" p="14px 0 14px 0" justifyContent="center">
              <Text color="secondary" size="xxl" weight="600" textAlign="center">
                🔥 {apy ? (apy > 100 ? formatCompactNumber(apy) : apy.toFixed(2)) : "- "}%
              </Text>
            </ApyBox>
            <StakingButton onClick={() => navigate(`/vault/${isProd ? "mainnet" : "sepolia"}/${apeVaultContract}`)}>
              <StakingText size="sm" weight={"700"} color={"cyan"}>
                {`Start Earning`}
              </StakingText>
            </StakingButton>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 3rem;
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 0;
  }
`;

export const ApyBox = styled(Flex)`
  border-radius: 14px;
  align-items: center;
  width: 100%;
  transition: opacity 0.2s ease;
`;

const StyledImg = styled.img`
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  max-height: 36px;
  max-width: 36px;
  border-radius: 50%;
`;

export const StakingText = styled(Text)`
  color: ${({ theme }) => (theme.theme === "dark" ? theme.colors.black : theme.colors.white)};
  transition: 0.2s ease-in-out;
`;

export const StakingButton = styled(Button)`
  padding: 12px;
  border-color: ${({ theme }) => (theme.theme === "dark" ? theme.colors.white : theme.colors.black)};
  background: ${({ theme }) => (theme.theme === "dark" ? theme.colors.white : theme.colors.black)};
  :hover {
    background-color: #79FFF;
    border-color: #79FFF;
    ${StakingText} {
      color: black;
    }
  }
`;
const Card = styled.div`
  padding: 1.8rem;
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  background: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  transition: 0.2s;
  border-radius: 14px;
  justify-content: space-between;
  align-item: center;
  border: ${({ theme }) => `1px solid ${theme.colors.gray20}`};
`;
