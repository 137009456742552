import { useMemo, useRef, useState } from "react";
import { MoreHorizontal } from "react-feather";
import { NavLink, useLocation } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Hidden, Text } from "@cyanco/components/theme/v3";

import { useWeb3React } from "@/components/Web3ReactProvider";
import { DAPP_V2_URL } from "@/config";
import { SupportedChainId } from "@/constants/chains";
import { useUserAdmin } from "@/hooks";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { IsInTestDrive } from "@/utils";
import { Experiments } from "@/utils/experimentList";

import { MenuButtonContainer } from ".";
import { useSelectedItems } from "../Account/SelectedItemsContext";
import { useAppContext } from "../AppContextProvider";
import { useAuthContext } from "../AuthContext/AuthContextProvider";
import { useVaults } from "../Vault/VaultDataProvider";

export const PageNavigation = () => {
  const theme = useTheme();
  const { chainId } = useWeb3React();
  const { items } = useSelectedItems();
  const { user } = useAuthContext();
  const { getUserAdminOfVaults } = useUserAdmin();
  const location = useLocation();
  const [showHiddenMenu, setShowHiddenMenu] = useState(false);
  const { vaults } = useVaults();
  const { experiment } = useAppContext();
  const [showHiddenMenuMobile, setShowHiddenMenuMobile] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(menuRef, () => (menuRef && showHiddenMenu ? setShowHiddenMenu(false) : undefined));
  const isApeStakingPossible = useMemo(() => {
    return [SupportedChainId.MAINNET, SupportedChainId.SEPOLIA].some(_chainId => _chainId === chainId);
  }, [chainId]);
  const isAdmin = useMemo(() => {
    const adminVaults = getUserAdminOfVaults(vaults);
    return adminVaults.length > 0;
  }, [user, vaults]);
  const isV2Enabled = useMemo(() => {
    return experiment.result && experiment.result[Experiments.UI_V2];
  }, [experiment]);
  return (
    <MenuButtonContainer
      show={items.length === 0}
      style={{
        columnGap: "0px",
      }}
    >
      {IsInTestDrive && (
        <StyledNavLink id={"testdrive-nav-link"} to={`/testdrive`} $active={location.pathname.startsWith("/testdrive")}>
          Demo
        </StyledNavLink>
      )}
      <StyledNavLink
        id={"bnpl-nav-link"}
        to="/"
        $active={location.pathname === "/" || location.pathname.startsWith("/bnpl")}
      >
        Shop
      </StyledNavLink>
      <StyledNavLink id={"loans-nav-link"} to={`/loans`} $active={location.pathname.startsWith("/loans")}>
        Borrow
      </StyledNavLink>
      <StyledNavLink id={"vault-nav-link"} to={"/vault"} $active={location.pathname.startsWith("/vault")}>
        Lend
      </StyledNavLink>
      {!IsInTestDrive && (
        <Hidden tabletDown>
          <StyledNavLink id={"account-nav-link"} to={`/account`} $active={location.pathname.startsWith("/account")}>
            Account
          </StyledNavLink>
        </Hidden>
      )}

      <Hidden tabletDown style={{ height: "100%", position: "relative" }} ref={menuRef}>
        {showHiddenMenu && (
          <HiddenMenuAccount>
            {IsInTestDrive && (
              <StyledNavLink
                id={"account-nav-link"}
                to={`/account`}
                $active={location.pathname.startsWith("/account")}
                onClick={() => setShowHiddenMenu(false)}
              >
                Account
              </StyledNavLink>
            )}
            {isV2Enabled && (
              <StyledLink
                id={"try-v2"}
                href={DAPP_V2_URL}
                target="_blank"
                style={{ borderRadius: "9px", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                onClick={() => setShowHiddenMenu(false)}
              >
                <Flex direction="row">
                  Try new UI
                  <div style={{ paddingLeft: "5px" }}>
                    <Text size="xs" color="cyan" weight="700" style={{ float: "right" }}>
                      New
                    </Text>
                  </div>
                </Flex>
              </StyledLink>
            )}
            {isAdmin && (
              <StyledNavLink
                id={"vault-admin-nav-link"}
                to="/vault-admin"
                $active={location.pathname.startsWith("/vault-admin")}
                style={{ borderRadius: "9px", borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                onClick={() => setShowHiddenMenu(false)}
              >
                Vault Admin
              </StyledNavLink>
            )}
            {isApeStakingPossible && (
              <StyledNavLink
                id={"ape-staking-nav-link"}
                to="/ape-coin"
                $active={location.pathname.startsWith("/ape-coin")}
                style={{ borderRadius: "9px", borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                onClick={() => setShowHiddenMenu(false)}
              >
                Ape Staking
              </StyledNavLink>
            )}
            <StyledNavLink
              id={"cyan-points-nav-link"}
              to="/points"
              $active={location.pathname.startsWith("/points")}
              style={{ borderRadius: "9px", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
              onClick={() => setShowHiddenMenu(false)}
            >
              Cyan Points
            </StyledNavLink>
            <StyledLink
              id={"cyan-extension-nav-link"}
              href="https://chromewebstore.google.com/detail/cyan-extension/knpcgeeofdmafehffknjfedljlacnbdm"
              target="_blank"
              style={{ borderRadius: "9px", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
              onClick={() => setShowHiddenMenu(false)}
            >
              Cyan Extension
            </StyledLink>
            <StyledLink
              id={"cyan-articles-nav-link"}
              href="https://usecyan.com/articles"
              target="_blank"
              style={{ borderRadius: "9px", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
              onClick={() => setShowHiddenMenu(false)}
            >
              Cyan Articles
            </StyledLink>
          </HiddenMenuAccount>
        )}
        <MoreButton
          justifyContent="center"
          alignItems="center"
          h="100%"
          onClick={e => {
            e.stopPropagation();
            setShowHiddenMenu(prev => !prev);
          }}
        >
          <MoreHorizontal color={showHiddenMenu ? theme.colors.secondary : theme.colors.gray0} />
        </MoreButton>
      </Hidden>
      <Hidden tabletUp onClick={() => setShowHiddenMenuMobile(!showHiddenMenuMobile)}>
        {showHiddenMenuMobile && (
          <HiddenMenu>
            <StyledNavLink id={"account-nav-link"} to={`/account`} $active={location.pathname.startsWith("/account")}>
              Account
            </StyledNavLink>
            {isAdmin && (
              <StyledNavLink
                id={"vault-admin-nav-link"}
                to="/vault-admin"
                $active={location.pathname.startsWith("/vault-admin")}
              >
                Vault Admin
              </StyledNavLink>
            )}
            {isV2Enabled && (
              <StyledLink id={"try-v2"} href={DAPP_V2_URL} target="_blank">
                <Flex direction="row">
                  Try new UI
                  <div style={{ paddingLeft: "5px" }}>
                    <Text size="xs" color="cyan" weight="700" style={{ float: "right" }}>
                      New
                    </Text>
                  </div>
                </Flex>
              </StyledLink>
            )}
            {isApeStakingPossible && (
              <StyledNavLink
                id={"ape-staking-nav-link"}
                to="/ape-coin"
                $active={location.pathname.startsWith("/ape-coin")}
              >
                Ape Staking
              </StyledNavLink>
            )}
            <StyledNavLink
              id={"bnpl-loan-pricer-nav-link"}
              to={`/bnpl/loan-pricer`}
              $active={location.pathname.startsWith("/bnpl/loan-pricer")}
            >
              NFT Loan Pricer
            </StyledNavLink>{" "}
            <StyledNavLink id={"bargain-nav-link"} to={`/bargain`} $active={location.pathname.startsWith("/bargain")}>
              Shop Bargains
            </StyledNavLink>
            <StyledNavLink id={"cyan-points-nav-link"} to="/points" $active={location.pathname.startsWith("/points")}>
              Cyan Points
            </StyledNavLink>
          </HiddenMenu>
        )}
        <Flex justifyContent="center" alignItems="center" h="100%">
          <MoreHorizontal color={showHiddenMenuMobile ? theme.colors.secondary : theme.colors.gray0} />
        </Flex>
      </Hidden>
    </MenuButtonContainer>
  );
};

const MoreButton = styled(Flex)`
  cursor: pointer;
  transition: 0.2s ease-in-out;
  padding: 0 5px;
  border-radius: 12px;
  margin-left: 10px;
  :hover {
    background: ${({ theme }) => theme.colors.gray10};
  }
`;
const HiddenMenuAccount = styled(Flex)`
  position: absolute;
  top: 35px;
  right: 8px;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
  z-index: 99;
`;
const StyledLink = styled.a`
  align-items: left;
  border-radius: 14px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  color: ${({ theme }) => (theme.theme == "dark" ? theme.colors.gray0 : theme.colors.gray90)};
  font-size: 16px;
  ${getStyleWithMediaQuery("font-size", "", [{ [breakpoints.tablet]: "16px" }])};
  font-family: Inter;
  font-weight: 500;
  padding: 8px 15px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  :focus {
    color: ${({ theme }) => theme.colors.secondary};
  }
  :hover {
    background: ${({ theme }) => theme.colors.gray10};
    border-radius: 14px;
  }
`;

const StyledNavLink = styled(NavLink)<{ $active: boolean }>`
  align-items: left;
  border-radius: 14px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.secondary : theme.theme == "dark" ? theme.colors.gray0 : theme.colors.gray90};
  font-size: 16px;
  ${getStyleWithMediaQuery("font-size", "", [{ [breakpoints.tablet]: "16px" }])};
  font-family: Inter;
  font-weight: 500;
  padding: 8px 15px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  :focus {
    color: ${({ theme }) => theme.colors.secondary};
  }
  :hover {
    background: ${({ theme }) => theme.colors.gray10};
    border-radius: 14px;
  }
`;

const HiddenMenu = styled(Flex)`
  position: absolute;
  bottom: 50px;
  right: 13px;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray20};
`;
